import { useFetchArticles } from '@/features/Articles/queries';

export const useFilteringArticles = (additionalParams: Record<string, string | number | any> = {}) => {
  const params = {
    page: 1,
    itemsPerPage: 10,
    ...additionalParams,
  };

  const { data, isLoading, isFetched } = useFetchArticles(params);

  return {
    data,
    isLoading,
    isFetched,
  };
};
