export const BASE_API_PATH = '/api';
export const BASE_TICKER_API_PATH = '/docs';
export const BASE_SSE_PATH = '/sse';
export const BASE_CRDT_PATH = '/crdt';
export const AI_COVER_API_URL = 'https://cms.cointelegraph.com/ais/api';
export const GTM_ID = 'GTM-000000';
export const DEFAULT_EVENTS_URL = 'cms.cointelegraph.com/events';
export const DEFAULT_USER_REFETCH_TIMEOUT = 300000;

export const apiGatewayUrl: string = import.meta.env?.PROD ? import.meta.env.VITE_API_GATEWAY_URL : BASE_API_PATH;
export const tickerApiGatewayUrl: string = import.meta.env?.PROD
  ? import.meta.env.VITE_TICKER_API_GATEWAY_URL
  : BASE_TICKER_API_PATH;
export const colladitorWs: string = import.meta.env?.PROD ? import.meta.env.VITE_COLLABORATION_WS : BASE_CRDT_PATH;
export const eventsUrl: string = import.meta.env?.VITE_EVENTS_URL
  ? import.meta.env?.VITE_EVENTS_URL
  : DEFAULT_EVENTS_URL;
export const sseGatewayUrl = import.meta.env?.PROD ? import.meta.env.VITE_APP_SSE_GATEWAY : BASE_SSE_PATH;
export const aiCoverSearchApiUrl = import.meta.env?.PROD ? import.meta.env.VITE_API_AI_COVER : AI_COVER_API_URL;
export const gtmId = import.meta.env?.PROD ? import.meta.env.VITE_GOOGLE_TAG_ID : 'GTM-000000';

export const userRefetchTimeout = import.meta.env?.PROD
  ? import.meta.env.VITE_GET_USER_TIMEOUT
  : DEFAULT_USER_REFETCH_TIMEOUT;

// Sentry
export const sentryDsn = import.meta.env?.VITE_APP_SENTRY_DSN;
export const deploymentEnv = import.meta.env?.VITE_APP_DEPLOYMENT_ENV;
export const release = import.meta.env?.VITE_APP_RELEASE || '1.0.0';
export const tracePropagationTargets = (import.meta.env?.VITE_SENTRY_TRACES_PROPAGATION_TARGETS || '')
  .split(',')
  .map((target: string): RegExp | string => {
    target = target.trim();

    if (target.startsWith('/') && target.endsWith('/')) {
      const pattern = target.slice(1, -1); // Remove the starting and ending slashes
      return new RegExp(pattern); // Convert to a RegExp object
    }

    // Otherwise, return the string target as is
    return target;
  });
export const tracesSampleRate = parseFloat(import.meta.env?.VITE_SENTRY_TRACES_SAMPLE_RATE || 0.5);
export const publicUrl = import.meta.env?.VITE_PUBLIC_URL || 'http://localhost:5137/';
