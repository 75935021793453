<template>
  <div class="flex flex-col">
    <div class="flex mb-4">
      <Search
        class="md:max-w-[300px]"
        placeholder="Search for articles"
        :value="searchQuery"
        @update:modelValue="(value) => updateSearchQuery(value)"
      />

      <div class="flex gap-2 ml-auto">
        <Button
          :size="SIZES.MEDIUM"
          :visual-type="BUTTON_TYPE.TERTIARY"
          data-testid="articles-quick-filters"
          @click="revealFilters"
        >
          <template #leftIcon>
            <EqualizerIcon class="w-5 h-5 rotate-90" />
          </template>

          <div class="flex items-center gap-2">
            Filters

            <div
              v-if="isFiltersSelected"
              class="w-5 h-5 flex items-center justify-center leading-none rounded-full bg-imperium-fg-base text-sm text-white"
            >
              {{ filtersStore.selectedFiltersCount }}
            </div>
          </div>
        </Button>

        <SortDropdown
          name="articles"
          :fields="SORT_FIELDS"
          :direction="SortStatus.DESC"
          :filter-store="filtersStore"
          @update="onSortUpdate"
          @reset="onSortReset"
        />

        <ColumnsDropdown
          @select="onColumnSelect"
          @reset="onColumnsReset"
        />

        <Button
          v-if="createDocumentView"
          :size="SIZES.MEDIUM"
          :is-disabled="!createDocumentEdit"
          data-testid="create-new-article-modal-show"
          @click="onCreateArticleButtonClick"
        >
          <template #leftIcon>
            <PlusIcon class="w-5 h-5" />
          </template>
          {{ t('article.create-modal.show-button') }}
        </Button>
      </div>
    </div>

    <div class="flex mb-4 py-2 gap-2">
      <Button
        :size="SIZES.SMALL"
        :visual-type="isQuickEmbargoed ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.GHOST"
        :rounded="BUTTON_ROUNDED.FULL"
        data-testid="articles-quick-filters-embargoed"
        @click="onSetQuickFilters(QUICK_FILTERS.EMBARGOED)"
      >
        Embargoed
      </Button>

      <Button
        :size="SIZES.SMALL"
        :visual-type="isQuickScheduled ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.GHOST"
        :rounded="BUTTON_ROUNDED.FULL"
        data-testid="articles-quick-filters-scheduled"
        @click="onSetQuickFilters(QUICK_FILTERS.SCHEDULED)"
      >
        Scheduled
      </Button>

      <Button
        :size="SIZES.SMALL"
        :visual-type="isQuickPublished ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.GHOST"
        :rounded="BUTTON_ROUNDED.FULL"
        data-testid="articles-quick-filters-published"
        @click="onSetQuickFilters(QUICK_FILTERS.PUBLISHED)"
      >
        Published
      </Button>
    </div>

    <ArticlesTable @redirect="goToArticleEdit" />

    <SlidePanel
      id="article-filters-panel"
      data-testid="article-filters-panel"
      :is-opened="isFiltersRevealed"
      class="article-filters"
      :size="SIZES.LARGE"
      @close="onFiltersPanelClose"
    >
      <template #title> Filters </template>

      <ArticleFiltersPanel
        v-if="isFiltersRevealed"
        ref="articleFiltersPanelRef"
        @apply="confirmFilters"
        @reset="cancelFilters"
      />
    </SlidePanel>

    <Teleport to="#modals">
      <CreateArticleModal
        v-if="isRevealed"
        :is-visible="isRevealed"
        @close="cancel"
        @confirm="onArticleCreated"
      />

      <DiscardFiltersModal
        :is-visible="isDiscardConfirmationRevealed"
        @close="cancelDiscardConfirmation"
        @reset="onDiscardFilters"
        @apply="onApplyFilters"
      />
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import Button from '@/components/Button.vue';
import CreateArticleModal from '@/features/CreateArticle/CreateArticleModal.vue';
import { useModal } from '@/composables/useModal';
import { SIZES, SortStatus, BUTTON_TYPE, BUTTON_ROUNDED, DocumentStatus } from '@/types';
import Search from '@/components/Table/Filters/Search.vue';
import SortDropdown, { type SortField } from '@/components/Table/Filters/SortDropdown.vue';
import { useArticlesFilters } from '@/features/Articles/stores/filters.store';
import SlidePanel from '@/components/SlidePanel.vue';
import ArticleFiltersPanel from '@/features/Articles/components/ArticleFiltersPanel.vue';
import DiscardFiltersModal from '@/features/Articles/components/modals/DiscardFiltersModal.vue';
import ColumnsDropdown from '@/features/Articles/components/ColumnsDropdown.vue';

import PlusIcon from '@/assets/icons/plus.svg?component';
import EqualizerIcon from '@/assets/icons/equalizer.svg?component';

import ArticlesTable from '@/features/Articles/components/ArticlesTable.vue';
import type { ARTICLE_COLUMNS } from '../constants';
import { useUserPermissions } from '@/stores/user.store';

import { SORT_FIELDS, QUICK_FILTERS, FILTERS_BY_PRESET_MAP } from '@/features/Articles/constants';
import type { ArticleCreatedLanguage } from '@/features/Articles/types.ts';
import { useArticleRouting } from '@/features/Articles/composables/routing.ts';

const { createDocumentEdit, createDocumentView } = useUserPermissions();
const { goToArticleEdit } = useArticleRouting();

const { t } = useI18n();

const { reveal, cancel, confirm, isRevealed } = useModal();
const {
  reveal: revealFilters,
  cancel: cancelFilters,
  confirm: confirmFilters,
  isRevealed: isFiltersRevealed,
} = useModal();

const {
  reveal: revealDiscardConfirmation,
  confirm: confirmDiscardConfirmation,
  cancel: cancelDiscardConfirmation,
  isRevealed: isDiscardConfirmationRevealed,
} = useModal();

// Filters
const articleFiltersPanelRef = ref<ArticleFiltersPanel | undefined>();
const filtersStore = useArticlesFilters();

const isFiltersSelected = computed<boolean>(() => !!filtersStore.selectedFiltersCount);
const isQuickFilterSelected = (quickFilter: QUICK_FILTERS) => {
  const preset = FILTERS_BY_PRESET_MAP[quickFilter];
  const filters = Object.keys(preset);

  for (const i in filters) {
    const filterName = filters[i];
    const filterValue = preset[filterName];

    if (Array.isArray(filterValue)) {
      const currentFromStore = filtersStore.state.filters[filterName] || [];
      if (JSON.stringify(filterValue.sort()) !== JSON.stringify(currentFromStore.sort())) {
        return false;
      }
    } else if (filtersStore.state.filters[filterName] !== filterValue) {
      return false;
    }
  }

  return true;
};

const isQuickEmbargoed = computed<boolean>(() => {
  if (Object.keys(filtersStore.state.filters).length !== 1) {
    return false;
  }

  return isQuickFilterSelected(QUICK_FILTERS.EMBARGOED);
});
const isQuickPublished = computed<boolean>(() => {
  if (
    filtersStore.state.filters.publishedFrom
      ? Object.keys(filtersStore.state.filters).length < 2
      : Object.keys(filtersStore.state.filters).length !== 1
  ) {
    return false;
  }

  if (
    filtersStore.state.filters.publishedFrom &&
    filtersStore.state.filters.publishedTo &&
    filtersStore.state.filters.status?.includes(DocumentStatus.PUBLISHED)
  ) {
    return isQuickFilterSelected(QUICK_FILTERS.PUBLISHED);
  } else if (
    !filtersStore.state.filters.publishedFrom &&
    !filtersStore.state.filters.publishedTo &&
    filtersStore.state.filters.status?.includes(DocumentStatus.PUBLISHED)
  ) {
    return isQuickFilterSelected(QUICK_FILTERS.PUBLISHED);
  }
});
const isQuickScheduled = computed<boolean>(() => {
  if (Object.keys(filtersStore.state.filters).length < 2) {
    return false;
  }

  return !!(
    filtersStore.state.filters.publishedFrom &&
    !filtersStore.state.filters.publishedTo &&
    filtersStore.state.filters.status?.includes(DocumentStatus.PUBLISHED)
  );
});

const searchQuery = computed(() => filtersStore.state?.searchQuery || '');
const updateSearchQuery = (searchQuery: string) => {
  filtersStore.setSearchQuery(searchQuery);
};

const onSortUpdate = (newField: SortField, newDirection: SortStatus) => {
  filtersStore.setSorts({
    [newField.value]: newDirection,
  });
};

const onSetQuickFilters = (preset: QUICK_FILTERS) => {
  filtersStore.toggleFastFiltersByPreset(preset);
};

const onFiltersPanelClose = () => {
  if (articleFiltersPanelRef.value.checkUnsavedChanges()) {
    revealDiscardConfirmation();
    return;
  }

  cancelFilters();
};

const onDiscardFilters = () => {
  cancelDiscardConfirmation();
  cancelFilters();
};

const onApplyFilters = () => {
  articleFiltersPanelRef.value.applyFilters();
  cancelDiscardConfirmation();
  cancelFilters();
};

const onCreateArticleButtonClick = () => reveal();

const onSortReset = () => {
  filtersStore.resetSort();
};

const onColumnSelect = (column: ARTICLE_COLUMNS): void => {
  filtersStore.updateColumn(column);
};

const onColumnsReset = (): void => {
  filtersStore.resetColumns();
};

const onArticleCreated = (article: ArticleCreatedLanguage): void => {
  goToArticleEdit(article);
  confirm();
};
</script>
