<script setup lang="ts">
import CollapsableSection from '@/components/Collapsable/CollapsableSection.vue';
import { computed, onMounted, ref } from 'vue';
import TagsMultiDropdown from '@/features/ArticleLayout/components/TagsMultiDropdown.vue';
import type { MultidropdownItem } from '@/components/FormMultiDropdown.vue';
import { useConverterPage } from '@/features/Converter/stores/converter.store';
import type { SearchMultidropdownItem } from '@/components/FormSearchMultiDropdown.vue';
import { ArticleTagsFetchRequest, Tag } from '@/features/ArticleLayout/types';
import { SortStatus } from '@/types';
import { fetchTags } from '@/features/ArticleLayout/api';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';

const props = defineProps<{
  converterId: number;
}>();

const converterStore = useConverterPage();
const currentLanguageStore = useCurrentLanguageStore();
const languageId = computed(() => currentLanguageStore.currentLanguage.id);

const tags = computed(() => converterStore.state.converter.tags);
const mappedTags = computed(() =>
  tags.value.map((tag) => {
    return {
      id: tag.id,
      label: tag?.label ?? tag?.tagTranslates[0]?.title,
    };
  }),
);
const updateTags = (value: SearchMultidropdownItem[]) => {
  const mappedTags: Array<number> = value.map((tag) => tag.id);
  onFieldUpdate(mappedTags, 'tags');
  converterStore.updateConverterData({ tags: value });
};

const sectionVisible = ref<boolean>(!!tags.value?.length ?? false);

const initTags = ref<SearchMultidropdownItem>([]);

onMounted(async () => {
  const tagsPayload: ArticleTagsFetchRequest = {
    languageId: languageId.value,
    'order[popular]': SortStatus.ASC,
    pagination: true,
    page: 1,
    itemsPerPage: 10,
  };

  const { items: tagsItems } = await fetchTags(tagsPayload);

  // Init values for tags field
  initTags.value = tagsItems?.map((tagTranslate: Tag) => ({
    id: tagTranslate.tag.id,
    label: tagTranslate.title,
  }));
});

const onFieldUpdate = (val: string | boolean | number[], field: string) => {
  converterStore.onFieldUpdate(val, field, props.converterId);
};
</script>

<template>
  <CollapsableSection
    title="Related articles"
    :content-exists="sectionVisible"
    button-label="Tags to display articles on the webpage"
    @expand="sectionVisible = true"
  >
    <template #content>
      <TagsMultiDropdown
        name="tags"
        without-super-filter
        :model-value="mappedTags"
        placeholder=" Select tags"
        :init-values="initTags"
        @update:model-value="(value) => updateTags(value)"
      >
        <template #label>
          <span class="text-sm text-imperium-fg-base font-semibold">Tags to display articles on the webpage</span>
        </template>
        <template #help>
          <span class="text-imperium-fg-muted text-sm">
            Articles will be selected according to the chosen tags.
          </span></template
        >

        <template #error> Select tag </template>
      </TagsMultiDropdown>
    </template>
  </CollapsableSection>
</template>
