<template>
  <div>
    <section class="flex flex-col gap-3 mb-4">
      <h3 class="text-sm font-semibold text-imperium-fg-base">Flags</h3>

      <div class="grid grid-cols-2 gap-4">
        <FormCheckbox
          name="flag[]"
          :size="SIZES.SMALL"
          :model-value="isFlagChecked(ARTICLE_FLAGS.BREAKING)"
          @update:model-value="updateSelectedFlags(ARTICLE_FLAGS.BREAKING)"
        >
          <div class="flex items-center gap-2">
            <ArticleFlag :flag="ARTICLE_FLAGS.BREAKING" />
            <span class="text-sm font-semibold text-imperium-fg-base">Breaking</span>
          </div>
        </FormCheckbox>

        <FormCheckbox
          name="flag[]"
          :size="SIZES.SMALL"
          :model-value="isFlagChecked(ARTICLE_FLAGS.EMBARGOED)"
          @update:model-value="updateSelectedFlags(ARTICLE_FLAGS.EMBARGOED)"
        >
          <div class="flex items-center gap-2">
            <ArticleFlag :flag="ARTICLE_FLAGS.EMBARGOED" />
            <span class="text-sm font-semibold text-imperium-fg-base">Embargoed</span>
          </div>
        </FormCheckbox>

        <!-- There could be scheduled filter after existing filter on backend -->

        <FormCheckbox
          name="flag[]"
          :size="SIZES.SMALL"
          :model-value="isFlagChecked(ARTICLE_FLAGS.NEEDS_PROFREADING)"
          @update:model-value="updateSelectedFlags(ARTICLE_FLAGS.NEEDS_PROFREADING)"
        >
          <div class="flex items-center gap-2">
            <ArticleFlag :flag="ARTICLE_FLAGS.NEEDS_PROFREADING" />
            <span class="text-sm font-semibold text-imperium-fg-base">Needs proofreading</span>
          </div>
        </FormCheckbox>
      </div>
    </section>

    <section class="flex flex-col gap-3 mb-4">
      <h3 class="text-sm font-semibold text-imperium-fg-base">Status</h3>

      <div class="grid grid-cols-2 gap-4">
        <FormCheckbox
          name="status[]"
          :size="SIZES.SMALL"
          :model-value="isStatusChecked(DocumentStatus.PUBLISHED)"
          @update:model-value="updateSelectedStatuses(DocumentStatus.PUBLISHED)"
        >
          <ArticleStatus
            :size="SIZES.XSMALL"
            :status="DocumentStatus.PUBLISHED"
          />
        </FormCheckbox>

        <!-- There could be scheduled filter after existing filter on backend -->

        <FormCheckbox
          name="status[]"
          :size="SIZES.SMALL"
          :model-value="isStatusChecked(DocumentStatus.IN_PROGRESS)"
          @update:model-value="updateSelectedStatuses(DocumentStatus.IN_PROGRESS)"
        >
          <ArticleStatus
            :size="SIZES.XSMALL"
            :status="DocumentStatus.IN_PROGRESS"
          />
        </FormCheckbox>

        <FormCheckbox
          name="status[]"
          :size="SIZES.SMALL"
          :model-value="isStatusChecked(DocumentStatus.IMAGE_REJECTED)"
          @update:model-value="updateSelectedStatuses(DocumentStatus.IMAGE_REJECTED)"
        >
          <ArticleStatus
            :size="SIZES.XSMALL"
            :status="DocumentStatus.IMAGE_REJECTED"
          />
        </FormCheckbox>

        <FormCheckbox
          name="status[]"
          :size="SIZES.SMALL"
          :model-value="isStatusChecked(DocumentStatus.READY_FOR_ART_EDITOR)"
          @update:model-value="updateSelectedStatuses(DocumentStatus.READY_FOR_ART_EDITOR)"
        >
          <ArticleStatus
            :size="SIZES.XSMALL"
            :status="DocumentStatus.READY_FOR_ART_EDITOR"
          />
        </FormCheckbox>

        <FormCheckbox
          name="status[]"
          :size="SIZES.SMALL"
          :model-value="isStatusChecked(DocumentStatus.TEXT_REJECTED)"
          @update:model-value="updateSelectedStatuses(DocumentStatus.TEXT_REJECTED)"
        >
          <ArticleStatus
            :size="SIZES.XSMALL"
            :status="DocumentStatus.TEXT_REJECTED"
          />
        </FormCheckbox>

        <FormCheckbox
          name="status[]"
          :size="SIZES.SMALL"
          :model-value="isStatusChecked(DocumentStatus.READY_TO_REVIEW)"
          @update:model-value="updateSelectedStatuses(DocumentStatus.READY_TO_REVIEW)"
        >
          <ArticleStatus
            :size="SIZES.XSMALL"
            :status="DocumentStatus.READY_TO_REVIEW"
          />
        </FormCheckbox>

        <FormCheckbox
          name="status[]"
          :size="SIZES.SMALL"
          :model-value="isStatusChecked(DocumentStatus.UNPUBLISHED)"
          @update:model-value="updateSelectedStatuses(DocumentStatus.UNPUBLISHED)"
        >
          <ArticleStatus
            :size="SIZES.XSMALL"
            :status="DocumentStatus.UNPUBLISHED"
          />
        </FormCheckbox>

        <FormCheckbox
          name="status[]"
          :size="SIZES.SMALL"
          :model-value="isStatusChecked(DocumentStatus.READY_TO_PUBLISH)"
          @update:model-value="updateSelectedStatuses(DocumentStatus.READY_TO_PUBLISH)"
        >
          <ArticleStatus
            :size="SIZES.XSMALL"
            :status="DocumentStatus.READY_TO_PUBLISH"
          />
        </FormCheckbox>

        <FormCheckbox
          name="status[]"
          :size="SIZES.SMALL"
          :model-value="isStatusChecked(DocumentStatus.NEW)"
          @update:model-value="updateSelectedStatuses(DocumentStatus.NEW)"
        >
          <ArticleStatus
            :size="SIZES.XSMALL"
            :status="DocumentStatus.NEW"
          />
        </FormCheckbox>
      </div>
    </section>

    <section class="flex flex-col gap-3 mb-4">
      <h3 class="text-sm font-semibold text-imperium-fg-base">Language</h3>

      <div class="grid grid-cols-2 gap-4">
        <FormCheckbox
          v-for="language in mappedLanguages"
          :key="language.short"
          name="language[]"
          :size="SIZES.SMALL"
          :model-value="isLanguageChecked(language.id)"
          @update:model-value="updateSelectedLanguages(language.id)"
        >
          <div class="flex gap-2 items-center">
            <CountryIcon
              :key="language.short"
              class="flex-none"
              :code="language.short"
              :name="language.title"
            />
            <span class="text-sm font-semibold text-imperium-fg-base">
              {{ language.title }}
            </span>
          </div>
        </FormCheckbox>
      </div>
    </section>

    <section class="flex flex-col gap-3 mb-4">
      <h3 class="text-sm font-semibold text-imperium-fg-base">Team</h3>

      <div class="flex flex-wrap gap-2">
        <div
          ref="teamFilters"
          class="flex flex-col gap-2 w-full"
        />

        <ConditionalContent
          :is-revealed="isFilterEnabled(CONDITIONAL_FILTERS.EDITOR)"
          :to="teamFilters"
          @reveal="addConditionalFilter(CONDITIONAL_FILTERS.EDITOR)"
        >
          <template #label> Editor </template>

          <FormMultiDropdown
            name="filterEditor"
            :model-value="editor"
            class="mb-2"
            placeholder="Type to search"
            :size="SIZES.SMALL"
            :values="editors"
            @update:model-value="(value) => (editor = value)"
          >
            <template #label>
              <span class="text-xs">Editor</span>
            </template>

            <template #error> Select editor </template>
          </FormMultiDropdown>
        </ConditionalContent>

        <ConditionalContent
          :is-revealed="isFilterEnabled(CONDITIONAL_FILTERS.WRITER)"
          :to="teamFilters"
          @reveal="addConditionalFilter(CONDITIONAL_FILTERS.WRITER)"
        >
          <template #label> Writer </template>

          <FormMultiDropdown
            name="filterWriter"
            :model-value="writer"
            class="mb-2"
            placeholder="Type to search"
            :size="SIZES.SMALL"
            :values="writers"
            @update:model-value="(value) => (writer = value)"
          >
            <template #label>
              <span class="text-xs">Writer</span>
            </template>

            <template #error> Select writer </template>
          </FormMultiDropdown>
        </ConditionalContent>

        <ConditionalContent
          :is-revealed="isFilterEnabled(CONDITIONAL_FILTERS.PUBLIC_AUTHOR)"
          :to="teamFilters"
          @reveal="addConditionalFilter(CONDITIONAL_FILTERS.PUBLIC_AUTHOR)"
        >
          <template #label> Public author </template>

          <FormMultiDropdown
            name="filterAuthor"
            class="mb-2"
            :model-value="author"
            placeholder="Type to search"
            :size="SIZES.SMALL"
            :values="authors"
            @update:model-value="(value) => (author = value)"
          >
            <template #label>
              <span class="text-xs">Public author</span>
            </template>

            <template #error> Select author </template>
          </FormMultiDropdown>
        </ConditionalContent>

        <ConditionalContent
          :is-revealed="isFilterEnabled(CONDITIONAL_FILTERS.COPY_EDITOR)"
          :to="teamFilters"
          @reveal="addConditionalFilter(CONDITIONAL_FILTERS.COPY_EDITOR)"
        >
          <template #label> Copy editor </template>

          <FormMultiDropdown
            name="filterCopyEditor"
            class="mb-2"
            :model-value="copyEditor"
            placeholder="Type to search"
            :size="SIZES.SMALL"
            :values="copyEditors"
            @update:model-value="(value) => (copyEditor = value)"
          >
            <template #label>
              <span class="text-xs">Copy editor</span>
            </template>

            <template #error> Select copy editor </template>
          </FormMultiDropdown>
        </ConditionalContent>

        <ConditionalContent
          :is-revealed="isFilterEnabled(CONDITIONAL_FILTERS.CREATOR)"
          :to="teamFilters"
          @reveal="addConditionalFilter(CONDITIONAL_FILTERS.CREATOR)"
        >
          <template #label> Creator </template>

          <FormMultiDropdown
            name="filterCreator"
            :model-value="creator"
            class="mb-2"
            placeholder="Type to search"
            :size="SIZES.SMALL"
            :values="users"
            @update:model-value="(value) => (creator = value)"
          >
            <template #label>
              <span class="text-xs">Creator</span>
            </template>

            <template #error> Select creator </template>
          </FormMultiDropdown>
        </ConditionalContent>
      </div>
    </section>

    <section class="flex flex-col gap-3 mb-4">
      <h3 class="text-sm font-semibold text-imperium-fg-base">Date & Schedule</h3>

      <div class="flex flex-wrap gap-2">
        <div
          ref="dateFilters"
          class="flex flex-col gap-2 w-full"
        />

        <ConditionalContent
          v-if="isArticlesFiltersFull"
          :is-revealed="isFilterEnabled(CONDITIONAL_FILTERS.EMBARGO)"
          :to="dateFilters"
          @reveal="addConditionalFilter(CONDITIONAL_FILTERS.EMBARGO)"
        >
          <template #label> Embargo </template>

          <div>
            <span class="inline-block text-xs mb-2">Embargoed until</span>
            <FormDatepicker
              name="embargoedUntil"
              class="mb-2"
              placeholder="Not selected"
              :model-value="embargoedUntil"
              :size="SIZES.SMALL"
              @update:model-value="(value) => (embargoedUntil = value)"
            />
          </div>
        </ConditionalContent>

        <ConditionalContent
          v-if="isArticlesFiltersFull"
          :is-revealed="isFilterEnabled(CONDITIONAL_FILTERS.DEADLINE_FOR_COVER)"
          :to="dateFilters"
          @reveal="addConditionalFilter(CONDITIONAL_FILTERS.DEADLINE_FOR_COVER)"
        >
          <template #label> Deadline for cover </template>

          <div>
            <span class="inline-block text-xs mb-2">Deadline for cover image</span>
            <FormDatepicker
              name="deadline"
              class="mb-2"
              placeholder="Not selected"
              :model-value="deadline"
              :size="SIZES.SMALL"
              @update:model-value="(value) => (deadline = value)"
            />
          </div>
        </ConditionalContent>

        <ConditionalContent
          :is-revealed="isFilterEnabled(CONDITIONAL_FILTERS.PUBLISHED)"
          :to="dateFilters"
          @reveal="addConditionalFilter(CONDITIONAL_FILTERS.PUBLISHED)"
        >
          <template #label> Published </template>

          <div>
            <span class="inline-block text-xs mb-2">Published</span>
            <ArticlesFilterDataPicker
              name="publishedAt"
              class="mb-2"
              placeholder="Not selected"
              :model-value="publishedAt"
              :size="SIZES.SMALL"
              @update:model-value="applyPublishDateFilter"
            />
          </div>
        </ConditionalContent>

        <ConditionalContent
          v-if="isArticlesFiltersFull"
          :is-revealed="isFilterEnabled(CONDITIONAL_FILTERS.CREATED)"
          :to="dateFilters"
          @reveal="addConditionalFilter(CONDITIONAL_FILTERS.CREATED)"
        >
          <template #label> Created </template>

          <div>
            <span class="inline-block text-xs mb-2">Created</span>
            <FormDatepicker
              name="createdAt"
              class="mb-2"
              placeholder="Not selected"
              :model-value="createdAt"
              :size="SIZES.SMALL"
              @update:model-value="(value) => (createdAt = value)"
            />
          </div>
        </ConditionalContent>
      </div>
    </section>

    <section class="flex flex-col gap-3 mb-4">
      <h3 class="text-sm font-semibold text-imperium-fg-base">Category</h3>

      <div class="flex flex-wrap gap-2">
        <div
          ref="categoryFilters"
          class="flex flex-col gap-2 w-full"
        />

        <ConditionalContent
          :is-revealed="isFilterEnabled(CONDITIONAL_FILTERS.CATEGORY)"
          :to="categoryFilters"
          @reveal="addConditionalFilter(CONDITIONAL_FILTERS.CATEGORY)"
        >
          <template #label> Category </template>

          <FormMultiDropdown
            name="category"
            class="mb-2"
            :model-value="category"
            placeholder="Add categories"
            :size="SIZES.SMALL"
            :values="categories"
            @update:model-value="(value) => (category = value)"
          >
            <template #label>
              <span class="text-xs">Category</span>
            </template>

            <template #error> Select category </template>
          </FormMultiDropdown>
        </ConditionalContent>
      </div>
    </section>

    <div
      class="px-4 pb-3 pt-5 flex relative items-center justify-between border-t border-imperium-border-weak rounded-b-2xl gap-3"
    >
      <Button
        :visual-type="BUTTON_TYPE.TERTIARY"
        :size="SIZES.MEDIUM"
        is-full-width
        @click="onFiltersReset"
      >
        Reset
      </Button>

      <Button
        :visual-type="BUTTON_TYPE.PRIMARY"
        :size="SIZES.MEDIUM"
        is-full-width
        @click="onFiltersApply"
      >
        Apply
      </Button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, inject } from 'vue';
import { useArticlesFilters } from '@/features/Articles/stores/filters.store';

import Button from '@/components/Button.vue';
import FormDatepicker from '@/components/FormDatepicker';
import FormCheckbox from '@/components/FormCheckbox.vue';
import ConditionalContent from '@/components/ConditionalContent.vue';
import ArticleStatus from '@/features/ArticleLayout/components/ArticleStatus.vue';
import ArticleFlag from '@/features/Articles/components/ArticleFlag.vue';
import FormMultiDropdown from '@/components/FormMultiDropdown.vue';
import { useAuthors, useEditors, useWriters, useUsers, useCopyEditors } from '@/features/Users/composables';
import { useCategories } from '@/features/ArticleLayout/composables';
import { ARTICLE_FLAGS } from '@/features/Articles/constants';

import { SIZES, BUTTON_TYPE, DocumentStatus } from '@/types';

import ArticlesFilterDataPicker from '@/features/Articles/components/ArticlesFilterDataPicker.vue';
import type { RangeValues } from '@/components/FormDatepicker/FormDatePickerRange.vue';
import { getDateByUTC, getDateByUTCWithValidEndHours } from '@/utils';
import { useLanguagesStore } from '@/stores/languages.store';
import { useUserStore } from '@/stores/user.store.ts';
import CountryIcon from '@/shared/ui/CountryIcon/CountryIcon.vue';

const enum CONDITIONAL_FILTERS {
  EDITOR = 'editor',
  COPY_EDITOR = 'copy-editor',
  WRITER = 'writer',
  PUBLIC_AUTHOR = 'public-author',
  CREATOR = 'creator',
  EMBARGO = 'embargo',
  DEADLINE_FOR_COVER = 'deadline',
  PUBLISHED = 'published',
  CREATED = 'created',
  CATEGORY = 'category',
}

const emits = defineEmits<{
  (event: 'apply'): void;
  (event: 'reset'): void;
}>();

const languagesStore = useLanguagesStore();
const languages = computed(() => languagesStore.state);

const isArticlesFiltersFull = inject('isArticlesFiltersFull');
const { writers } = useWriters();
const { users: editors } = useEditors();
const { users: copyEditors } = useCopyEditors();
const { authors } = useAuthors();
const { users } = useUsers();
const { categories } = useCategories();
const userStore = useUserStore();

const filtersStore = useArticlesFilters();

const mappedLanguages = computed(() => {
  if (!userStore.state.languages) {
    return null;
  }

  return userStore.state.languages;
});

const initialFiltersSnapshot = ref<string>();

const language = ref([]);
const status = ref([]);
const flag = ref([]);

const deadline = ref();
const createdAt = ref();
const publishedFrom = ref();
const publishedTo = ref();
const embargoedUntil = ref();

const editor = ref([]);
const copyEditor = ref([]);
const writer = ref([]);
const author = ref([]);
const creator = ref([]);
const category = ref([]);

const publishedAt = computed<RangeValues | null>(() => {
  return {
    start: publishedFrom.value || null,
    end: publishedTo.value || null,
  };
});

const teamFilters = ref<HTMLElement | undefined>();
const dateFilters = ref<HTMLElement | undefined>();
const categoryFilters = ref<HTMLElement | undefined>();

const conditionalFiltersFlags = ref<CONDITIONAL_FILTERS[]>([]);

const addConditionalFilter = (filter: CONDITIONAL_FILTERS) => {
  conditionalFiltersFlags.value.push(filter);
};
const removeConditionalFilter = (filter: CONDITIONAL_FILTERS) => {
  const position = conditionalFiltersFlags.value.indexOf(filter);

  if (position === -1) {
    return;
  }

  conditionalFiltersFlags.value.splice(position, 1);
};
const isFilterEnabled = (filter: CONDITIONAL_FILTERS) => {
  return conditionalFiltersFlags.value.includes(filter);
};

const isLanguageChecked = (filterValue: string) => language.value.includes(filterValue);
const isStatusChecked = (filterValue: DocumentStatus) => status.value.includes(filterValue);
const isFlagChecked = (filterValue: ARTICLE_FLAGS) => flag.value.includes(filterValue);

const updateSelectedFlags = (filterValue: ARTICLE_FLAGS) => {
  if (isFlagChecked(filterValue)) {
    const position = flag.value.findIndex((element) => element === filterValue);
    flag.value.splice(position, 1);
    return;
  }

  flag.value.push(filterValue);
};
const updateSelectedStatuses = (filterValue: DocumentStatus) => {
  if (isStatusChecked(filterValue)) {
    const position = status.value.findIndex((element) => element === filterValue);
    status.value.splice(position, 1);
    return;
  }

  status.value.push(filterValue);
};
const updateSelectedLanguages = (filterValue: string) => {
  if (isLanguageChecked(filterValue)) {
    const position = language.value.findIndex((element) => element === filterValue);
    language.value.splice(position, 1);
    return;
  }

  language.value.push(filterValue);
};

const resetLocaleFilters = () => {
  conditionalFiltersFlags.value = [];

  deadline.value = null;
  createdAt.value = null;
  publishedFrom.value = null;
  publishedTo.value = null;
  embargoedUntil.value = null;

  language.value = [];
  status.value = [];
  flag.value = [];

  editor.value = [];
  copyEditor.value = [];
  writer.value = [];
  author.value = [];
  creator.value = [];
  category.value = [];
};

const onFiltersReset = () => {
  filtersStore.resetFilters();
  resetLocaleFilters();

  emits('reset');
};

const onFiltersApply = () => {
  const newFilters = {};

  newFilters.deadline = deadline.value ? deadline.value : undefined;
  newFilters.createdAt = createdAt.value ? createdAt.value : undefined;
  newFilters.publishedFrom = publishedFrom.value ? publishedFrom.value : undefined;
  newFilters.publishedTo = publishedTo.value ? publishedTo.value : undefined;
  newFilters.embargoedUntil = embargoedUntil.value ? embargoedUntil.value : undefined;

  newFilters.language = language.value.length ? language.value.slice() : undefined;
  newFilters.status = status.value.length ? status.value.slice() : undefined;

  newFilters.editor = editor.value.length ? editor.value.slice() : undefined;
  newFilters.copyEditor = copyEditor.value.length ? copyEditor.value.slice() : undefined;
  newFilters.writer = writer.value.length ? writer.value.slice() : undefined;
  newFilters.author = author.value.length ? author.value.slice() : undefined;
  newFilters.creator = creator.value.length ? creator.value.slice() : undefined;
  newFilters.category = category.value.length ? category.value.slice() : undefined;
  newFilters.flag = flag.value.length ? flag.value.slice() : undefined;

  if (!editor.value.length) removeConditionalFilter(CONDITIONAL_FILTERS.EDITOR);
  if (!copyEditor.value.length) removeConditionalFilter(CONDITIONAL_FILTERS.COPY_EDITOR);
  if (!writer.value.length) removeConditionalFilter(CONDITIONAL_FILTERS.WRITER);
  if (!author.value.length) removeConditionalFilter(CONDITIONAL_FILTERS.PUBLIC_AUTHOR);
  if (!creator.value.length) removeConditionalFilter(CONDITIONAL_FILTERS.CREATOR);
  if (!category.value.length) removeConditionalFilter(CONDITIONAL_FILTERS.CATEGORY);

  if (!embargoedUntil.value) removeConditionalFilter(CONDITIONAL_FILTERS.EMBARGO);
  if (!deadline.value) removeConditionalFilter(CONDITIONAL_FILTERS.DEADLINE_FOR_COVER);
  if (!publishedFrom.value) removeConditionalFilter(CONDITIONAL_FILTERS.PUBLISHED);
  if (!createdAt.value) removeConditionalFilter(CONDITIONAL_FILTERS.CREATED);

  Object.keys(newFilters).forEach((key) => newFilters[key] === undefined && delete newFilters[key]);

  filtersStore.resetFilters();
  filtersStore.setFilters(newFilters);

  emits('apply');
};

const createSnapshot = () => {
  return JSON.stringify({
    deadline: deadline.value,
    createdAt: createdAt.value,
    publishedFrom: publishedFrom.value,
    publishedTo: publishedTo.value,
    embargoedUntil: embargoedUntil.value,
    language: language.value,
    status: status.value,
    editor: editor.value,
    copyEditor: copyEditor.value,
    writer: writer.value,
    author: author.value,
    creator: creator.value,
    category: category.value,
    flag: flag.value,
  });
};

onMounted(() => {
  const filtersFromStore = filtersStore.state.filters;

  if (filtersFromStore.deadline) deadline.value = filtersFromStore.deadline;
  if (filtersFromStore.createdAt) createdAt.value = filtersFromStore.createdAt;
  if (filtersFromStore.publishedFrom) publishedFrom.value = filtersFromStore.publishedFrom;
  if (filtersFromStore.publishedTo) publishedTo.value = filtersFromStore.publishedTo;
  if (filtersFromStore.embargoedUntil) embargoedUntil.value = filtersFromStore.embargoedUntil;

  if (filtersFromStore.language) language.value = filtersFromStore.language.slice();
  if (filtersFromStore.status) status.value = filtersFromStore.status.slice();

  if (filtersFromStore.editor) editor.value = filtersFromStore.editor.slice();
  if (filtersFromStore.copyEditor) copyEditor.value = filtersFromStore.copyEditor.slice();
  if (filtersFromStore.writer) writer.value = filtersFromStore.writer.slice();
  if (filtersFromStore.author) author.value = filtersFromStore.author.slice();
  if (filtersFromStore.creator) creator.value = filtersFromStore.creator.slice();
  if (filtersFromStore.category) category.value = filtersFromStore.category.slice();
  if (filtersFromStore.flag) flag.value = filtersFromStore.flag.slice();

  if (editor.value.length) addConditionalFilter(CONDITIONAL_FILTERS.EDITOR);
  if (copyEditor.value.length) addConditionalFilter(CONDITIONAL_FILTERS.COPY_EDITOR);
  if (writer.value.length) addConditionalFilter(CONDITIONAL_FILTERS.WRITER);
  if (author.value.length) addConditionalFilter(CONDITIONAL_FILTERS.PUBLIC_AUTHOR);
  if (creator.value.length) addConditionalFilter(CONDITIONAL_FILTERS.CREATOR);
  if (category.value.length) addConditionalFilter(CONDITIONAL_FILTERS.CATEGORY);

  if (embargoedUntil.value) addConditionalFilter(CONDITIONAL_FILTERS.EMBARGO);
  if (deadline.value) addConditionalFilter(CONDITIONAL_FILTERS.DEADLINE_FOR_COVER);
  if (publishedFrom.value) addConditionalFilter(CONDITIONAL_FILTERS.PUBLISHED);
  if (createdAt.value) addConditionalFilter(CONDITIONAL_FILTERS.CREATED);

  initialFiltersSnapshot.value = createSnapshot();
});

const applyPublishDateFilter = (rangeValue: RangeValues | null) => {
  publishedFrom.value = getDateByUTC(rangeValue?.start);
  publishedTo.value = getDateByUTCWithValidEndHours(rangeValue?.end);
};

defineExpose({
  applyFilters: onFiltersApply,
  checkUnsavedChanges: () => {
    return initialFiltersSnapshot.value !== createSnapshot();
  },
});
</script>
