<template>
  <Button
    :size="SIZES.MEDIUM"
    :is-full-width="props.isFullWidth"
    data-testid="create-converter-button"
    @click="onCreateConverter"
  >
    <template #leftIcon>
      <Plus class="h-5 w-5" />
    </template>
    New Converter
  </Button>
</template>

<script lang="ts" setup>
import { SIZES } from '@/types';

import Button from '@/components/Button.vue';
import Plus from '@/assets/icons/plus.svg?component';
import { useRouter } from 'vue-router';
import { ConverterService } from '@/features/Converter/services';
import { useToast } from '@/composables';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    isFullWidth: boolean;
  }>(),
  {
    isFullWidth: false,
  },
);

const router = useRouter();
const toast = useToast();

const currentLanguageStore = useCurrentLanguageStore();
const languageId = computed(() => currentLanguageStore.currentLanguage.id);

const onCreateConverter = async () => {
  try {
    const { id } = await ConverterService.createConverter({
      languageId: languageId.value,
    });
    router.push({
      name: 'converter-edit',
      params: {
        id,
      },
    });
  } catch (e) {
    toast.errorTemporary({
      id: 'ERROR_CREATE_CONVERTER',
      message: 'New converter cant be created',
    });
  }
};
</script>
