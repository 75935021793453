import { toTypedSchema } from '@vee-validate/zod';
import zod from 'zod';
import { LABEL_FIELD_NAME, LABEL_FIELD_ENABLED, LABEL_FIELD_TYPE } from '@/features/Labels/constants';

const labelFormSchema = zod.object({
  [LABEL_FIELD_NAME]: zod.string().trim().min(1, { message: 'This is required' }),
  [LABEL_FIELD_ENABLED]: zod.boolean().optional().default(false),
  [LABEL_FIELD_TYPE]: zod.string().optional().default('default'),
});

export const labelFormTypedSchema: unknown = toTypedSchema(labelFormSchema);
