import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Document } from '@/features/ArticleLayout/types';
import { useArticleUpdatedAt } from '@/stores/article.store';
import type { DocumentFetchResponse, DocumentPatchResponse } from '@/features/ArticleLayout/types';
import { getTags } from '@/features/ArticleLayout/helpers/article';

interface ArticleState extends Document, errorsInterface {}
interface errorsInterface {
  [key: string]: string;
}

const defaultState = {
  authorId: null,
  title: '',
  dek: '',
  fulltext: '',

  // Metadata
  slug: null,
  assignment: '',
  needsOriginalArtwork: false,
  writerId: null,
  editorId: null,
  copyEditorId: null,
  coverImageDeadLineAt: null,
  coverDeadline: null,
  publishedAt: null,
  updatedAt: null,
  embargoUntil: null,
  categoryId: null,
  badgeId: null,
  superTags: [],
  tags: [],

  twitterPost: '',
  twitterExcludeRss: false,
  isExcludedFromTelegram: false,

  seoMetaDescription: '',

  isBreakingNews: false,
  isShowingInMarkets: false,
  excludeFromAllRss: false,
  hideFromHotStories: false,
  hideFromMainPage: false,
  isPromoPost: false,
  hasPost: false,
  needsProofreading: false,
  canBeRepublished: false,
  isOriginalContent: false,
  status: 0,

  language: null,
  languages: null,
  originalLanguageId: null,

  // Cover
  coverArt: null,
  youtubeUrl: null,

  postUrl: '',

  words: 0,
  characters: 0,
  coverSetBy: 'No',
  approvedBy: 'No',
  errors: {},
};

const stringFields = ['title', 'dek', 'seoMetaDescription', 'twitterPost', 'assignment'];

export const useArticleStore = defineStore('useArticleStore', () => {
  const state = ref<ArticleState>({ ...defaultState });

  const updatedAtStore = useArticleUpdatedAt();

  const setArticleData = (articleData: Document) => {
    const keysToSkip = ['tags', 'superTags', 'updatedAt'];

    Object.keys(articleData).forEach((key) => {
      if (keysToSkip.includes(key)) {
        return;
      }

      if (key in state.value) {
        state.value[key] = key in articleData ? articleData[key] : state.value[key];
      }

      if (stringFields.includes(key)) {
        state.value[key] = articleData[key] || '';
      }
    });

    if (articleData.publishedAt) {
      const publishDate = new Date(articleData.publishedAt);
      state.value.publishedAt = publishDate.toISOString();
    }

    state.value.updatedAt = updatedAtStore.state;

    if (articleData.tags) {
      state.value.tags = getTags({
        tags: articleData.tags || state.value.tags || [],
        isSuper: false,
      });

      state.value.superTags = getTags({
        tags: articleData.tags || state.value.tags || [],
        isSuper: true,
      });
    }

    if (articleData.coverArt?.id) {
      state.value.coverArt = articleData.coverArt.id;
    } else if ('coverArt' in articleData) {
      state.value.coverArt = null;
    }
  };

  const updateField = (field: string, value: string | number) => {
    state.value[field] = value;
  };

  const setArticleAdditionalData = (data: DocumentFetchResponse | DocumentPatchResponse, users) => {
    state.value.words = data.words || 0;
    state.value.characters = data.characters || 0;

    const approvedByUser = users.value?.find((item: SelectItem) => item.id === data.approvedBy);
    const coverSetByUser = users.value?.find((item: SelectItem) => item.id === data.coverSetBy);

    state.value.approvedBy = approvedByUser ? approvedByUser.label : 'No';
    state.value.coverSetBy = coverSetByUser ? coverSetByUser.label : 'No';
  };

  const cleanArticleData = () => {
    state.value = { ...defaultState };
  };

  const setArticleErrors = (errors: Record<string, string>) => {
    state.value.errors = errors;
  };

  const isOriginalArticle = () => {
    if (!state.value.language || !state.value.originalLanguageId) return true;
    return state.value.language === state.value.originalLanguageId;
  };

  return {
    state,
    setArticleData,
    setArticleAdditionalData,
    cleanArticleData,
    setArticleErrors,
    updateField,
    isOriginalArticle,
  };
});
