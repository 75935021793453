import type { LabelsFetchResponse } from '@/features/Labels/types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useSimpleAction, useToast } from '@/composables';
import { useLabelsFilters } from '@/features/Labels/stores/filters.store';
import { DEFAULT_LANGUAGE_ID } from '@/constants/queries';
import { fetchLabels } from '@/features/Labels/api';
import { useLabelsPagination } from '@/features/Labels/stores/pagination.store';

interface LabelsStore {
  labels: LabelsFetchResponse;
  isLoading: boolean;
}

export const useLabelsStore = defineStore('useLabelsStore', () => {
  const state = ref<LabelsStore>({
    labels: [],
    isLoading: false,
  });

  const toast = useToast();

  const paginationStore = useLabelsPagination();
  const filtersStore = useLabelsFilters();

  const { action } = useSimpleAction(async () => {
    try {
      state.value.isLoading = true;
      const payload = {
        page: paginationStore.state.page,
        itemsPerPage: paginationStore.state.limit,
        pagination: true,
        searchQuery: filtersStore.state.searchQuery || undefined,
        languageId: DEFAULT_LANGUAGE_ID,
      };

      if (filtersStore.state.searchQuery) {
        payload['filter[search]'] = filtersStore.state.searchQuery;
      }

      for (const key in filtersStore.state.sort) {
        if (filtersStore.state.sort[key]) {
          payload[`order[${key}]`] = filtersStore.state.sort[key];
        }
      }

      const { items, itemsPerPage, page, total } = await fetchLabels(payload);

      state.value.labels = items;
      paginationStore.state = {
        limit: itemsPerPage,
        total,
        page,
      };

      state.value.isLoading = false;
    } catch (err) {
      state.value.isLoading = false;
      toast.errorTemporary({ id: 'ERROR_FETCH_LABELS_LIST', message: 'Something went wrong, please try later' });
      throw err;
    }
  });

  return {
    fetchLabels: action,
    state,
  };
});
