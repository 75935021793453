import { useMutation } from '@tanstack/vue-query';
import { type DocumentPatchPayload } from '@/types';

import { useToast } from '@/composables/useToast';
import { patchDocument, translateDocument } from '@/features/ArticleLayout/api';
import type { DocumentFetchResponse } from '@/features/ArticleLayout/types.ts';
import type { AxiosError, AxiosResponse } from 'axios';

interface DocumentMutateRequest {
  documentId: number;
  languageId: number;
  values: DocumentPatchPayload;
  userHash: string;
}

export const useMutateDocument = () => {
  const { isPending, isError, isSuccess, mutate, mutateAsync } = useMutation({
    mutationFn: ({ documentId, languageId, values, userHash }: DocumentMutateRequest) =>
      patchDocument(documentId, languageId, values, userHash),
  });

  return {
    isPending,
    isError,
    isSuccess,
    mutate,
    mutateAsync,
  };
};

interface TranslateDocumentRequest {
  documentId: number;
  languageId: number;
}

interface TranslateDocumentParams {
  onSuccess?: (data: DocumentFetchResponse, variables: TranslateDocumentRequest, context: unknown) => void;
}

export const useTranslateDocument = (params: TranslateDocumentParams = {}) => {
  const toast = useToast();

  return useMutation({
    mutationFn: ({ documentId, languageId }: TranslateDocumentRequest) => translateDocument(documentId, languageId),
    onError: ({ data }: AxiosResponse) => {
      if (data?.errorMessage) {
        return toast.error({
          id: data.errorCode,
          message: data.errorMessage,
        });
      }

      return toast.error({
        id: 'ARTICLE_TRANSLATE_ERROR',
        message: `Failed to translate article. Try again.`,
      });
    },
    ...params,
  });
};
