<template>
  <aside
    ref="scrollableArea"
    class="bg-imperium-bg-sub-base flex rounded-2xl lg:pr-0 right-0 top-0 z-[60] lg:z-10 fixed lg:relative shadow-[0px_1px_3px_rgba(0,_0,_0,_0.1),_0px_1px_2px_-1px_rgba(0,_0,_0,_0.1)] transition-[right,margin-right]"
    :class="{
      'mr-0': props.isOpened,
      '-mr-[626px] -right-[626px] lg:mr-0 lg:right-0': !isMobile && !props.isOpened,
      '-mr-[453px] -right-[453px] lg:mr-0 lg:right-0': isMobile && !props.isOpened,
      'top-[57px]': !isDesktop && props.isOpened,

      // Scrolling
      'overflow-y-scroll lg:overflow-y-visible max-h-svh lg:max-h-[unset] overscroll-none scrollbar-thin': true,
    }"
  >
    <section
      class="relative h-full max-w-[372px] lg:w-full p-4 rounded-2xl z-[110] rounded-tr-none rounded-br-none shadow-[0px_1px_3px_rgba(0,_0,_0,_0.1),_0px_1px_2px_-1px_rgba(0,_0,_0,_0.1)]"
    >
      <section :data-anchor="METADATA_SECTIONS.INFO">
        <MetadataHeader
          :is-opened="props.isOpened"
          @open="emits('open')"
          @hide="emits('hide')"
        />

        <MetadataTranslates :language-id="props.languageId" />
        <MetadataSectionPublished v-if="articleStore.state.hasPost && props.status === DocumentStatus.PUBLISHED" />
        <MetadataMetaSection />

        <div v-if="documentMetadataSlugView">
          <Tooltip
            :key="`tooltip-slug`"
            width="unset"
            name="slug-info"
            placement="auto"
            :tooltip-visible="!documentMetadataSlugEdit && !documentMetadataSlugCondition"
            button-class="w-full text-left"
          >
            <template #content>
              <ArticleUrlInput
                :is-disabled="
                  (!documentMetadataSlugEdit && !documentMetadataSlugCondition) ||
                  props.hasPost ||
                  !userStore.hasAccessToCurrentLanguage()
                "
                name="articleSlug"
                :has-post="articleStore.state.hasPost"
                :article-url="articleStore.state.slug || articleStore.state.title"
                :article-title="articleStore.state?.title || ''"
                :article-url-attrs="props.articleUrlAttrs"
                :is-readonly="props.hasPost"
                @update:article-url="(value) => emits('update-field', 'slug', value)"
              />
            </template>

            <template #tooltip>
              <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
            </template>
          </Tooltip>
        </div>

        <MetadataSectionCover
          :data-anchor="METADATA_SECTIONS.COVER"
          :ai-image-suggestions="props.aiImageSuggestions"
          :is-assignment-errored="props.isAssignmentErrored"
          :is-ai-image-suggestions-loading="props.isAiImageSuggestionsLoading"
          :errors="props.errors"
          :assignment-attrs="props.assignmentAttrs"
          :is-breaking-news-attrs="props.isBreakingNewsAttrs"
          @open-ai-image-modal="emits('open-ai-image-modal')"
          @open-insert-image-modal="emits('open-insert-image-modal')"
          @remove-cover="emits('remove-cover')"
          @update-cover-art="(value) => emits('update-field', 'coverArt', value)"
          @update-assignment="(value) => emits('update-field', 'assignment', value)"
          @update-breaking="(value) => emits('update-field', 'isBreakingNews', value)"
          @update-coverDeadline="(value) => emits('update-field', 'coverDeadline', value)"
        />
      </section>

      <MetadataSectionTeam
        class="mb-6"
        :data-anchor="METADATA_SECTIONS.TEAM"
        :writer-attrs="props.writerAttrs"
        :editor-attrs="props.editorAttrs"
        :copy-editor-attrs="props.copyEditorAttrs"
        @update:copy-editor="(value) => emits('update-field', 'copyEditorId', value)"
        @update:writer="(value) => emits('update-field', 'writerId', value)"
        @update:editor="(value) => emits('update-field', 'editorId', value)"
      />

      <MetadataSectionSchedule
        class="mb-6"
        :data-anchor="METADATA_SECTIONS.SCHEDULE"
        :deadline-attrs="props.deadlineAttrs"
        :publish-attrs="props.publishAttrs"
        :embargo-attrs="props.embargoAttrs"
        @update:embargo="(value) => emits('update-field', 'embargoUntil', value)"
        @update:publish="(value) => emits('update-field', 'publishedAt', value)"
      />

      <MetadataSectionTaxonomy
        class="mb-6"
        :data-anchor="METADATA_SECTIONS.TAXONOMY"
        :category-attrs="props.categoryAttrs"
        :label-attrs="props.labelAttrs"
        :super-tags-attrs="props.superTagsAttrs"
        :is-super-tags-errored="props.isSuperTagsErrored"
        :errors="errors"
        :tags-attrs="props.tagsAttrs"
        @update:category="(value) => emits('update-field', 'categoryId', value)"
        @update:label="(value) => emits('update-field', 'badgeId', value)"
        @update:super-tags="(value) => emits('update-field', 'superTags', value)"
        @update:tags="(value) => emits('update-field', 'tags', value)"
      />

      <MetadataSectionSocialNetworks
        class="mb-6"
        :errors="errors"
        :data-anchor="METADATA_SECTIONS.SOCIALS"
        :twitter-post-attrs="props.twitterPostAttrs"
        :twitter-exclude-rss-attrs="props.twitterExcludeRssAttrs"
        :twitter-exclude-channels-attrs="props.twitterExcludeChannelsAttrs"
        @update:twitter-exclude-channels="(value) => emits('update-field', 'isExcludedFromTelegram', value)"
        @update:twitter-exclude-rss="(value) => emits('update-field', 'twitterExcludeRss', value)"
        @update:twitter-post="(value) => emits('update-field', 'twitterPost', value)"
      />

      <MetadataSectionSeo
        class="mb-6"
        :data-anchor="METADATA_SECTIONS.SEO"
        :errors="errors"
        :description-attrs="props.descriptionAttrs"
        :is-meta-description-errored="props.isMetaDescriptionErrored"
        @update:description="(value) => emits('update-field', 'seoMetaDescription', value)"
      />

      <MetadataSectionArticleSettings
        :data-anchor="METADATA_SECTIONS.SETTINGS"
        :is-show-in-markets-attrs="props.isShowInMarketsAttrs"
        :is-exclude-from-rss-attrs="props.isExcludeFromRssAttrs"
        :is-hide-from-hot-attrs="props.isHideFromHotAttrs"
        :is-hide-from-main-page-attrs="props.isHideFromMainPageAttrs"
        :is-promo-attrs="props.isPromoAttrs"
        @update:is-breaking-news="(value) => emits('update-field', 'isBreakingNews', value)"
        @update:is-exclude-from-rss="(value) => emits('update-field', 'excludeFromAllRss', value)"
        @update:is-hide-from-hot="(value) => emits('update-field', 'hideFromHotStories', value)"
        @update:is-hide-from-main-page="(value) => emits('update-field', 'hideFromMainPage', value)"
        @update:is-promo="(value) => emits('update-field', 'isPromoPost', value)"
        @update:is-original-content="(value) => emits('update-field', 'isOriginalContent', value)"
        @update:canBeRepublished="(value) => emits('update-field', 'canBeRepublished', value)"
        @update:is-show-in-markets="(value) => emits('update-field', 'isShowingInMarkets', value)"
      />
    </section>

    <MetadataNavigation
      v-if="scrollableArea"
      ref="navigationRef"
      :area="scrollableArea"
    />

    <div id="metadata-tooltips" />
  </aside>
</template>

<script lang="ts" setup>
import { inject, ref } from 'vue';

import { DocumentStatus } from '@/types';
// TODO: Consider to avoid relative path ./components => @/components...
import MetadataNavigation from './MetadataNavigation.vue';
import ArticleUrlInput from './components/ArticleUrlInput.vue';
import MetadataSectionTeam from './components/MetadataSectionTeam.vue';
import MetadataSectionSchedule from './components/MetadataSectionSchedule.vue';
import MetadataSectionTaxonomy from './components/MetadataSectionTaxonomy.vue';
import MetadataSectionSocialNetworks from './components/MetadataSectionSocialNetworks.vue';
import MetadataSectionSeo from './components/MetadataSectionSeo.vue';
import MetadataSectionArticleSettings from './components/MetadataSectionArticleSettings.vue';

import { METADATA_SECTIONS } from './constants';
import { useUserPermissions, useUserStore } from '@/stores/user.store';
import type { SearchMultidropdownItem } from '@/components/FormSearchMultiDropdown.vue';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store';
import MetadataMetaSection from '@/features/ArticleLayout/components/MetadataMetaSection.vue';
import MetadataHeader from '@/features/ArticleLayout/components/MetadataHeader.vue';
import Tooltip from '@/components/Tooltip.vue';
import { useUserConditions } from '@/composables/useUserConditions.ts';

import MetadataSectionCover from '@/features/ArticleLayout/components/MetadataSectionCover.vue';
import MetadataSectionPublished from '@/features/ArticleLayout/components/MetadataSectionPublished.vue';
import MetadataTranslates from '@/features/ArticleLayout/components/MetadataTranslates.vue';

const props = withDefaults(
  defineProps<{
    isOpened: boolean;

    articleUrl: string;
    status: DocumentStatus | undefined;
    coverDeadline: string;
    errors: Record<string, unknown>;

    languageId: number;
    coverImageId: number | undefined | null;
    hasPost: boolean;

    articleUrlAttrs: Record<string, unknown>;
    assignmentAttrs: Record<string, unknown>;
    needsCoverAttrs: Record<string, unknown>;
    writerAttrs: Record<string, unknown>;
    editorAttrs: Record<string, unknown>;
    copyEditorAttrs: Record<string, unknown>;
    deadlineAttrs: Record<string, unknown>;
    publishAttrs: Record<string, unknown>;
    coverDeadlineAttrs: Record<string, unknown>;
    embargoAttrs: Record<string, unknown>;
    categoryAttrs: Record<string, unknown>;
    labelAttrs: Record<string, unknown>;
    superTagsAttrs: Record<string, unknown>;
    tagsAttrs: Record<string, unknown>;
    twitterPostAttrs: Record<string, unknown>;
    twitterExcludeRssAttrs: Record<string, unknown>;
    twitterExcludeChannelsAttrs: Record<string, unknown>;
    descriptionAttrs: Record<string, unknown>;
    isBreakingNewsAttrs: Record<string, unknown>;
    isShowInMarketsAttrs: Record<string, unknown>;
    isExcludeFromRssAttrs: Record<string, unknown>;
    isHideFromHotAttrs: Record<string, unknown>;
    isHideFromMainPageAttrs: Record<string, unknown>;
    isPromoAttrs: Record<string, unknown>;

    isAssignmentErrored: boolean;
    aiImageSuggestions: any[];
    isAiImageSuggestionsLoading: boolean;
  }>(),
  {
    isOpened: false,
  },
);

const emits = defineEmits<{
  (event: 'update-field', field: string, value: string | string[] | boolean | Date): void;

  (event: 'open'): void;
  (event: 'hide'): void;
  (event: 'open-ai-image-modal'): void;
  (event: 'open-insert-image-modal'): void;
  (event: 'remove-cover'): void;
}>();

const articleStore = useArticleStore();

const { documentMetadataSlugView, documentMetadataSlugEdit } = useUserPermissions();

const { documentMetadataSlugCondition } = useUserConditions();

const userStore = useUserStore();

const isMobile = inject<boolean>('isMobile');
const isDesktop = inject<boolean>('isDesktop');

const scrollableArea = ref<HTMLElement | null>(null);
const navigationRef = ref<HTMLElement | null>(null);

defineExpose({
  scrollToField: (field: string) => {
    if (!navigationRef.value) {
      return;
    }

    navigationRef.value.scrollToField(field);
  },
});
</script>

<style lang="scss" scoped>
[data-anchor] {
  scroll-margin: 80px;
}
</style>
