<template>
  <TranslateButton
    :is-loading="isPending"
    :document-language-id="props.documentLanguageId"
    :is-disabled="props.isDisabled"
    @select="selectLanguage"
  />
</template>
<script setup lang="ts">
import TranslateButton from '@/features/ArticleLayout/components/TranslateButton.vue';
import { useTranslateDocument } from '@/features/ArticleLayout/queries/useMutateDocument.ts';
import type { Language } from '@/types';
import { useRoute } from 'vue-router';
import { useArticleRouting } from '@/features/Articles/composables/routing.ts';
import { useToast } from '@/composables';

const route = useRoute();
const toast = useToast();

const props = defineProps<{
  documentId: number;
  documentLanguageId: number;
  isDisabled?: boolean;
}>();

const { mutateAsync, isPending } = useTranslateDocument({
  onSuccess: (article) => {
    goToArticleEdit(
      {
        id: article.id,
        languageId: article.language,
      },
      true,
    );

    toast.success({
      id: 'ARTICLE_TRANSLATED',
      message: 'The article was translated successfully',
    });
  },
});

const { goToArticleEdit } = useArticleRouting();

const selectLanguage = (language: Language) => {
  mutateAsync({
    languageId: language.id,
    documentId: +route.params.id,
  });
};
</script>
