<template>
  <UploadMediaFilterRow :is-choosing-media="props.isChoosingMedia" />
  <div class="p-4 mt-4 bg-imperium-bg-sub-base rounded-2xl shadow-imperium-fg-base">
    <div
      v-if="mediaStore.state.isMediaLoading"
      class="w-full flex align-middle justify-center items-center min-h-[60vh] max-w-[100vw]"
    >
      <LoaderIcon class="h-10 w-10" />
    </div>
    <div v-else>
      <div
        v-if="mediaStore.state.media?.length"
        :class="
          tableClass({
            isChoosingMedia: props.isChoosingMedia,
          })
        "
      >
        <div
          v-for="media in mediaStore.state.media"
          :key="media.id"
          @click="onEditImage(media)"
        >
          <MediaCardTable
            :last-usage="media.lastUsage"
            :src="media.url"
          />
        </div>
      </div>
      <div
        v-else
        class="w-full flex align-middle justify-center items-center min-h-[60vh] max-w-[100vw]"
      >
        <div class="font-semibold text-imperium-fg-strong">No media found</div>
      </div>
    </div>

    <Pagination
      :current-page="paginationStore.state.page"
      :is-limit-visible="isLimitVisible"
      :is-mobile="isMobile || isTablet || props.isChoosingMedia"
      :items-count="paginationStore.state.total || 0"
      :limit="paginationStore.state.limit"
      :limits="limits"
      class="mt-5"
      @change-page="paginationStore.changePage"
      @change-limit="paginationStore.changeLimit"
    />

    <Teleport to="#modals">
      <EditMediaModal
        v-if="mediaStore.state.editedImage"
        :is-visible="isEditModalOpen"
        :media-id="mediaStore.state.editedImage"
        @close="onClose"
      />
    </Teleport>
  </div>
</template>
<script lang="ts" setup>
import { inject, onMounted, ref, watch } from 'vue';
import { useMediaList } from '@/features/Media/stores/media.store';
import { useMediaFilters } from '@/features/Media/stores/filters.store';
import { useMediaPagination } from '@/features/Media/stores/pagination.store';
import UploadMediaFilterRow from '@/features/Media/components/UploadMediaFilterRow.vue';
import MediaCardTable from '@/features/Media/components/MediaCardTable.vue';
import isEqual from 'lodash/isEqual';
import Pagination from '@/components/Table/Pagination/Pagination.vue';
import EditMediaModal from '@/features/UploadImage/components/EditMediaModal.vue';
import LoaderIcon from '@/assets/icons/spinner.svg?component';
import { tv } from 'tailwind-variants';
import type { Media } from '@/features/Media/types';

const limits = [24, 48, 96];

const props = withDefaults(
  defineProps<{
    isChoosingMedia?: boolean;
    isLimitVisible?: boolean;
  }>(),
  {
    isChoosingMedia: false,
    isLimitVisible: true,
  },
);

const emits = defineEmits<{
  (event: 'choose', value: Media): void;
}>();

const filtersStore = useMediaFilters();
const paginationStore = useMediaPagination();
const mediaStore = useMediaList();

const limit = props.isChoosingMedia ? 8 : 24;
paginationStore.setState({ limit, total: 0 });

const isMobile = inject<boolean>('isMobile');
const isTablet = inject<boolean>('isTablet');

const isEditModalOpen = ref<boolean>(false);

const isInitialized = ref<boolean>(false);

watch(
  () => [filtersStore.state, paginationStore.state],
  (newValue, oldValue) => {
    // prevent watcher from call after page initialization
    if (!isInitialized.value) {
      isInitialized.value = true;
      return;
    }
    const isFiltersEqual = isEqual(newValue, oldValue);
    if (!isFiltersEqual) {
      mediaStore.state.media = [];
      mediaStore.fetchMediaList();
    }
  },
  { deep: true },
);

const tableClass = tv({
  base: 'grid grid-cols-4 gap-4',
  variants: {
    isChoosingMedia: {
      false: 'xs:grid-cols-4 xl:grid-cols-6',
    },
  },
});

const onEditImage = (media: Media) => {
  if (props.isChoosingMedia) {
    emits('choose', media);
  } else {
    mediaStore.state.editedImage = media.id;
    isEditModalOpen.value = true;
  }
};

const onClose = () => {
  isEditModalOpen.value = false;
  mediaStore.cleanEditingMedia();
};

onMounted(mediaStore.fetchMediaList);
</script>
