export const LABELS_RESOURCE = 'labels-resource';
export const LABEL_RESOURCE = 'label-resource';

export const LABEL_FIELD_NAME = 'title';
export const LABEL_FIELD_ID = 'id';
export const LABEL_FIELD_ENABLED = 'enabled';
export const LABEL_FIELD_DESCRIPTION = 'description';
export const LABEL_FIELD_BADGE = 'badge';
export const LABEL_FIELD_TYPE = 'label';
export const LABEL_FIELD_CATEGORIES = 'categories';
export const LABEL_FIELD_CATEGORY_TRANSLATE = 'categoryTranslate';

export const LABEL_TRANSLATE_FIELD_NAME = 'title';
export const LABEL_TRANSLATE_FIELD_ID = 'id';
export const LABEL_TRANSLATE_FIELD_BADGE = 'badge';

export const enum LABEL_TYPE {
  SUCCESS = 'Success (Green color)',
  DEFAULT = 'Default (Orange color)',
  WARNING = 'Warning (Red color)',
  DANGER = 'Danger (Red color)',
  INFO = 'Info (Gray color)',
}

export const LABEL_TYPE_LIST = [
  {
    id: 'default',
    label: LABEL_TYPE.DEFAULT,
  },
  {
    id: 'success',
    label: LABEL_TYPE.SUCCESS,
  },
  {
    id: 'warning',
    label: LABEL_TYPE.WARNING,
  },
  {
    id: 'danger',
    label: LABEL_TYPE.DANGER,
  },
  {
    id: 'info',
    label: LABEL_TYPE.INFO,
  },
];
