<template>
  <div class="flex w-full justify-between items-center py-5 px-3 mt-5 border-t border-imperium-border-weak">
    <p class="text-xs text-imperium-fg-base font-semibold">Time</p>

    <div class="flex items-center gap-2">
      <div class="relative">
        <input
          ref="timeInput"
          v-model="localTime"
          class="block z-50 w-full relative z-1 input-rounded-default input-primary p-1.5 max-w-[90px]"
          placeholder="00:00"
          maxlength="5"
          @click="focusOnInput"
          @input="onTimeUpdate"
        />
        <div class="absolute w-fit h-full z-10 inset-y-0 flex items-center right-2">
          <ClockIcon class="-w-4 h-4 text-imperium-fg-muted" />
        </div>
      </div>

      <div
        class="flex items-center rounded cursor-pointer border-imperium-border-base border"
        role="radiogroup"
        aria-label="Time of Day"
      >
        <div
          class="text-sm p-2 hover:bg-imperium-bg-3"
          tabindex="0"
          role="radio"
          aria-checked="true"
          aria-label="AM"
          :class="{
            'bg-imperium-bg-3': timeMeridian === 'AM',
          }"
          @click="onMeridianChangeToAM"
        >
          AM
        </div>
        <hr class="h-9 w-px bg-imperium-bg-4" />
        <div
          class="text-sm p-2 hover:bg-imperium-bg-3"
          tabindex="-1"
          role="radio"
          aria-checked="false"
          aria-label="PM"
          :class="{
            'bg-imperium-bg-3': timeMeridian === 'PM',
          }"
          @click="onMeridianChangeToPM"
        >
          PM
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import type { TimepickerItem } from './types';
import ClockIcon from '@/assets/icons/clock.svg?component';

defineProps<{
  modelValue: TimepickerItem | undefined;
}>();

const emits = defineEmits<{
  (event: 'update:model-value', value: TimepickerItem): void;
}>();

const timeMeridian = ref<string>('AM');
const localTime = ref<string>('');
const timeInput = ref<HTMLElement | null>(null);

const focusOnInput = (): void => {
  if (timeInput.value) {
    timeInput.value.focus();
  }
};

const isValidTime = (time: string): boolean => {
  if (time.length === 1) {
    return Number(time[0]) < 2;
  }
  if (time.length === 4) {
    return Number(time[3]) <= 5;
  }
  if (time.length > 1 && time.startsWith('1')) {
    return Number(time[1]) <= 2;
  }

  return time.startsWith('1') || time.startsWith('0');
};

const numberRegex = /^[0-9]+$/;
const onTimeUpdate = (event: Event) => {
  let time = (event.target as HTMLInputElement).value.replace(/[^0-9:.]/g, '').replace(/(\..*?)\..*/g, '$1');

  if (time.length === 2 && event.inputType !== 'deleteContentBackward') time += ':';

  if (isValidTime(time) && !numberRegex.test(time)) {
    localTime.value = time;
    updateTime(time);
  } else if (time.length === 1 && Number(time[0]) > 1) {
    localTime.value = '';
  } else if (time.length === 4 && Number(time[3]) > 5) {
    localTime.value = time.slice(0, 3);
  } else {
    localTime.value = time[0];
  }
};

const updateTime = (time: string): void => {
  if (time.length >= 4) {
    const { hours, minutes } = convertTime12to24(time);

    if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
      emits('update:model-value', {
        hours,
        minutes,
      });
    }
  }
};

const convertTime12to24 = (time12h: string) => {
  const [hours, minutes] = time12h.split(':');

  let hoursNum = parseInt(hours, 10);
  const minutesNum = parseInt(minutes, 10);

  if (timeMeridian.value === 'PM' && hoursNum !== 12) {
    hoursNum += 12;
  }

  if (timeMeridian.value === 'AM' && hoursNum === 12) {
    hoursNum = 0;
  }

  return {
    hours: hoursNum,
    minutes: minutesNum,
  };
};

const onMeridianChangeToPM = (): void => {
  timeMeridian.value = 'PM';
  updateTime(localTime.value);
};

const onMeridianChangeToAM = (): void => {
  timeMeridian.value = 'AM';
  updateTime(localTime.value);
};
</script>
