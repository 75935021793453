<template>
  <section>
    <h3 class="font-semibold text-base text-imperium-fg-base mb-2">Taxonomy</h3>

    <Tooltip
      :key="`tooltip-category`"
      width="unset"
      name="category-info"
      placement="auto"
      :tooltip-visible="!documentMetadataCategoryEdit && !documentMetadataCategoryCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <FormSelect
          v-if="documentMetadataCategoryView"
          name="category"
          class="mb-2"
          placeholder="Not selected"
          :model-value="articleStore.state.categoryId"
          :attrs="props.categoryAttrs"
          :is-errored="!!articleErrors.categoryId"
          :is-disabled="
            (!documentMetadataCategoryEdit && !documentMetadataCategoryCondition) ||
            !articleStore.isOriginalArticle() ||
            !userStore.hasAccessToCurrentLanguage()
          "
          is-required
          :size="SIZES.SMALL"
          :values="categories"
          :variant="VARIANTS.MUTED"
          @update:model-value="onCategoryChange"
        >
          <template #label>
            <span class="text-xs">Category</span>
          </template>

          <template #error> {{ articleErrors.categoryId }}</template>
        </FormSelect>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-label`"
      width="unset"
      name="label-info"
      placement="auto"
      :tooltip-visible="!documentMetadataLabelEdit && !documentMetadataLabelCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <FormSelect
          v-if="documentMetadataLabelView"
          name="label"
          class="mb-2"
          placeholder="Not selected"
          :model-value="articleStore.state.badgeId"
          :attrs="props.labelAttrs"
          :is-errored="!!articleErrors.badgeId"
          :is-disabled="
            (!documentMetadataLabelEdit && !documentMetadataLabelCondition) ||
            isDisabledLabelsInput ||
            !userStore.hasAccessToCurrentLanguage()
          "
          :size="SIZES.SMALL"
          :values="badges"
          is-required
          :variant="VARIANTS.MUTED"
          @update:model-value="(value) => emits('update:label', value)"
        >
          <template #label>
            <span class="text-xs">Label</span>
          </template>

          <template #error> {{ articleErrors.badgeId }} </template>
        </FormSelect>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-supertags`"
      width="unset"
      name="supertags-info"
      placement="auto"
      :tooltip-visible="!documentMetadataSuperTagsEdit && !documentMetadataSuperTagsCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <TagsMultiDropdown
          v-if="documentMetadataSuperTagsView"
          :is-super="true"
          name="superTags"
          :model-value="articleStore.state.superTags"
          :is-disabled="
            (!documentMetadataSuperTagsEdit && !documentMetadataSuperTagsCondition) ||
            !articleStore.isOriginalArticle() ||
            !userStore.hasAccessToCurrentLanguage()
          "
          :attrs="props.superTagsAttrs"
          is-required
          :is-errored="!!articleErrors.superTags"
          :init-values="initSuperTags"
          @update:model-value="
            (value) => {
              selectedSuperTags = value;
              isSelectedSuperTagsChanged = true;
              onTagsAndSuperTagsUpdate(value);
            }
          "
        >
          <template #label>
            <span class="text-xs">Super tags</span>
          </template>

          <template #error> Select super tag </template>
        </TagsMultiDropdown>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-tags`"
      width="unset"
      name="tags-info"
      placement="auto"
      :tooltip-visible="!documentMetadataTagsEdit && !documentMetadataTagsCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <TagsMultiDropdown
          v-if="documentMetadataTagsView"
          :is-super="false"
          name="tags"
          is-required
          :model-value="articleStore.state.tags"
          :is-disabled="
            (!documentMetadataTagsEdit && !documentMetadataTagsCondition) ||
            !articleStore.isOriginalArticle() ||
            !userStore.hasAccessToCurrentLanguage()
          "
          :attrs="props.tagsAttrs"
          :is-errored="!!articleErrors.tags"
          :init-values="initTags"
          @update:model-value="
            (value) => {
              selectedTags = value;
              isSelectedTagsChanged = true;
              onTagsAndSuperTagsUpdate(value);
            }
          "
        >
          <template #label>
            <span class="text-xs">Tags</span>
          </template>

          <template #error> {{ errors.tags }} </template>
        </TagsMultiDropdown>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>
  </section>
</template>

<script lang="ts" setup>
import { ref, onMounted, watchEffect, watch, shallowRef, computed } from 'vue';
import FormSelect, { SelectItem } from '@/components/FormSelect.vue';
import { SIZES, SortStatus, VARIANTS } from '@/types';
import type { ArticleTagsFetchRequest, Tag } from '../types';
import { useUserPermissions, useUserStore } from '@/stores/user.store';
import { useArticleTagsStore } from '@/features/CollaborativeEditor/stores/article-tags.store';
import TagsMultiDropdown from '@/features/ArticleLayout/components/TagsMultiDropdown.vue';
import type { SearchMultidropdownItem } from '@/components/FormSearchMultiDropdown.vue';
import { fetchCategories, fetchTags } from '@/features/ArticleLayout/api';
import { useBadges } from '@/features/ArticleLayout/composables';
import { useUserConditions } from '@/composables/useUserConditions.ts';
import Tooltip from '@/components/Tooltip.vue';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store.ts';
import { useArticleCurrentLanguageStore } from '@/stores/article-current-language.ts';

const props = defineProps<{
  categoryAttrs: Record<string, unknown>;
  labelAttrs: Record<string, unknown>;
  superTagsAttrs: Record<string, unknown>;
  tagsAttrs: Record<string, unknown>;
  isTagsErrored: boolean;
  errors: Record<string, unknown>;
}>();

const emits = defineEmits<{
  (event: 'update:category', value: string): void;
  (event: 'update:label', value: string): void;
  (event: 'update:super-tags', value: string[]): void;
  (event: 'update:tags', value: string[]): void;
}>();

const {
  documentMetadataCategoryView,
  documentMetadataCategoryEdit,
  documentMetadataLabelView,
  documentMetadataLabelEdit,
  documentMetadataSuperTagsView,
  documentMetadataSuperTagsEdit,
  documentMetadataTagsView,
  documentMetadataTagsEdit,
} = useUserPermissions();

const {
  documentMetadataCategoryCondition,
  documentMetadataLabelCondition,
  documentMetadataSuperTagsCondition,
  documentMetadataTagsCondition,
} = useUserConditions();

const articleStore = useArticleStore();
const userStore = useUserStore();

const currentLanguageStore = useArticleCurrentLanguageStore();
const languageId = computed(() => currentLanguageStore.state.id);

const { setTags } = useArticleTagsStore();

const initTags = ref<SearchMultidropdownItem[]>([]);
const initSuperTags = ref<SearchMultidropdownItem[]>([]);

const selectedTags = ref<SearchMultidropdownItem>();
const selectedSuperTags = ref<SearchMultidropdownItem>();

const selectedCategory = ref<{ category?: number }>({ category: articleStore.state.categoryId ?? undefined });
const { badges, refetch, isLoading } = useBadges(selectedCategory);

const isSelectedTagsChanged = shallowRef<boolean>(false);
const isSelectedSuperTagsChanged = shallowRef<boolean>(false);
const isDisabledLabelsInput = computed<boolean>(() => !articleStore.state.categoryId);

const articleErrors = computed(() => articleStore.state.errors);

watchEffect(() => {
  selectedTags.value = articleStore.state.tags;
  selectedSuperTags.value = articleStore.state.superTags;
});

watch(
  () => articleStore.state.categoryId,
  () => {
    selectedCategory.value.category = articleStore.state.categoryId;
    refetch({ newCategory: selectedCategory.value.category, language: languageId.value });
  },
);

watch(
  () => [badges.value.length, isLoading.value],
  (newVal) => {
    console.log(newVal);
    if (newVal[0] === 0 && !newVal[1]) {
      refetch({ newCategory: null, enabled: true, language: languageId.value });
    }
  },
);

const onCategoryChange = (value: number) => {
  selectedCategory.value.category = value;
  emits('update:category', value);
};

const onTagsAndSuperTagsUpdate = (): void => {
  if (isSelectedTagsChanged.value) {
    setTags(selectedTags.value);
    emits('update:tags', selectedTags.value);
  }
  if (isSelectedSuperTagsChanged.value) {
    emits('update:super-tags', selectedSuperTags.value);
  }

  isSelectedSuperTagsChanged.value = false;
  isSelectedTagsChanged.value = false;
};

const fetchAllTags = async () => {
  const tagsPayload: ArticleTagsFetchRequest = {
    languageId: languageId.value,
    'filter[super]': false,
    'order[popular]': SortStatus.DESC,
    pagination: true,
    page: 1,
    itemsPerPage: 10,
  };

  const superTagsPayload: ArticleTagsFetchRequest = {
    languageId: languageId.value,
    'filter[super]': true,
    'order[popular]': SortStatus.DESC,
    pagination: false,
  };

  const { items: tagsItems } = await fetchTags(tagsPayload);

  // Init values for tags field
  initTags.value = tagsItems?.map((tagTranslate: Tag) => ({
    id: tagTranslate.tag.id,
    label: tagTranslate.title,
  }));

  // We need to make a pause before fetching tags again for removing conflict between this two requests
  setTimeout(async () => {
    const { items: superTagsItems } = await fetchTags(superTagsPayload);
    // Init values for superTags field

    initSuperTags.value = superTagsItems?.map((tagTranslate: Tag) => ({
      id: tagTranslate.tag.id,
      label: tagTranslate.title,
    }));
  }, 0);

  if (articleStore.state.tags && articleStore.state.tags.length) {
    setTags(articleStore.state.tags);
  }
};

const categories = ref<SelectItem[] | []>([]);

const getCategories = async () => {
  const payload = {
    languageId: languageId.value,
    'filter[relevant]': true,
  };
  const data = await fetchCategories(payload);

  categories.value = data.items.map((categoryTranslate) => ({
    id: categoryTranslate.id,
    label: categoryTranslate.title,
  }));
};

onMounted(async () => {
  await fetchAllTags();
  if (languageId.value) {
    await getCategories();
  }
});

watch(
  () => languageId.value,
  async () => {
    await fetchAllTags();
    await refetch({ newCategory: selectedCategory.value.category, language: languageId.value });
    await getCategories();
  },
);
</script>
