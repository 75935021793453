<template>
  <FormSelect
    v-show="userHasManyLanguages"
    is-required
    name="coverDeadlineId"
    :values="languages"
    :model-value="props.modelValue"
    label-key="title"
    :selected-value="props.language"
    @update:modelValue="changeLanguage"
  >
    <template #label> Language </template>
    <template #error> Fill the language </template>
    <template #dropdownItem="{ item }">
      <label
        class="w-full py-2 ms-2 text-md font-semibold text-gray-900 rounded dark:text-gray-300 flex items-center gap-2"
      >
        <CountryIcon
          :key="item.short"
          class="flex-none"
          :code="item.short"
          :name="item.title"
        />
        {{ item.title }}
      </label>
    </template>
    <template #selectedValue="{ item }">
      <label class="w-full text-md font-semibold text-gray-900 rounded dark:text-gray-300 flex items-center gap-2">
        <CountryIcon
          :key="item.short"
          :code="item.short"
          :name="item.title"
        />
        {{ item.title }}
      </label>
    </template>
  </FormSelect>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import FormSelect from '@/components/FormSelect.vue';
import type { Language } from '@/types';
import CountryIcon from '@/shared/ui/CountryIcon/CountryIcon.vue';
import { useUserStore } from '@/stores/user.store.ts';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';

const props = defineProps<{
  modelValue: number;
}>();
const emits = defineEmits<{ (event: 'update:modelValue', value: number): void }>();
const userStore = useUserStore();

const languages = computed(() => userStore.state.languages);
const currentLanguageStore = useCurrentLanguageStore();
const currentLanguage = computed(() => currentLanguageStore.currentLanguage);

const changeLanguage = (lang: number): void => {
  if (!languages.value) return;
  const selectedLanguage: Language | null = languages.value.find(({ id }) => id === lang) || null;
  if (!selectedLanguage) return;
  emits('update:modelValue', selectedLanguage.id);
};

const userHasManyLanguages = computed(() => {
  return languages.value && languages.value.length > 1;
});
</script>
