import { computed } from 'vue';
import { useRoute } from 'vue-router';

export const useLayoutAppearance = () => {
  const route = useRoute();

  const isMainLayoutVisible = computed(() => !route.path.includes('auth'));

  // Features
  const isDashboardSwitcherVisible = computed(() => route.name === 'dashboard');
  const isDashboardPage = computed(() => route.name === 'dashboard');
  const isArticlesPage = computed(() => route.path === '/articles');
  const isArticlePage = computed(() => route.name === 'article');

  return {
    isMainLayoutVisible,

    isDashboardSwitcherVisible,
    isDashboardPage,
    isArticlesPage,
    isArticlePage,
  };
};
