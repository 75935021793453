import { computed } from 'vue';
import { type SelectItem } from '@/components/FormSelect.vue';
import { useFetchAuthors } from '@/features/ArticleLayout/queries';
import { type ArticleAuthorsFetchRequest } from '@/features/ArticleLayout/types';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';

export const useAuthors = () => {
  const currentLanguageStore = useCurrentLanguageStore();
  const languageId = computed(() => currentLanguageStore.currentLanguage.id);

  const fetchAuthorsParams = computed<ArticleAuthorsFetchRequest>(() => ({
    languageId: languageId.value,
  }));

  const { data: authorTranslates } = useFetchAuthors(fetchAuthorsParams);

  const authors = computed<SelectItem[]>(() => {
    if (!authorTranslates.value?.items?.length) {
      return [];
    }

    return authorTranslates.value.items.map((authorTranslate) => ({
      id: authorTranslate.author.id,
      label: authorTranslate.name,
    }));
  });

  return {
    authors,
  };
};
