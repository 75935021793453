export const getUniqueTabId = () => {
  const idFromStorage = sessionStorage.getItem('TAB_ID_KEY');
  if (idFromStorage) {
    const usedIds = JSON.parse(localStorage.getItem('USED_IDS')) || [];
    if (usedIds.includes(idFromStorage)) {
      const id = crypto.randomUUID();
      sessionStorage.setItem('TAB_ID_KEY', id);
      usedIds.push(id);
      localStorage.setItem('USED_IDS', JSON.stringify(usedIds));
      return id;
    }
    return idFromStorage;
  } else {
    const id = crypto.randomUUID();
    sessionStorage.setItem('TAB_ID_KEY', id);
    const usedIds = JSON.parse(localStorage.getItem('USED_IDS')) || [];
    usedIds.push(id);
    localStorage.setItem('USED_IDS', JSON.stringify(usedIds));
    return id;
  }
};

export const clearUniqueTabIdFromStore = () => {
  sessionStorage.removeItem('TAB_ID_KEY');
  localStorage.removeItem('USED_IDS');
};
