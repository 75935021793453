import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "16",
  height: "16",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#303D43",
      d: "m3.52 1.549.22-1.33.005-.027A.244.244 0 0 1 3.972 0h.056a.244.244 0 0 1 .23.22l.222 1.329c.024.139.035.208.052.27.127.476.506.844.986.955.063.015.132.024.272.042l1.3.174.057.009c.1.024.174.109.185.21l.001.06v.13l-.001.058a.24.24 0 0 1-.185.21l-.058.01-1.299.173c-.14.019-.21.028-.272.043-.48.111-.86.478-.986.955-.017.061-.028.13-.052.27l-.221 1.33-.005.027A.244.244 0 0 1 4 6.667h-.028a.244.244 0 0 1-.23-.22L3.52 5.118a3 3 0 0 0-.051-.27 1.33 1.33 0 0 0-.987-.955c-.062-.015-.132-.024-.272-.043l-1.3-.173a.4.4 0 0 1-.057-.01.24.24 0 0 1-.185-.21l-.001-.059v-.13l.001-.058a.24.24 0 0 1 .185-.211l.058-.01 1.299-.173c.14-.018.21-.027.272-.042.48-.111.86-.479.987-.955a3 3 0 0 0 .05-.27M8.504 4a.21.21 0 0 1-.205-.176 3.6 3.6 0 0 0-.228-.785c-.07-.156.04-.372.211-.372h3.445c.875 0 1.313 0 1.661.134a2 2 0 0 1 1.144 1.144c.135.348.135.786.135 1.662v2.77a.137.137 0 0 1-.158.133l-.027-.004c-.218-.033-.445-.068-.634-.173a1 1 0 0 1-.462-.553c-.053-.156-.053-.342-.053-.713v-1.46c0-.455 0-.734-.014-.944-.012-.198-.033-.243-.03-.237a.67.67 0 0 0-.382-.381 1 1 0 0 0-.237-.03C12.46 4 12.182 4 11.727 4z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#303D43",
      d: "M8.98 7.805c.206-.385.285-.86.443-1.808l.163-.98a1 1 0 0 1 .027-.13.33.33 0 0 1 .254-.215c.03-.005.064-.005.133-.005.07 0 .104 0 .134.005a.33.33 0 0 1 .253.215c.01.028.016.062.027.13l.163.98c.159.949.238 1.423.443 1.808.263.493.674.892 1.176 1.138.392.193.868.257 1.821.384l.88.117c.139.019.208.028.263.057.07.038.123.1.151.174.022.057.022.127.022.268v.114c0 .14 0 .21-.022.268a.33.33 0 0 1-.151.174c-.055.03-.124.038-.263.057l-.88.117c-.953.127-1.43.19-1.821.384a2.67 2.67 0 0 0-1.176 1.138c-.205.385-.284.86-.443 1.808l-.163.98a1 1 0 0 1-.027.13.33.33 0 0 1-.253.215c-.03.005-.065.005-.134.005s-.104 0-.133-.005a.33.33 0 0 1-.254-.214 1 1 0 0 1-.027-.131l-.163-.98c-.158-.949-.237-1.423-.443-1.808a2.67 2.67 0 0 0-1.176-1.138c-.392-.193-.868-.257-1.82-.384l-.88-.117c-.14-.018-.21-.028-.264-.057a.33.33 0 0 1-.151-.174c-.022-.057-.022-.127-.022-.268v-.114c0-.14 0-.21.022-.268A.33.33 0 0 1 4.84 9.5c.055-.03.124-.038.264-.057l.879-.117c.953-.127 1.43-.19 1.821-.384A2.67 2.67 0 0 0 8.98 7.805"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#303D43",
      d: "M1.333 4.867v6.466a2 2 0 0 0 2 2H8c.11 0 .198-.09.176-.197C8.102 12.764 7.796 12 6.533 12h-3.2a.667.667 0 0 1-.666-.667V5.221c0-.141-.059-.277-.185-.34-.17-.085-.47-.187-.948-.21a.193.193 0 0 0-.2.196m12.528 6.883.083-.024c1.009-.293.775.57.405 1.007-.507.6-1.19.6-2.484.6-.133 0-.229-.103-.191-.208.295-.825 1.271-1.108 2.187-1.374"
    }, null, -1)
  ])))
}
export default { render: render }