import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M5.999 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M1.467 3.945c-.135.348-.135.786-.135 1.662v4.786c0 .876 0 1.314.135 1.662a2 2 0 0 0 1.143 1.144c.349.134.786.134 1.662.134h3.393A.334.334 0 0 0 7.999 13v-.667a.333.333 0 0 0-.334-.332q-.555.001-1.11.005c-.963.006-1.927.012-2.89-.006h-.057l2.39-2.39.863.862c.26.26.682.26.942 0l3.529-3.53 2.764 2.765c.21.21.57.061.57-.236V5.607c0-.876 0-1.314-.135-1.662a2 2 0 0 0-1.144-1.144c-.348-.134-.786-.134-1.662-.134H4.272c-.876 0-1.313 0-1.662.134a2 2 0 0 0-1.143 1.144m4.06 4.25-2.862 2.862V7.333h.003l-.003-1.726c0-.455.001-.734.014-.943.013-.2.034-.244.031-.238a.67.67 0 0 1 .381-.381c-.005.002.039-.018.238-.03C3.539 4 3.817 4 4.272 4h7.453c.455 0 .734 0 .944.014.177.012.231.029.237.031a.67.67 0 0 1 .381.381c-.002-.006.018.039.03.238.011.164.014.37.015.67v1.723L11.803 5.53a.667.667 0 0 0-.942 0L7.332 9.057l-.862-.862a.667.667 0 0 0-.943 0",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M12.332 9.333c.184 0 .333.15.333.334v1.666h1.667c.184 0 .333.15.333.334v.666a.334.334 0 0 1-.333.334h-1.667v1.666a.334.334 0 0 1-.333.334h-.667a.333.333 0 0 1-.333-.334v-1.666H9.665a.333.333 0 0 1-.333-.334v-.666c0-.184.15-.334.333-.334h1.667V9.667c0-.184.15-.334.333-.334z"
    }, null, -1)
  ])))
}
export default { render: render }