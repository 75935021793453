import { defineStore } from 'pinia';
import type { Language } from '@/types';
import { ref } from 'vue';

const STORE_NAME = 'articleCurrentLanguageStore';
interface LanguageStoreState {
  currentLanguage: {
    id: number;
    code: string;
    short: string;
    title: string;
    name: string;
  };
}

export const useArticleCurrentLanguageStore = defineStore(
  STORE_NAME,
  () => {
    const state = ref<LanguageStoreState['currentLanguage']>({
      id: 1,
      code: 'en',
      short: 'en',
      title: 'English',
      name: 'English',
    });
    const initLanguage = (language: Language): void => {
      state.value = {
        id: language.id,
        code: language.code,
        short: language.short,
        title: language.title,
        name: language.name,
      };
    };

    const updateLanguage = (language: Language): void => {
      state.value = {
        id: language.id,
        code: language.code,
        short: language.short,
        title: language.title,
        name: language.name,
      };
    };

    const clearCurrentLanguage = () => {
      state.value = null;
    };

    return {
      state,
      initLanguage,
      updateLanguage,
      clearCurrentLanguage,
    };
  },
  {
    persist: true,
  },
);
