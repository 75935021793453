import { useQuery } from '@tanstack/vue-query';
import type { ComputedRef } from 'vue';
import { fetchCoverMatches } from '@/features/SearchImageAI/api';
import type { CoverImageAIResponse, CoverImageAIParams } from '@/features/SearchImageAI/types';

type OnSuccessCallback = (result: CoverImageAIResponse) => void;

interface UseSearchCoverAIOptions {
  enabled?: boolean;
  onSuccess?: OnSuccessCallback;
}

export const useSearchCoverAI = (params: ComputedRef<CoverImageAIParams>, options: UseSearchCoverAIOptions = {}) => {
  const { enabled = true, onSuccess } = options;

  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: ['ai-cover-suggestions', params],

    queryFn: async ({ queryKey }) => {
      const payload: CoverImageAIParams = queryKey[1] as CoverImageAIParams;

      if (!payload.description) {
        throw new Error('Description required.');
      }

      const result = await fetchCoverMatches(payload);

      if (onSuccess) {
        onSuccess({ data: result });
      }

      return { data: result };
    },

    gcTime: 0,
    staleTime: 0,
    refetchInterval: false,
    refetchOnMount: 'always',
    enabled,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
  };
};
