import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "16",
  height: "16",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#303D43",
      d: "M7 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#303D43",
      "fill-rule": "evenodd",
      d: "M3.613 11.997c1.076.02 2.154.008 3.23.004.146 0 .218 0 .276.022a.33.33 0 0 1 .192.19c.022.058.022.131.022.277v.353c0 .146 0 .22-.022.277a.33.33 0 0 1-.19.19c-.059.023-.132.023-.278.023h-2.57c-.875 0-1.313 0-1.661-.134a2 2 0 0 1-1.144-1.144c-.135-.348-.135-.786-.135-1.662V5.607c0-.876 0-1.314.135-1.662a2 2 0 0 1 1.144-1.144c.348-.134.786-.134 1.661-.134h7.454c.875 0 1.313 0 1.661.134a2 2 0 0 1 1.144 1.144c.151.39.15.831.141 1.259a1.6 1.6 0 0 1-.016.258.65.65 0 0 1-.105.247c-.034.05-.08.095-.172.188l-2.687 2.686c-.206.207-.31.31-.423.36a.67.67 0 0 1-.54 0c-.113-.05-.217-.153-.423-.36L10 8.276 8.026 10.25c-.206.206-.31.31-.423.36a.67.67 0 0 1-.54 0c-.113-.05-.216-.154-.423-.36L6 9.61zM4.273 4h7.454c.455 0 .733 0 .943.014.177.012.232.029.237.03a.67.67 0 0 1 .381.382c-.002-.006.018.039.031.237.007.109.01.236.012.396L11 7.391l-.307-.307c-.206-.207-.31-.31-.423-.36a.67.67 0 0 0-.54 0c-.113.05-.217.153-.423.36L7.333 9.057l-.64-.64c-.206-.207-.31-.31-.423-.36a.67.67 0 0 0-.54 0c-.113.05-.217.153-.423.36L2.67 11.054c-.003-.171-.003-.383-.003-.66V5.606c0-.455 0-.734.014-.944.013-.198.033-.243.03-.237a.67.67 0 0 1 .382-.381c-.006.002.038-.018.237-.03C3.54 4 3.818 4 4.273 4m9.07 4.096a.327.327 0 0 1 .463 0l2.098 2.098a.327.327 0 0 1 0 .463l-4.484 4.485a.66.66 0 0 1-.463.191h-2.29v-2.29c0-.173.069-.34.191-.463zm-3.272 5.122a.33.33 0 0 0-.096.232v.575h.575c.087 0 .17-.035.232-.096l1.703-1.704-.71-.71zm2.63-2.629.71.71.874-.873-.71-.71z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }