<template>
  <div class="flex items-center gap-2 pr-2">
    <template v-if="isNew">
      <Tooltip
        :key="`tooltip-start-button`"
        width="unset"
        name="start-button-info"
        placement="auto"
        :tooltip-visible="!documentStatusToWorkEdit && !documentStatusToWorkCondition"
        button-class="w-full text-left"
      >
        <template #content>
          <Button
            v-if="documentStatusToWorkView"
            :is-disabled="
              (!documentStatusToWorkEdit && !documentStatusToWorkCondition) || !userStore.hasAccessToCurrentLanguage()
            "
            :is-loading="isLoadingStatus === DocumentStatus.IN_PROGRESS"
            @click="emits('update:modelValue', DocumentStatus.IN_PROGRESS)"
          >
            Start work
          </Button>
        </template>
        <template #tooltip>
          <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
        </template>
      </Tooltip>
    </template>

    <template v-if="isInProgress">
      <Button
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :color="BUTTON_COLOR.PRIMARY"
        :is-loading="isLoadingStatus === DocumentStatus.NEW"
        :is-disabled="!userStore.hasAccessToCurrentLanguage()"
        @click="emits('update:modelValue', DocumentStatus.NEW)"
      >
        Stop work
      </Button>

      <Tooltip
        :key="`tooltip-finish-text-button-progress`"
        width="unset"
        name="finish-text-button-progress-info"
        placement="auto"
        :tooltip-visible="!documentStatusToFinishEdit && !finishTextCondition"
        button-class="w-full text-left"
      >
        <template #content>
          <Button
            v-if="documentStatusToFinishView"
            :is-disabled="
              (!documentStatusToFinishEdit && !finishTextCondition) || !userStore.hasAccessToCurrentLanguage()
            "
            :is-loading="isLoadingStatus === DocumentStatus.READY_FOR_ART_EDITOR"
            @click="emits('update:modelValue', DocumentStatus.READY_FOR_ART_EDITOR)"
          >
            Finish text
          </Button>
        </template>
        <template #tooltip>
          <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
        </template>
      </Tooltip>
    </template>

    <template v-if="isReadyForArtwork">
      <Button
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :color="BUTTON_COLOR.PRIMARY"
        :is-loading="isLoadingStatus === DocumentStatus.IN_PROGRESS"
        :is-disabled="!userStore.hasAccessToCurrentLanguage()"
        @click="emits('update:modelValue', DocumentStatus.IN_PROGRESS)"
      >
        Back to text
      </Button>

      <Tooltip
        :key="`tooltip-finish-art-button-progress`"
        width="unset"
        name="finish-art-button-progress-info"
        placement="auto"
        :tooltip-visible="!documentStatusToFinishArtworkEdit && !artworkCondition"
        button-class="w-full text-left"
      >
        <template #content>
          <Button
            :is-disabled="
              (!documentStatusToFinishArtworkEdit && !artworkCondition) || !userStore.hasAccessToCurrentLanguage()
            "
            :is-loading="isLoadingStatus === DocumentStatus.READY_TO_REVIEW"
            @click="emits('update:modelValue', DocumentStatus.READY_TO_REVIEW)"
          >
            Finish artwork
          </Button>
        </template>
        <template #tooltip>
          <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
        </template>
      </Tooltip>
    </template>
    <template v-if="isCopyEditorAndNeedsProofreading && isApproveButtonVisible">
      <Button
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :color="BUTTON_COLOR.PRIMARY"
        :is-disabled="!userStore.hasAccessToCurrentLanguage()"
        @click="onApproveByCopyEditor"
      >
        Approve
      </Button>
    </template>

    <template v-if="isReadyToReview">
      <Tooltip
        :key="`tooltip-reject-text`"
        width="unset"
        name="reject-text-button-info"
        placement="auto"
        :tooltip-visible="!documentStatusToRejectTextEdit && !documentStatusToRejectTextCondition"
        button-class="w-full text-left"
      >
        <template #content>
          <Button
            v-if="documentStatusToRejectTextView"
            :is-loading="isLoadingStatus === DocumentStatus.TEXT_REJECTED"
            :is-disabled="
              (!documentStatusToRejectTextEdit && !documentStatusToRejectTextCondition) ||
              !userStore.hasAccessToCurrentLanguage()
            "
            :visual-type="BUTTON_TYPE.PRIMARY"
            :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
            :color="BUTTON_COLOR.PRIMARY"
            @click="emits('update:modelValue', DocumentStatus.TEXT_REJECTED)"
          >
            Reject text
          </Button>
        </template>
        <template #tooltip>
          <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
        </template>
      </Tooltip>

      <Tooltip
        :key="`tooltip-reject-art`"
        width="unset"
        name="reject-art-button-info"
        placement="auto"
        :tooltip-visible="!documentStatusToFinishArtworkEdit && !artworkCondition"
        button-class="w-full text-left"
      >
        <template #content>
          <Button
            v-if="documentStatusToFinishArtworkView"
            :is-loading="isLoadingStatus === DocumentStatus.IMAGE_REJECTED"
            :is-disabled="
              (!documentStatusToFinishArtworkEdit && !artworkCondition) || !userStore.hasAccessToCurrentLanguage()
            "
            :visual-type="BUTTON_TYPE.PRIMARY"
            :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
            :color="BUTTON_COLOR.PRIMARY"
            @click="emits('update:modelValue', DocumentStatus.IMAGE_REJECTED)"
          >
            Reject artwork
          </Button>
        </template>
        <template #tooltip>
          <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
        </template>
      </Tooltip>

      <Tooltip
        :key="`tooltip-finish-review`"
        width="unset"
        name="finish-review-button-info"
        placement="auto"
        :tooltip-visible="!documentStatusToFinishReviewEdit && !documentStatusToFinishReviewCondition"
        button-class="w-full text-left"
      >
        <template #content>
          <Button
            v-if="documentStatusToFinishReviewView"
            :is-loading="isLoadingStatus === DocumentStatus.READY_TO_PUBLISH"
            :is-disabled="
              (!documentStatusToFinishReviewEdit && !documentStatusToFinishReviewCondition) ||
              !userStore.hasAccessToCurrentLanguage()
            "
            @click="emits('update:modelValue', DocumentStatus.READY_TO_PUBLISH)"
          >
            Finish review
          </Button>
        </template>
        <template #tooltip>
          <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
        </template>
      </Tooltip>
    </template>

    <template v-if="isTextRejected && documentStatusToFinishView">
      <Tooltip
        :key="`tooltip-finish-text-button`"
        width="unset"
        name="finish-text-button-info"
        placement="auto"
        :tooltip-visible="!documentStatusToFinishEdit && !finishTextCondition"
        button-class="w-full text-left"
      >
        <template #content>
          <Button
            :is-loading="isLoadingStatus === DocumentStatus.READY_TO_REVIEW"
            :is-disabled="
              (!documentStatusToFinishEdit && !finishTextCondition) || !userStore.hasAccessToCurrentLanguage()
            "
            @click="emits('update:modelValue', DocumentStatus.READY_TO_REVIEW)"
          >
            Finish text
          </Button>
        </template>
        <template #tooltip>
          <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
        </template>
      </Tooltip>
    </template>

    <template v-if="isArtworkRejected && documentStatusToFinishArtworkEdit">
      <Tooltip
        :key="`tooltip-finish-art-button`"
        width="unset"
        name="finish-art-button-info"
        placement="auto"
        :tooltip-visible="!documentStatusToFinishArtworkEdit && !artworkCondition"
        button-class="w-full text-left"
      >
        <template #content>
          <Button
            :is-loading="isLoadingStatus === DocumentStatus.READY_TO_REVIEW"
            :is-disabled="
              (!documentStatusToFinishArtworkEdit && !artworkCondition) || !userStore.hasAccessToCurrentLanguage()
            "
            @click="emits('update:modelValue', DocumentStatus.READY_TO_REVIEW)"
          >
            Finish artwork
          </Button>
        </template>
        <template #tooltip>
          <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
        </template>
      </Tooltip>
    </template>

    <template v-if="isReadyToPublish">
      <Tooltip
        :key="`tooltip-publish-1-button`"
        width="unset"
        name="publish-1-button-info"
        placement="auto"
        :tooltip-visible="!documentStatusToPublishEdit && !publishCondition"
        button-class="w-full text-left"
      >
        <template #content>
          <Button
            v-if="documentStatusToPublishView"
            :is-loading="isLoadingStatus === DocumentStatus.PUBLISHED"
            :is-disabled="
              (!documentStatusToPublishEdit && !publishCondition) || !userStore.hasAccessToCurrentLanguage()
            "
            @click="revealPublish"
          >
            Publish
          </Button>
        </template>
        <template #tooltip>
          <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
        </template>
      </Tooltip>
    </template>

    <template v-if="isPublished">
      <Button
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :color="BUTTON_COLOR.PRIMARY"
        :is-loading="isLoadingStatus === DocumentStatus.UNPUBLISHED"
        :is-disabled="!userStore.hasAccessToCurrentLanguage()"
        @click="revealUnpublish"
      >
        Unpublish
      </Button>

      <Tooltip
        :key="`tooltip-update-button`"
        width="unset"
        name="update-button-info"
        placement="auto"
        :tooltip-visible="!documentStatusToUpdateEdit && !documentStatusToUpdateCondition"
        button-class="w-full text-left"
      >
        <template #content>
          <ButtonSplitDropdown
            v-if="documentStatusToUpdateView"
            :is-dropdown-shown="documentMetadataOnlyCoverUpdateView"
            :is-loading="isLoadingStatus === DocumentStatus.PUBLISHED"
            :is-dropdown-opened="isUpdateCoverDropdownOpened"
            :is-disabled="
              (!documentStatusToUpdateEdit && !documentStatusToUpdateCondition) ||
              !userStore.hasAccessToCurrentLanguage()
            "
            @click="revealUpdate"
            @click-dropdown="isUpdateCoverDropdownOpened = true"
          >
            Update article
            <template #menu>
              <Button
                is-full-width
                :visual-type="BUTTON_TYPE.GHOST"
                @click="
                  emits('updateOnlyCover');
                  isUpdateCoverDropdownOpened = false;
                "
              >
                Update cover image
              </Button>
            </template>
          </ButtonSplitDropdown>
        </template>
        <template #tooltip>
          <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
        </template>
      </Tooltip>

      <Button
        v-if="documentMetadataOnlyCoverUpdateView && !documentStatusToUpdateView"
        @click="emits('updateOnlyCover')"
      >
        Update cover image
      </Button>

      <Tooltip
        :key="`tooltip-publish-button`"
        width="unset"
        name="publish-button-info"
        placement="auto"
        :tooltip-visible="!documentStatusToPublishEdit && !publishCondition"
        button-class="w-full text-left"
      >
        <template #content>
          <Button
            v-if="documentStatusToPublishView && isArticleScheduled"
            :is-loading="isLoadingStatus === DocumentStatus.PUBLISHED"
            :is-disabled="
              (!documentStatusToPublishEdit && !publishCondition) || !userStore.hasAccessToCurrentLanguage()
            "
            @click="revealPublish"
          >
            Publish now
          </Button>
        </template>
        <template #tooltip>
          <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
        </template>
      </Tooltip>
    </template>

    <template v-if="isUnpublished">
      <Tooltip
        :key="`tooltip-back-to-work`"
        width="unset"
        name="back-button-info"
        placement="auto"
        :tooltip-visible="!documentStatusToBackToWorkEdit && !documentStatusToBackToWorkCondition"
        button-class="w-full text-left"
      >
        <template #content>
          <Button
            v-if="documentStatusToBackToWorkView"
            :is-loading="isLoadingStatus === DocumentStatus.IN_PROGRESS"
            :is-disabled="
              (!documentStatusToBackToWorkEdit && !documentStatusToBackToWorkCondition) ||
              !userStore.hasAccessToCurrentLanguage()
            "
            :visual-type="BUTTON_TYPE.PRIMARY"
            :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
            :color="BUTTON_COLOR.PRIMARY"
            @click="emits('update:modelValue', DocumentStatus.IN_PROGRESS)"
          >
            Back to work
          </Button>
        </template>
        <template #tooltip>
          <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
        </template>
      </Tooltip>

      <Tooltip
        :key="`tooltip-publish`"
        width="unset"
        name="publish-button-info-unpublished"
        placement="auto"
        :tooltip-visible="!documentStatusToPublishEdit && !publishCondition"
        button-class="w-full text-left"
      >
        <template #content>
          <Button
            v-if="documentStatusToPublishView"
            :is-loading="isLoadingStatus === DocumentStatus.PUBLISHED"
            :is-disabled="
              (!documentStatusToPublishEdit && !publishCondition) || !userStore.hasAccessToCurrentLanguage()
            "
            @click="revealPublish"
          >
            Publish
          </Button>
        </template>
        <template #tooltip>
          <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
        </template>
      </Tooltip>
    </template>

    <Teleport to="#modals">
      <component
        :is="isArticleEmbargoed && !isArticleScheduled ? PublishEmbargoedModal : PublishModal"
        v-if="isPublishRevealed"
        :is-visible="isPublishRevealed"
        :published-at="props.publishedAt"
        :is-publish-now="isPublishNow"
        @close="cancelPublish"
        @schedule="onConfirmSchedule"
        @publish="onConfirmPublish"
      />

      <UnpublishModal
        :is-visible="isUnpublishRevealed"
        :is-scheduled="isArticleScheduled"
        @close="cancelUnpublish"
        @confirm="onConfirmUnpublish"
      />

      <UpdateModal
        :is-visible="isUpdateRevealed"
        :is-published="isPublished && !isArticleScheduled"
        :can-be-republished="props.canBeRepublished"
        @close="cancelUpdate"
        @update="onConfirmUpdate"
      />
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import Button from '@/components/Button.vue';
import ButtonSplitDropdown from '@/components/ButtonSplitDropdown.vue';
import { useModal } from '@/composables/useModal';

import UnpublishModal from './modals/UnpublishModal.vue';
import PublishModal from './modals/PublishModal.vue';
import PublishEmbargoedModal from './modals/PublishEmbargoedModal.vue';
import UpdateModal from './modals/UpdateModal.vue';

import { useCurrentDocument } from '../composables';

import { BUTTON_COLOR, BUTTON_ON_SURFACE, BUTTON_TYPE, DocumentStatus, ROLES } from '@/types';
import { useUserPermissions, useUserStore } from '@/stores/user.store';
import { useUserConditions } from '@/composables/useUserConditions';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store';
import Tooltip from '@/components/Tooltip.vue';

const props = defineProps<{
  modelValue: DocumentStatus;
  publishedAt: string;
  hasPost: boolean;
  canBeRepublished: boolean;
  languageId: number;
  isLoadingStatus: DocumentStatus | null;
}>();

const emits = defineEmits<{
  (event: 'update:modelValue', status: DocumentStatus): void;
  (event: 'publish', articleUrl: string): void;
  (event: 'schedule', articleUrl: string, embargoedUntil: string | null, publishedAt: string | null): void;
  (event: 'update', isUpdatePublicationDate: boolean): void;
  (event: 'approve', field: string, value: boolean): void;
  (event: 'updateOnlyCover'): void;
}>();

const userStore = useUserStore();

const { data: articleData } = useCurrentDocument();
const articleStore = useArticleStore();

const user = computed(() => userStore.state);

const isUpdateCoverDropdownOpened = ref(false);

const isCopyEditorAndNeedsProofreading = computed(
  () => user.value?.roles?.includes(ROLES.ROLE_COPY_EDITOR) && articleStore.state.needsProofreading,
);

const {
  documentStatusToFinishView,
  documentStatusToFinishEdit,
  documentStatusToFinishArtworkView,
  documentStatusToFinishArtworkEdit,
  documentStatusToBackToWorkView,
  documentStatusToBackToWorkEdit,
  documentStatusToRejectTextView,
  documentStatusToRejectTextEdit,
  documentStatusToFinishReviewView,
  documentStatusToFinishReviewEdit,
  documentStatusToPublishView,
  documentStatusToPublishEdit,
  documentStatusToUpdateView,
  documentStatusToUpdateEdit,
  documentStatusToWorkView,
  documentStatusToWorkEdit,
  documentMetadataOnlyCoverUpdateView,
} = useUserPermissions();

const {
  finishTextCondition,
  artworkCondition,
  publishCondition,
  documentStatusToFinishReviewCondition,
  documentStatusToWorkCondition,
  documentStatusToBackToWorkCondition,
  documentStatusToRejectTextCondition,
  documentStatusToUpdateCondition,
} = useUserConditions();

const {
  reveal: revealUnpublish,
  cancel: cancelUnpublish,
  confirm: confirmUnpublish,
  isRevealed: isUnpublishRevealed,
} = useModal();

const {
  reveal: revealPublish,
  cancel: cancelPublish,
  confirm: confirmPublish,
  isRevealed: isPublishRevealed,
} = useModal();

const { reveal: revealUpdate, cancel: cancelUpdate, confirm: confirmUpdate, isRevealed: isUpdateRevealed } = useModal();

const isArticleScheduled = computed(() => {
  const currentDate = new Date();
  const publishDate = props.publishedAt ? new Date(props.publishedAt) : currentDate;

  return publishDate.getTime() > currentDate.getTime();
});

const isApproveButtonVisible = computed(
  () =>
    props.modelValue === DocumentStatus.PUBLISHED ||
    props.modelValue === DocumentStatus.READY_TO_REVIEW ||
    props.modelValue === DocumentStatus.READY_FOR_ART_EDITOR ||
    props.modelValue === DocumentStatus.READY_TO_PUBLISH ||
    isArticleScheduled.value,
);

const isArticleEmbargoed = computed(() => !!articleData.value?.embargoUntil);

const isNew = computed(() => props.modelValue === DocumentStatus.NEW);
const isInProgress = computed(() => props.modelValue === DocumentStatus.IN_PROGRESS);
const isReadyForArtwork = computed(() => props.modelValue === DocumentStatus.READY_FOR_ART_EDITOR);
const isReadyToPublish = computed(() => props.modelValue === DocumentStatus.READY_TO_PUBLISH);
const isReadyToReview = computed(() => props.modelValue === DocumentStatus.READY_TO_REVIEW);
const isPublished = computed(() => props.modelValue === DocumentStatus.PUBLISHED);
const isUnpublished = computed(() => props.modelValue === DocumentStatus.UNPUBLISHED);
const isTextRejected = computed(() => props.modelValue === DocumentStatus.TEXT_REJECTED);
const isArtworkRejected = computed(() => props.modelValue === DocumentStatus.IMAGE_REJECTED);

const isPublishNow = computed(() => {
  return isPublished.value && isArticleScheduled.value;
});

const onConfirmSchedule = (articleUrl: string, embargoedUntil: string | null, publishedAt: string | null) => {
  confirmPublish();
  emits('schedule', articleUrl, embargoedUntil, publishedAt);
};

const onConfirmPublish = (articleUrl: string) => {
  confirmPublish();
  emits('publish', articleUrl);
};

const onConfirmUnpublish = () => {
  confirmUnpublish();
  emits('update:modelValue', DocumentStatus.UNPUBLISHED);
};

const onConfirmUpdate = (isUpdatePublicationDate: boolean) => {
  confirmUpdate();
  emits('update', isUpdatePublicationDate);
};

const onApproveByCopyEditor = (): void => {
  emits('approve', 'needsProofreading', false);
};
</script>
