import { SortStatus } from '@/types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useLabelsPagination } from '@/features/Labels/stores/pagination.store.ts';

export interface FiltersState {
  searchQuery: string;
  sort: {
    id?: SortStatus;
    title?: SortStatus;
    category?: SortStatus;
  };
}
export type State = FiltersState;

export const useLabelsFilters = defineStore('labelsFilters', () => {
  const state = ref<Partial<State>>({
    sort: {
      id: SortStatus.DESC,
    },
    searchQuery: '',
  });
  const paginationStore = useLabelsPagination();

  const setSorts = (sort: Record<string, SortStatus>) => {
    state.value = { ...state.value, sort: { ...sort } } as FiltersState;
  };

  const resetSort = () => {
    state.value = {
      ...state.value,
      sort: {
        createdAt: SortStatus.DESC,
      },
    };
  };

  const setSearchQuery = (searchQuery: string) => {
    state.value = { ...state.value, searchQuery };
    paginationStore.setState({ page: 1 });
  };

  return {
    state,
    setSorts,
    setSearchQuery,
    resetSort,
  };
});
