import { ref, computed, type Ref } from 'vue';
import { type SelectItem } from '@/components/FormSelect.vue';
import type { ArticleBadgesFetchRequest } from '../types';
import { useFetchBadges } from '@/features/ArticleLayout/queries';
import { useArticleCurrentLanguageStore } from '@/stores/article-current-language.ts';

export const useBadges = (filter?: { category?: number }, language?: number) => {
  const currentLanguageStore = useArticleCurrentLanguageStore();
  const languageId = computed(() => currentLanguageStore.state.id);

  const badgesPayload = ref<ArticleBadgesFetchRequest>({
    languageId: language ?? languageId.value,
    ['filter[enabled]']: true,
  });

  if (filter?.category) {
    badgesPayload.value.category = filter.category;
  }

  const { data: badgesTranslates, refetch: queryReFetch, isLoading } = useFetchBadges(badgesPayload);

  const badges = computed<SelectItem[]>(() => {
    if (!badgesTranslates.value?.items?.length) {
      return [];
    }

    return badgesTranslates.value.items.map((badgeTranslate) => ({
      id: badgeTranslate.id,
      label: badgeTranslate.title,
    }));
  });

  const refetch = (payload: { newCategory?: number | null; enabled?: boolean; language: number }) => {
    const { newCategory, enabled, language } = payload;
    if (newCategory !== undefined) {
      badgesPayload.value.category = newCategory;
      badgesPayload.value['filter[enabled]'] = enabled;
      if (language) {
        badgesPayload.value.languageId = language;
      }
      queryReFetch();
    }
  };

  return {
    badges,
    refetch,
    isLoading,
  };
};
