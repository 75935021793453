<template>
  <section>
    <h3 class="font-semibold text-base text-imperium-fg-base mb-2">Social networks</h3>

    <Tooltip
      :key="`tooltip-x-lead`"
      width="unset"
      name="x-lead-info"
      placement="auto"
      :tooltip-visible="!documentMetadataXLeadTextEdit && !documentMetadataXLeadTextCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <FormTextarea
          v-if="documentMetadataXLeadTextView"
          :model-value="articleStore.state.twitterPost"
          name="twitterPost"
          class="mb-1"
          placeholder="Write a lead text that will be displayed on X (Twitter)"
          :attrs="props.twitterPostAttrs"
          :size="SIZES.SMALL"
          is-required
          :max="MAX_TWEET_LENGTH"
          :is-errored="isTwitterPostErrored"
          :is-disabled="
            (!documentMetadataXLeadTextEdit && !documentMetadataXLeadTextCondition) ||
            !userStore.hasAccessToCurrentLanguage()
          "
          @update:model-value="(value) => emits('update:twitterPost', value)"
        >
          <template #label>
            <span class="text-xs">X post (Twitter)</span>
          </template>

          <template #error> {{ errors.twitterPost }} </template>
        </FormTextarea>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-exclude-from-xrss`"
      width="unset"
      name="xrss-info"
      placement="auto"
      :tooltip-visible="!documentMetadataExcludeFromXRSSEdit && !documentMetadataExcludeFromXRSSCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <FormCheckbox
          v-if="documentMetadataExcludeFromXRSSView"
          :model-value="articleStore.state.twitterExcludeRss"
          class="mb-2"
          name="exclude-twitter"
          :attrs="props.twitterExcludeRssAttrs"
          :size="SIZES.SMALL"
          :is-disabled="
            (!documentMetadataExcludeFromXRSSEdit && !documentMetadataExcludeFromXRSSCondition) ||
            !userStore.hasAccessToCurrentLanguage()
          "
          @update:model-value="(value) => emits('update:twitterExcludeRss', value)"
        >
          <span class="text-sm">Exclude from X (Twitter) RSS</span>
        </FormCheckbox>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-exclude-from-tg`"
      width="unset"
      name="tg-info"
      placement="auto"
      :tooltip-visible="!documentMetadataExcludeFromTgChannelsEdit && !documentMetadataExcludeFromTgChannelsCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <FormCheckbox
          v-if="documentMetadataExcludeFromTgChannelsView"
          :model-value="articleStore.state.isExcludedFromTelegram"
          name="exclude-channels"
          :attrs="props.twitterExcludeChannelsAttrs"
          :size="SIZES.SMALL"
          :is-disabled="
            (!documentMetadataExcludeFromTgChannelsEdit && !documentMetadataExcludeFromTgChannelsCondition) ||
            !userStore.hasAccessToCurrentLanguage()
          "
          @update:model-value="(value) => emits('update:twitterExcludeChannels', value)"
        >
          <span class="text-sm">Exclude from Telegram channels</span>
        </FormCheckbox>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>
  </section>
</template>

<script lang="ts" setup>
import { SIZES } from '@/types';
import FormCheckbox from '@/components/FormCheckbox.vue';
import FormTextarea from '@/components/FormTextarea.vue';

import { MAX_TWEET_LENGTH } from '../constants';
import { useUserPermissions, useUserStore } from '@/stores/user.store';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store';
import { computed } from 'vue';
import { useUserConditions } from '@/composables/useUserConditions.ts';
import Tooltip from '@/components/Tooltip.vue';

const props = defineProps<{
  twitterPostAttrs: Record<string, unknown>;
  twitterExcludeRssAttrs: Record<string, unknown>;
  twitterExcludeChannelsAttrs: Record<string, unknown>;
  isTwitterPostErrored: boolean;
  errors: Record<string, unknown>;
}>();

const emits = defineEmits<{
  (event: 'update:twitterPost', value: string): void;
  (event: 'update:twitterExcludeRss', value: boolean): void;
  (event: 'update:twitterExcludeChannels', value: boolean): void;
}>();

const {
  documentMetadataXLeadTextView,
  documentMetadataXLeadTextEdit,
  documentMetadataExcludeFromXRSSView,
  documentMetadataExcludeFromXRSSEdit,
  documentMetadataExcludeFromTgChannelsView,
  documentMetadataExcludeFromTgChannelsEdit,
} = useUserPermissions();

const {
  documentMetadataXLeadTextCondition,
  documentMetadataExcludeFromXRSSCondition,
  documentMetadataExcludeFromTgChannelsCondition,
} = useUserConditions();

const userStore = useUserStore();
const articleStore = useArticleStore();

const articleErrors = computed(() => articleStore.state.errors);

const isTwitterPostErrored = computed<boolean>(() => !!articleErrors.value.twitterPost);
</script>
