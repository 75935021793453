<script setup lang="ts">
import { MAX_ASSIGNMENT_LENGTH, METADATA_SECTIONS } from '@/features/ArticleLayout/constants.ts';
import FormTextarea from '@/components/FormTextarea.vue';
import FormCheckbox from '@/components/FormCheckbox.vue';
import ViewsReversedIcon from '@/assets/icons/views-reversed.svg?component';
import PictureAddIcon from '@/assets/icons/editor/picture-add.svg?component';
import PictureRemoveIcon from '@/assets/icons/picture-remove.svg?component';
import PictureReplaceIcon from '@/assets/icons/picture-replace.svg?component';
import CopyIcon from '@/assets/icons/copy.svg?component';
import CheckCircle from '@/assets/icons/check-circle.svg?component';
import { SIZES, BUTTON_TYPE } from '@/types';
import ImageSuggestions from '@/features/SearchImageAI/components/ImageSuggestions.vue';
import DeadlineFormSelect from '@/features/ArticleLayout/components/DeadlineFormSelect.vue';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store.ts';
import { useUserPermissions, useUserStore } from '@/stores/user.store.ts';
import { useUserConditions } from '@/composables/useUserConditions.ts';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import Tooltip from '@/components/Tooltip.vue';
import { useToast } from '@/composables';
import * as Sentry from '@sentry/vue';
import Button from '@/components/Button.vue';

const props = defineProps<{
  isAssignmentErrored: boolean;
  aiImageSuggestions: any[];
  isAiImageSuggestionsLoading: boolean;
  errors: Record<string, unknown>;
  assignmentAttrs: Record<string, unknown>;
  isBreakingNewsAttrs: Record<string, unknown>;
}>();
const emits = defineEmits<{
  (event: 'open-ai-image-modal'): void;
  (event: 'open-insert-image-modal'): void;
  (event: 'remove-cover'): void;
  (event: 'update-cover-art', value: number | string): void;
  (event: 'update-assignment', value: string): void;
  (event: 'update-breaking', value: boolean): void;
  (event: 'update-coverDeadline', value: string | number): void;
}>();

const articleStore = useArticleStore();
const userStore = useUserStore();
const { t } = useI18n();
const toast = useToast();

const isAssignmentOpen = ref(true);

const {
  documentMetadataBreakingNewsEdit,
  documentMetadataBreakingNewsView,
  documentMetadataSearchCoverAIView,
  documentMetadataSearchCoverAIEdit,
} = useUserPermissions();

const { documentMetadataBreakingNewsConditions, documentMetadataSearchCoverAICondition } = useUserConditions();

const initialCopyButtonData = {
  text: 'Copy assignment text',
  icon: CopyIcon,
};
const copyAssignmentButtonData = ref(initialCopyButtonData);
const copyAssignmentToClipboard = async (text: string) => {
  try {
    copyAssignmentButtonData.value = {
      text: 'Assignment was copied',
      icon: CheckCircle,
    };
    await navigator.clipboard.write([
      new ClipboardItem({
        'text/plain': new Blob([text], { type: 'text/plain' }),
      }),
    ]);
  } catch (e) {
    Sentry.captureException(e);
  } finally {
    setTimeout(() => {
      copyAssignmentButtonData.value = initialCopyButtonData;
    }, 3000);
    toast.success({
      id: 'ASSIGNMENT_TEXT_COPY',
      message: 'Assignment was copied to clipboard',
    });
  }
};

const coverArtUpdate = async (id: string) => {
  emits('update-cover-art', id);
};
</script>

<template>
  <div
    class="mb-6"
    :data-anchor="METADATA_SECTIONS.COVER"
  >
    <div class="flex items-center justify-between mb-3">
      <h3 class="font-semibold text-base text-imperium-fg-base">Cover</h3>
      <div class="flex gap-1">
        <Tooltip
          v-if="documentMetadataSearchCoverAIView"
          :key="`tooltip-slug`"
          width="unset"
          name="slug-info"
          placement="auto"
          :tooltip-visible="!documentMetadataSearchCoverAIEdit && !documentMetadataSearchCoverAICondition"
          button-class="w-full text-left"
        >
          <template #content>
            <Button
              :visual-type="BUTTON_TYPE.TERTIARY"
              :size="SIZES.SMALL"
              :is-disabled="
                (!documentMetadataSearchCoverAIEdit && !documentMetadataSearchCoverAICondition) ||
                !userStore.hasAccessToCurrentLanguage()
              "
              @click="emits('open-ai-image-modal')"
            >
              <template #leftIcon>
                <ViewsReversedIcon class="w-4 h-4 text-imperium-fg-muted" />
              </template>

              {{ articleStore.state.coverArt ? t('ai-cover-search.update') : t('ai-cover-search.search') }}
            </Button>
          </template>

          <template #tooltip>
            <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
          </template>
        </Tooltip>

        <Tooltip
          v-if="!articleStore.state.coverArt"
          :key="`tooltip-insert-cover`"
          width="unset"
          name="insert-cover"
          placement="bottom"
        >
          <template #content>
            <Button
              :visual-type="BUTTON_TYPE.TERTIARY"
              :size="SIZES.SMALL"
              class="h-full"
              @click="emits('open-insert-image-modal')"
            >
              <template #leftIcon>
                <PictureAddIcon class="w-4 h-4" />
              </template>
            </Button>
          </template>
          <template #tooltip>
            <div class="text-sm whitespace-nowrap">{{ t('ai-cover-search.insert') }}</div>
          </template>
        </Tooltip>
        <template v-if="articleStore.state.coverArt">
          <Tooltip
            :key="`tooltip-replace-cover`"
            width="unset"
            name="replace-cover"
            placement="bottom"
          >
            <template #content>
              <Button
                :visual-type="BUTTON_TYPE.TERTIARY"
                :size="SIZES.SMALL"
                class="h-full"
                @click="emits('open-insert-image-modal')"
              >
                <template #leftIcon>
                  <PictureReplaceIcon class="w-4 h-4 text-imperium-fg-muted" />
                </template>
              </Button>
            </template>
            <template #tooltip>
              <div class="text-sm whitespace-nowrap">{{ t('ai-cover-search.replace') }}</div>
            </template>
          </Tooltip>
          <Tooltip
            :key="`tooltip-remove-cover`"
            width="unset"
            name="remove-cover"
            placement="bottom"
          >
            <template #content>
              <Button
                :visual-type="BUTTON_TYPE.TERTIARY"
                :size="SIZES.SMALL"
                class="h-full"
                @click="emits('remove-cover')"
              >
                <template #leftIcon>
                  <PictureRemoveIcon class="w-4 h-4 text-imperium-fg-muted" />
                </template>
              </Button>
            </template>
            <template #tooltip>
              <div class="text-sm whitespace-nowrap">{{ t('ai-cover-search.clear') }}</div>
            </template>
          </Tooltip>
        </template>
      </div>
    </div>

    <FormTextarea
      :model-value="articleStore.state.assignment"
      name="assignment"
      class="mb-2"
      min-height="96px"
      placeholder="Enter assignment"
      :is-errored="props.isAssignmentErrored"
      is-required
      is-accordion
      :attrs="props.assignmentAttrs"
      :max="MAX_ASSIGNMENT_LENGTH"
      :size="SIZES.SMALL"
      @update:model-value="(value) => emits('update-assignment', value)"
      @toggleAccordion="(value) => (isAssignmentOpen = value)"
    >
      <template #label>
        <span class="text-xs">Assignment</span>
      </template>
      <template #error> {{ errors.assignment }} </template>
    </FormTextarea>

    <template v-if="isAssignmentOpen">
      <Button
        class="flex justify-center mb-3 w-full"
        :visual-type="BUTTON_TYPE.TERTIARY"
        :size="SIZES.SMALL"
        @click="copyAssignmentToClipboard(articleStore.state.assignment)"
      >
        <template #leftIcon>
          <Component :is="copyAssignmentButtonData.icon" />
        </template>
        {{ copyAssignmentButtonData.text }}
      </Button>

      <div
        v-if="props.aiImageSuggestions.length && documentMetadataSearchCoverAIView"
        class="mb-3"
      >
        <ImageSuggestions
          :title="t('ai-cover-search.suggestions')"
          :is-loading="props.isAiImageSuggestionsLoading"
          :disabled="!documentMetadataSearchCoverAIEdit && !documentMetadataSearchCoverAICondition"
          :images="props.aiImageSuggestions"
          :text="articleStore.state.assignment"
          @selectImage="coverArtUpdate"
        />
      </div>
    </template>

    <Tooltip
      :key="`tooltip-breaking`"
      width="unset"
      name="breaking-info"
      placement="auto"
      :tooltip-visible="!documentMetadataBreakingNewsEdit && !documentMetadataBreakingNewsConditions"
      button-class="w-full text-left"
    >
      <template #content>
        <FormCheckbox
          v-if="documentMetadataBreakingNewsView"
          :model-value="articleStore.state.isBreakingNews"
          :is-disabled="!documentMetadataBreakingNewsEdit && !documentMetadataBreakingNewsConditions"
          name="breaking-news"
          :size="SIZES.SMALL"
          :attrs="props.isBreakingNewsAttrs"
          class="mb-2 text-sm leading-[22px]"
          @update:model-value="(value) => emits('update-breaking', value)"
        >
          {{ t('article.breaking-news') }}
        </FormCheckbox>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>

    <DeadlineFormSelect
      :model-value="articleStore.state.coverDeadline"
      :is-breaking-news="articleStore.state.isBreakingNews"
      @update:model-value="(value) => emits('update-coverDeadline', value)"
    />
  </div>
</template>
