<script setup lang="ts">
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store.ts';
import { computed } from 'vue';
import { BUTTON_COLOR, BUTTON_TYPE } from '@/types';
import OpenLinkIcon from '@/assets/icons/open-link.svg?component';

const articleStore = useArticleStore();

const formattedPublishedAt = computed(() => {
  if (!articleStore.state.publishedAt) return;

  return new Intl.DateTimeFormat('en', {
    dateStyle: 'medium',
    timeStyle: 'short',
  }).format(new Date(articleStore.state.publishedAt));
});

const articleUrl = computed(() => articleStore.state.postUrl);
</script>

<template>
  <div class="flex justify-between gap-1 border input-rounded-default py-2 px-2 mb-4">
    <div class="flex flex-col gap-1">
      <h3 class="text-imperium-fg-base text-xs font-semibold">Published At</h3>

      <span
        class="text-imperium-fg-strong text-sm"
        v-if="formattedPublishedAt"
      >
        {{ formattedPublishedAt }}
      </span>
      <span
        v-else
        class="h-5"
      />
    </div>
    <a
      :color="BUTTON_COLOR.BASE"
      :visual-type="BUTTON_TYPE.PLAIN"
      target="_blank"
      :href="articleUrl"
    >
      <div class="flex gap-1 font-semibold text-imperium-ds-secondary-base items-center">
        <span class="text-sm">Link</span>
        <OpenLinkIcon class="w-4 h-4" />
      </div>
    </a>
  </div>
</template>
