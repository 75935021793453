<script setup lang="ts">
import { computed, nextTick, ref } from 'vue';
import { SIZES, BUTTON_TYPE, type Language } from '@/types';

import Dropdown from '@/components/Dropdown.vue';
import Button from '@/components/Button.vue';
import ArrowDownIcon from '@/assets/icons/arrow-down.svg?component';
import CountryIcon from '@/shared/ui/CountryIcon/CountryIcon.vue';
import { useUserStore } from '@/stores/user.store.ts';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';

const { state: user } = useUserStore();
const currentLanguageStore = useCurrentLanguageStore();

const emits = defineEmits<{
  (event: 'toggle'): void;
  (event: 'select', value: Language): void;
}>();

const languageDropdown = ref<HTMLElement | null>(null);

const languagesOptions = computed(() => {
  return [
    {
      id: 0,
      code: 'global',
      short: 'global',
      title: 'Global',
      name: 'Global',
    },
    ...user.languages,
  ];
});

const hasLanguagesOptions = computed<boolean>(() => {
  return languagesOptions.value?.length > 0;
});

const currentLanguage = computed(() => currentLanguageStore.currentLanguage);

const changeCurrentLanguage = (language: Language) => {
  currentLanguageStore.updateLanguage(language);
  nextTick(() => languageDropdown.value?.close());
};
</script>

<template>
  <Dropdown
    v-if="hasLanguagesOptions"
    id="article-translate"
    ref="languageDropdown"
    placement="bottom-start"
    :classes="{
      dropdown: 'w-60',
    }"
  >
    <template #button>
      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.TERTIARY"
        @click="emits('toggle')"
      >
        <template #leftIcon>
          <CountryIcon
            :key="currentLanguage.short"
            class="flex-none"
            :code="currentLanguage.short"
            :name="currentLanguage.title"
          />
        </template>
        <template #rightIcon>
          <ArrowDownIcon class="w-5 h-5 text-imperium-fg-base" />
        </template>

        {{ currentLanguage.title }}
      </Button>
    </template>

    <template #menu>
      <ul class="max-h-64 py-2 px-2 overflow-y-auto overflow-x-hidden text-base">
        <li
          v-for="language in languagesOptions"
          :key="language.short"
          class="w-full py-2 px-1 text-md font-semibold text-gray-900 hover:bg-imperium-bg-2 rounded dark:text-gray-300 flex items-center gap-2 cursor-pointer"
          @click="changeCurrentLanguage(language)"
        >
          <CountryIcon
            :key="language.short"
            class="flex-none"
            :code="language.short"
            :name="language.title"
          />
          {{ language.title }}
        </li>
      </ul>
    </template>
  </Dropdown>
</template>
