import { extractData, http } from '@/http';
import type { LabelsFetchRequest, LabelsFetchResponse, LabelFetchParam, LabelTranslate } from '@/features/Labels/types';

export const fetchLabels = (params: LabelsFetchRequest): Promise<LabelsFetchResponse> =>
  http.get<LabelsFetchResponse>('/badges', { params, useAuth: true }).then(extractData);

export const fetchLabel = ({ labelId, languageId }: LabelFetchParam) =>
  http
    .get<LabelTranslate>(`/badges/${labelId}/language/${languageId}`, {
      useAuth: true,
    })
    .then(extractData);

// TODO change after backend will be done
export const createLabel = (label: LabelTranslate) =>
  http
    .post<LabelTranslate>(`/badges`, label, {
      useAuth: true,
    })
    .then(extractData);

export const updateLabel = (labelId: string | number, languageId: number, label: LabelTranslate) =>
  http
    .patch<LabelTranslate>(`/badges/${labelId}/language/${languageId}`, label, {
      useAuth: true,
    })
    .then(extractData);
