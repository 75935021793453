export enum UserStatuses {
  ACTIVE = 'active',
  EXPIRED = 'expired',
  INVITED = 'invited',
  DEACTIVATED = 'deactivated',
}

export enum UserRoles {
  SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  EDITOR_IN_CHIEF = 'editor-in-chief',
  COPY_EDITOR = 'copy-editor',
  ART_EDITOR = 'art-editor',
  WRITER = 'writer',
  TESTER = 'tester',
}

export enum UserLanguages {
  ENGLISH = 'english',
}

export enum UserPermissions {
  CATEGORY_PAGE = 'CATEGORY.PAGE',
  MEDIA_PAGE = 'MEDIA.PAGE',
  MEDIA_UPLOAD_IMAGE = 'MEDIA.UPLOAD_IMAGE',
  MEDIA_EDIT_IMAGE = 'MEDIA.EDIT_IMAGE',
  MEDIA_DELETE_IMAGE = 'MEDIA.DELETE_IMAGE',
  USERS_PAGE = 'USERS_PAGE.USERS_PAGE',
  CONVERTER_PAGE = 'CONVERTER.PAGE',
  USERS_PAGE_INVITE_USER = 'USERS_PAGE.INVITE_USER',
  USERS_PAGE_ADD_SUPERADMIN_ROLE = 'USERS_PAGE.ADD_SUPERADMIN_ROLE',
  USERS_PAGE_EDIT_USER_STATUS = 'USERS_PAGE.EDIT_USER_STATUS',
  USERS_PAGE_2FA_RESET = 'USERS_PAGE.RESET_TWO_FACTOR',
  DOCUMENT_CREATE = 'DOCUMENT.STATUS.CREATE',
  DOCUMENT_CHANGE_STATUS_TO_WORK = 'DOCUMENT.STATUS.START_WORK',
  DOCUMENT_CHANGE_STATUS_FINISH_TEXT = 'DOCUMENT.STATUS.FINISH_TEXT',
  DOCUMENT_CHANGE_STATUS_FINISH_ARTWORK = 'DOCUMENT.STATUS.FINISH_ARTWORK',
  DOCUMENT_CHANGE_STATUS_BACK_TO_WORK = 'DOCUMENT.STATUS.BACK_TO_WORK',
  DOCUMENT_CHANGE_STATUS_REJECT_TEXT = 'DOCUMENT.STATUS.REJECT_TEXT',
  DOCUMENT_CHANGE_STATUS_REJECT_ARTWORK = 'DOCUMENT.STATUS.REJECT_ARTWORK',
  DOCUMENT_CHANGE_STATUS_FINISH_REVIEW = 'DOCUMENT.STATUS.FINISH_REVIEW',
  DOCUMENT_CHANGE_STATUS_PUBLISH = 'DOCUMENT.STATUS.PUBLISH',
  DOCUMENT_CHANGE_STATUS_UPDATE = 'DOCUMENT.STATUS.UPDATE',
  DOCUMENT_METADATA_TEXT = 'DOCUMENT.METADATA.DOCUMENT_TEXT',
  DOCUMENT_METADATA_SLUG = 'DOCUMENT.METADATA.SLUG_URL',
  DOCUMENT_METADATA_TITLE = 'DOCUMENT.METADATA.TITLE',
  DOCUMENT_METADATA_COVER = 'DOCUMENT.METADATA.COVER_IMAGE',
  DOCUMENT_METADATA_COVER_IMAGE_UPDATE = 'DOCUMENT.METADATA.COVER_IMAGE_UPDATE',
  DOCUMENT_METADATA_BREAKING_NEWS = 'DOCUMENT.METADATA.BREAKING_NEWS',
  DOCUMENT_METADATA_NEEDS_ORIGINAL_ARTWORK = 'DOCUMENT.METADATA.NEEDS_ORIGINAL_ARTWORK',
  DOCUMENT_METADATA_EDITOR_SELECT = 'DOCUMENT.METADATA.EDITOR_SELECT',
  DOCUMENT_METADATA_WRITER_SELECT = 'DOCUMENT.METADATA.WRITER_SELECT',
  DOCUMENT_METADATA_COPY_EDITOR = 'DOCUMENT.METADATA.COPY_EDITOR',
  DOCUMENT_METADATA_YOUTUBE_COVER = 'DOCUMENT.METADATA.YOUTUBE_COVER',
  DOCUMENT_METADATA_DEADLINE_FOR_COVER_IMAGE = 'DOCUMENT.METADATA.DEADLINE_FOR_COVER_IMAGE',
  DOCUMENT_METADATA_EMBARGO = 'DOCUMENT.METADATA.EMBARGO',
  DOCUMENT_METADATA_SCHEDULE_PUBLICATION = 'DOCUMENT.METADATA.SCHEDULE_PUBLICATION',
  DOCUMENT_METADATA_CATEGORY = 'DOCUMENT.METADATA.CATEGORY',
  DOCUMENT_METADATA_LABEL = 'DOCUMENT.METADATA.LABEL',
  DOCUMENT_METADATA_SUPER_TAGS = 'DOCUMENT.METADATA.SUPER_TAGS',
  DOCUMENT_METADATA_TAGS = 'DOCUMENT.METADATA.TAGS',
  DOCUMENT_METADATA_X_LEADTEXT = 'DOCUMENT.METADATA.X_LEADTEXT',
  DOCUMENT_METADATA_EXCLUDE_FROM_X_RSS = 'DOCUMENT.METADATA.EXCLUDE_FROM_X_RSS',
  DOCUMENT_METADATA_EXCLUDE_FROM_TELEGRAM_CHANNELS = 'DOCUMENT.METADATA.EXCLUDE_FROM_TELEGRAM_CHANNELS',
  DOCUMENT_METADATA_SEO_META_DESCRIPTIONS = 'DOCUMENT.METADATA.SEO_META_DESCRIPTIONS',
  DOCUMENT_METADATA_HIDE_NO_INDEX = 'DOCUMENT.METADATA.NO_INDEX',
  DOCUMENT_METADATA_HIDE_FROM_MAIN_PAGE = 'DOCUMENT.METADATA.HIDE_FROM_MAIN_PAGE',
  DOCUMENT_METADATA_SHOW_IN_MARKETS = 'DOCUMENT.METADATA.SHOW_IN_MARKETS',
  DOCUMENT_METADATA_PROMO_POST = 'DOCUMENT.METADATA.PROMO_POST',
  DOCUMENT_METADATA_REPUBLISHED_ARTICLE = 'DOCUMENT.METADATA.REPUBLISHED_ARTICLE',
  DOCUMENT_METADATA_ORIGINAL_CONTENT = 'DOCUMENT.METADATA.ORIGINAL_CONTENT',
  DOCUMENT_TRANSLATE = 'DOCUMENT.TRANSLATE',
  USERS_PAGE_EDIT_USER_LANGUAGES = 'USERS_PAGE.EDIT_USER_LANGUAGES',
  DOCUMENT_METADATA_SEARCH_COVER_AI = 'DOCUMENT.METADATA.SEARCH_COVER_AI',
  LABEL_PAGE = 'LABEL.PAGE',

  // Temp unavailable
  DOCUMENT_METADATA_SOCIAL_NETWORKS_LEADTEXT = 'DOCUMENT.METADATA.SOCIAL_NETWORKS_LEADTEXT',
  DOCUMENT_METADATA_LEADTEXT = 'DOCUMENT.METADATA.LEADTEXT',
  DOCUMENT_METADATA_TELEGRAM_LEADTEXT = 'DOCUMENT.METADATA.TELEGRAM_LEADTEXT',
  DOCUMENT_METADATA_PIN_IN_RELATED = 'DOCUMENT.METADATA.PIN_IN_RELATED',
}

export enum UserConditions {
  WRITERS_ASSIGNED = 'Senior Writer and Writer Assigned',
  WRITER_ASSIGNED = 'Writer Assigned',
  SENIOR_WRITER_ASSIGNED = 'Senior Writer Assigned',
}
