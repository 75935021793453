<script setup lang="ts">
import { CoverDeadline, CoverDeadlineOptions } from '@/features/CreateArticle/types';
import { useUserPermissions, useUserStore } from '@/stores/user.store';
import { useUserConditions } from '@/composables/useUserConditions';
import { useI18n } from 'vue-i18n';
import FormSelect from '@/components/FormSelect.vue';
import { watchEffect } from 'vue';
import { SIZES, UserPermissions } from '@/types';
import Tooltip from '@/components/Tooltip.vue';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store.ts';

const props = withDefaults(
  defineProps<{
    modelValue: string;
    isBreakingNews: boolean;
  }>(),
  {},
);

const emits = defineEmits<{
  (event: 'update:modelValue', value: number | string): void;
}>();

const articleStore = useArticleStore();

const { documentMetadataCoverDeadlineView, documentMetadataCoverDeadlineEdit } = useUserPermissions();

const { metadataCoverCondition } = useUserConditions();
const userStore = useUserStore();

const { t } = useI18n();
</script>

<template>
  <Tooltip
    :key="`tooltip-cover-deadline`"
    width="unset"
    name="deadline-info"
    placement="auto"
    :tooltip-visible="!documentMetadataCoverDeadlineEdit && !metadataCoverCondition"
    button-class="w-full text-left"
  >
    <template #content>
      <FormSelect
        v-if="documentMetadataCoverDeadlineView"
        :model-value="props.modelValue"
        is-required
        name="coverDeadlineId"
        class="mb-2"
        :size="SIZES.SMALL"
        :is-disabled="
          props.isBreakingNews ||
          (!documentMetadataCoverDeadlineEdit && !metadataCoverCondition) ||
          !userStore.hasAccessToCurrentLanguage()
        "
        y
        :values="CoverDeadlineOptions"
        @update:model-value="(value) => emits('update:modelValue', value)"
      >
        <template #label>
          {{ t('article.cover-deadline') }}
        </template>
      </FormSelect>
    </template>
    <template #tooltip>
      <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
    </template>
  </Tooltip>
</template>
