<template>
  <component
    :is="path"
    class="w-5 h-4 border-none"
    :class="{
      'opacity-50': props.isDisabled,
      'w-6 h-6 rounded-full border border-opacity-15 border-imperium-border-clear': props.rounded,
    }"
  />
</template>
<script setup lang="ts">
/**
 * @see https://github.com/kapowaz/square-flags
 * to download new country flags
 */
import { defineAsyncComponent } from 'vue';

const props = withDefaults(
  defineProps<{
    code: string;
    name: string;
    isDisabled?: boolean;
    rounded?: boolean;
  }>(),
  {
    rounded: true,
  },
);

const path = defineAsyncComponent(() => import(`@/assets/country-flags/square/${props.code}.svg?component`));
</script>
