<template>
  <div>
    <div class="relative">
      <div
        v-if="labelsStore.state.isLoading"
        class="max-w-[100vw] min-h-[60vh] flex items-center justify-center"
      >
        <div class="h-10 w-10">
          <LoaderIcon />
        </div>
      </div>

      <div
        v-else
        class="relative bg-imperium-bg-sub-base"
        :class="{
          'rounded-2xl': !isMobile,
        }"
      >
        <div>
          <Table
            class="max-w-full relative overflow-x-auto"
            :is-sticky="!isExtraLargeDesktop"
            topRounded
          >
            <TableHead>
              <TableHeadRow
                :size="SIZES.MEDIUM"
                :bordered="TABLE_BORDERED.BOTTOM"
                :is-on-surface="TABLE_ON_SURFACE.PRIMARY"
              >
                <TableHeadCell
                  :size="SIZES.SMALL"
                  :scale="TABLE_SCALE.DOUBLE"
                  :is-sticky="true"
                  is-uppercase
                >
                  <SortColumnHeader
                    :id="LABEL_FIELD_ID"
                    label="Id"
                    class="p-2"
                    :value="filtersStore.state.sort[LABEL_FIELD_ID]"
                    @change-sort="updateFilters({ filterName: LABEL_FIELD_ID, value: $event })"
                  />
                </TableHeadCell>

                <TableHeadCell
                  :size="isTablet ? SIZES.LARGE : SIZES.XLARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold"
                  :is-sticky="true"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  <SortColumnHeader
                    :id="LABEL_FIELD_NAME"
                    label="Label"
                    class="p-2"
                    :value="filtersStore.state.sort[LABEL_FIELD_NAME]"
                    @change-sort="updateFilters({ filterName: LABEL_FIELD_NAME, value: $event })"
                  />
                </TableHeadCell>

                <TableHeadCell
                  :size="isTablet ? SIZES.LARGE : SIZES.XLARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold"
                  :is-sticky="true"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  <p class="p-2">Category</p>
                </TableHeadCell>

                <TableHeadCell
                  :size="isTablet ? SIZES.LARGE : SIZES.XLARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold"
                  :is-sticky="true"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  <p class="p-2">Label type</p>
                </TableHeadCell>
              </TableHeadRow>
            </TableHead>

            <TableBody v-if="!!labelsStore.state.labels.length">
              <TableRow
                v-for="label in labelsStore.state.labels"
                :key="label[LABEL_FIELD_ID]"
                :size="SIZES.MEDIUM"
                :striped="TABLE_STRIPED.STRIPED"
                :bordered="TABLE_BORDERED.BOTTOM"
                class="cursor-pointer"
                @click="emits('redirect', label[LABEL_FIELD_ID])"
              >
                <TableCell
                  :size="SIZES.SMALL"
                  :scale="TABLE_SCALE.DOUBLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  :is-sticky="true"
                  is-uppercase
                >
                  <span class="text-imperium-fg-muted">{{ label[LABEL_FIELD_ID] }}</span>
                </TableCell>

                <TableCell
                  :scale="TABLE_SCALE.NORMAL"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold"
                  :is-sticky="true"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  {{ label[LABEL_FIELD_NAME] || '—' }}
                </TableCell>

                <TableCell
                  :scale="TABLE_SCALE.TRIPLE"
                  is-uppercase
                  is-full-width
                >
                  <CategoriesTableLabelBadges
                    v-if="label[LABEL_FIELD_CATEGORIES]"
                    :badges="label[LABEL_FIELD_CATEGORIES]"
                  />
                </TableCell>
                <TableCell
                  :scale="TABLE_SCALE.TRIPLE"
                  is-uppercase
                  is-full-width
                >
                  {{ LABEL_TYPE[label[LABEL_FIELD_TYPE].toUpperCase()] || '—' }}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <div
          v-if="!paginationStore.state.total"
          class="w-full flex align-middle justify-center items-center min-h-[60vh] max-w-[100vw]"
        >
          <div class="font-semibold text-imperium-fg-strong">No labels found</div>
        </div>

        <div
          v-if="paginationStore.state.total"
          class="py-3 p-4 mt-1"
        >
          <Pagination
            :current-page="paginationStore.state.page || 1"
            :is-mobile="isMobile || isTablet"
            :items-count="paginationStore.state.total || 0"
            :limit="paginationStore.state.limit"
            @change-page="paginationStore.changePage"
            @change-limit="paginationStore.changeLimit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject, onMounted, watch } from 'vue';
import LoaderIcon from '@/assets/icons/spinner.svg?component';
import Pagination from '@/components/Table/Pagination/Pagination.vue';
import { TABLE_BORDERED, TABLE_ON_SURFACE, TABLE_STRIPED, TABLE_SCALE, SIZES, SortStatus, TABLE_VALIGN } from '@/types';
import { Table, TableBody, TableHead, TableCell, TableHeadCell, TableRow, TableHeadRow } from '@/components/Table';
import SortColumnHeader from '@/components/Table/Filters/SortColumnHeader.vue';
import {
  LABEL_FIELD_CATEGORIES,
  LABEL_FIELD_CATEGORY_TRANSLATE,
  LABEL_FIELD_ID,
  LABEL_FIELD_NAME,
  LABEL_FIELD_TYPE,
  LABEL_TYPE,
} from '@/features/Labels/constants';
import { useLabelsFilters } from '@/features/Labels/stores/filters.store';
import { useLabelsStore } from '@/features/Labels/stores/labels.store';
import { useLabelsPagination } from '@/features/Labels/stores/pagination.store';
import isEqual from 'lodash/isEqual';
import { CATEGORY_FIELD_LABELS } from '@/features/Categories/constants.ts';
import CategoriesTableLabelBadges from '@/features/Categories/components/CategoriesTableLabelBadges.vue';

const emits = defineEmits<{
  (event: 'redirect', roleId: number): void;
}>();

const isMobile = inject<boolean>('isMobile');
const isTablet = inject<boolean>('isTablet');
const isExtraLargeDesktop = inject<boolean>('isExtraLargeDesktop');

const filtersStore = useLabelsFilters();
const paginationStore = useLabelsPagination();

const labelsStore = useLabelsStore();

const updateFilters = ({ filterName, value }: { filterName: string; value: SortStatus }) => {
  filtersStore.setSorts({
    [filterName]: value ? value : SortStatus.ASC,
  });
};

onMounted(() => {
  labelsStore.fetchLabels();
});

watch(
  () => [filtersStore.state, paginationStore.state],
  (newVal, oldVal) => {
    const isFiltersEqual = isEqual(newVal, oldVal);
    if (!isFiltersEqual) {
      labelsStore.state.labels = [];
      labelsStore.fetchLabels();
    }
  },
  { deep: true },
);
</script>
