<template>
  <section>
    <h3 class="font-semibold text-base text-imperium-fg-base mb-2">Date & Schedule</h3>

    <div v-if="documentMetadataScheduledView">
      <Tooltip
        :key="`tooltip-schedule`"
        width="unset"
        name="schedule-info"
        placement="auto"
        :tooltip-visible="!documentMetadataScheduledEdit && !documentMetadataScheduledCondition"
        button-class="w-full text-left"
      >
        <template #content>
          <span class="inline-block text-xs mb-2">Schedule publication</span>
          <FormDatepicker
            :is-disabled="
              (!documentMetadataScheduledEdit && !documentMetadataScheduledCondition) ||
              !userStore.hasAccessToCurrentLanguage()
            "
            name="publish"
            class="mb-2"
            placeholder="Not selected"
            :model-value="articleStore.state.publishedAt"
            :size="SIZES.SMALL"
            :attrs="publishAttrs"
            @update:model-value="(value) => emits('update:publish', value)"
          />
        </template>
        <template #tooltip>
          <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
        </template>
      </Tooltip>
    </div>

    <div v-if="documentMetadataEmbargoView">
      <Tooltip
        :key="`tooltip-embargo`"
        width="unset"
        name="embargo-info"
        placement="auto"
        :tooltip-visible="!documentMetadataEmbargoEdit && !metadataEmbargoCondition"
        button-class="w-full text-left"
      >
        <template #content>
          <span class="inline-block text-xs mb-2">Embargo</span>
          <FormDatepicker
            name="embargo"
            :is-disabled="
              (!documentMetadataEmbargoEdit && !metadataEmbargoCondition) ||
              !articleStore.isOriginalArticle() ||
              !userStore.hasAccessToCurrentLanguage()
            "
            class="mb-2"
            placeholder="Not selected"
            :size="SIZES.SMALL"
            :model-value="articleStore.state.embargoUntil"
            :attrs="embargoAttrs"
            @update:model-value="(value) => emits('update:embargo', value)"
          />
        </template>
        <template #tooltip>
          <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
        </template>
      </Tooltip>
    </div>
  </section>
</template>

<script lang="ts" setup>
import FormDatepicker from '@/components/FormDatepicker';
import { SIZES } from '@/types';
import { useUserPermissions, useUserStore } from '@/stores/user.store';
import { useUserConditions } from '@/composables/useUserConditions';
import Tooltip from '@/components/Tooltip.vue';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store.ts';

const props = defineProps<{
  deadlineAttrs: Record<string, unknown>;
  publishAttrs: Record<string, unknown>;
  embargoAttrs: Record<string, unknown>;
}>();

const emits = defineEmits<{
  (event: 'update:deadline', value: string): void;
  (event: 'update:publish', value: string): void;
  (event: 'update:embargo', value: string): void;
}>();

const {
  documentMetadataEmbargoView,
  documentMetadataEmbargoEdit,
  documentMetadataScheduledView,
  documentMetadataScheduledEdit,
} = useUserPermissions();

const { metadataEmbargoCondition, documentMetadataScheduledCondition } = useUserConditions();

const articleStore = useArticleStore();
const userStore = useUserStore();
</script>
