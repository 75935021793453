import { extractData, http } from '@/http';
import qs from 'qs';
import type { CoverImageAIResponse, CoverImageAIParams } from './types';
import type { AxiosRequestConfig } from 'axios';
import { aiCoverSearchApiUrl } from '@/config';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  disableWithCredentials?: boolean;
}

export const fetchCoverMatches = async (params: CoverImageAIParams): Promise<CoverImageAIResponse> => {
  try {
    const formData = qs.stringify(params);

    const response = await http.post<CoverImageAIResponse>(`${aiCoverSearchApiUrl}/search`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      disableWithCredentials: true,
    } as CustomAxiosRequestConfig);

    return extractData<CoverImageAIResponse>(response);
  } catch (error) {
    // Handle errors if needed
    console.error('Error fetching cover matches:', error);
    throw error;
  }
};
