<template>
  <ModalHolder
    v-if="isVisible"
    data-testid="create-new-article-modal"
    :is-visible="props.isVisible"
    :size="SIZES.MEDIUM"
    @close="onClose"
  >
    <template #title>
      {{ t('article.create-modal.cta') }}
    </template>

    <form
      class="px-2 pb-4"
      @submit="onSubmit"
    >
      <div class="pt-4 pb-3">
        <div class="flex gap-2 mb-3">
          <LanguageDropdown
            v-model="language"
            :language="language"
            class="basis-1/2"
            @update:model-value="updateLanguage"
          />
          <FormSelect
            v-model="coverDeadline"
            is-required
            name="coverDeadlineId"
            class="basis-1/2"
            :class="{
              'basis-1': !user.languages.length,
            }"
            :attrs="coverDeadLineAttrs"
            :is-errored="isCoverDeadlineErrored"
            :is-disabled="breakingNews"
            :values="CoverDeadlineOptions"
          >
            <template #label>
              {{ t('article.cover-deadline') }}
            </template>
            <template #error> Fill the cover deadline </template>
          </FormSelect>
        </div>
        <div class="mb-3">
          <FormTextarea
            v-model="assignment"
            class="mb-1"
            name="assignment"
            min-height="60px"
            :placeholder="t('article.assignment-placeholder').toString()"
            :is-errored="isAssignmentErrored"
            :attrs="assignmentAttrs"
            :max="ASSIGNMENT_MAX_LENGTH"
            is-required
          >
            <template #label>
              {{ t('article.assignment') }}
            </template>

            <template #error>
              {{ errors.assignment }}
            </template>
          </FormTextarea>
        </div>

        <div class="flex gap-4 justify-start items-center mb-4">
          <FormCheckbox
            v-if="documentMetadataBreakingNewsView"
            v-model="breakingNews"
            :is-disabled="!documentMetadataBreakingNewsEdit && !documentMetadataBreakingNewsCondition"
            name="breaking-news"
            :attrs="breakingNewsAttrs"
          >
            {{ t('article.breaking-news') }}
          </FormCheckbox>
        </div>

        <FormSelect
          v-if="documentMetadataCategoryView"
          id="create-article-modal-category"
          v-model="category"
          :is-disabled="!documentMetadataCategoryEdit && !documentMetadataCategoryCondition"
          name="categoryId"
          class="mb-2"
          :attrs="categoryAttrs"
          :values="categories"
        >
          <template #label>
            {{ t('article.category') }}
          </template>
        </FormSelect>

        <div class="mb-2 gap-2 flex justify-between items-end">
          <FormSelect
            v-if="documentMetadataEditorSelectView"
            id="create-article-modal-editor"
            v-model="editor"
            :is-disabled="!documentMetadataEditorSelectEdit && !documentMetadataEditorSelectCondition"
            name="editorId"
            class="w-full"
            :placeholder="t('common.unassigned').toString()"
            :attrs="editorAttrs"
            :values="editors"
          >
            <template #label>
              {{ t('article.editor') }}
            </template>
          </FormSelect>
          <Button
            v-if="allowedAssignToMeAsEditor"
            :visual-type="BUTTON_TYPE.GHOST"
            :size="SIZES.SMALL"
            data-testid="button-assign-to-me"
            class="mb-1 whitespace-nowrap"
            @click="assignToMe(ASSIGN_TO_ME_ENTITY.EDITOR)"
          >
            {{ t('article.create-modal.assign-to-me') }}
          </Button>
        </div>

        <div
          v-if="documentMetadataWriterSelectView"
          class="flex justify-between items-end gap-2"
        >
          <FormSelect
            id="create-article-modal-writer"
            v-model="writer"
            :is-disabled="!documentMetadataWriterSelectEdit && !documentMetadataWriterSelectCondition"
            name="writerId"
            class="w-full"
            :placeholder="t('common.unassigned').toString()"
            :attrs="writerAttrs"
            :values="writers"
          >
            <template #label>
              {{ t('article.writer') }}
            </template>
          </FormSelect>
          <Button
            v-if="allowedAssignToMeAsWriter"
            :visual-type="BUTTON_TYPE.GHOST"
            :size="SIZES.SMALL"
            data-testid="button-assign-to-me"
            class="mb-1 whitespace-nowrap"
            @click="assignToMe(ASSIGN_TO_ME_ENTITY.WRITER)"
          >
            {{ t('article.create-modal.assign-to-me') }}
          </Button>
        </div>
      </div>

      <div
        v-if="createDocumentView"
        class="flex items-center gap-4 justify-end pt-2"
      >
        <Button
          :is-disabled="!createDocumentEdit && !createDocumentCondition"
          type="submit"
          :size="SIZES.MEDIUM"
          :is-loading="isLoading"
          data-testid="button-cta"
          is-full-width
        >
          {{ t('article.create-modal.cta') }}
        </Button>
      </div>
    </form>
  </ModalHolder>
</template>

<script setup lang="ts">
import zod from 'zod';
import { computed, onMounted, ref, watch, watchEffect } from 'vue';
import { toTypedSchema } from '@vee-validate/zod';
import { useI18n } from 'vue-i18n';

import FormCheckbox from '@/components/FormCheckbox.vue';
import FormTextarea from '@/components/FormTextarea.vue';
import FormSelect, { type SelectItem } from '@/components/FormSelect.vue';
import Button from '@/components/Button.vue';
import ModalHolder from '@/components/ModalHolder.vue';

import { useFormData, useSimpleAction, useToast } from '@/composables';
import { CreateDocumentService } from './service';
import { ASSIGN_TO_ME_ENTITY, CoverDeadline, CoverDeadlineOptions, type CreateArticlePayload } from './types';
import { BUTTON_TYPE, type Language, ROLES, SIZES } from '@/types';
import { useFetchUsers } from '@/features/Users/queries';
import { type UsersFetchPayload } from '@/features/Users/types';
import { mapUsersToSelectItemByRole } from '@/features/Users/utils';
import { useUserPermissions, useUserStore } from '@/stores/user.store';
import { useLanguagesStore } from '@/stores/languages.store';
import { useRolesStore } from '@/features/Roles/stores/roles.store';
import { useUserConditions } from '@/composables/useUserConditions.ts';
import LanguageDropdown from '@/features/Languages/components/LanguageDropdown.vue';
import type { ArticleCreated, ArticleCreatedLanguage } from '@/features/Articles/types.ts';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';
import { fetchCategories } from '@/features/ArticleLayout/api';

const ASSIGNMENT_MAX_LENGTH = 255;

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
  }>(),
  {
    isVisible: false,
  },
);

const emits = defineEmits<{
  (event: 'confirm', article: ArticleCreatedLanguage): void;
  (event: 'close'): void;
}>();

const rolesStore = useRolesStore();

const roles = computed(() => rolesStore.state);

const getWriterRoleId = computed(() => roles?.value?.find((role) => role.slug === ROLES.ROLE_WRITER)?.id) ?? null;
const getSeniorWriterRoleId =
  computed(() => roles?.value?.find((role) => role.slug === ROLES.ROLE_SENIOR_WRITER)?.id) ?? null;
const getEditorRoleId = computed(() => roles?.value?.find((role) => role.slug === ROLES.ROLE_EDITOR)?.id) ?? null;
const getCopyEditorRoleId =
  computed(() => roles?.value?.find((role) => role.slug === ROLES.ROLE_COPY_EDITOR)?.id) ?? null;
const getEditorInChiefRoleId =
  computed(() => roles?.value?.find((role) => role.slug === ROLES.ROLE_EDITOR_IN_CHIEF)?.id) ?? null;

const { createDocumentView, createDocumentEdit } = useUserPermissions();
const { createDocumentCondition } = useUserConditions();

const { t } = useI18n();
const toast = useToast();

const userStore = useUserStore();

const languagesStore = useLanguagesStore();
const languages = computed(() => languagesStore.state);

const currentLanguageStore = useCurrentLanguageStore();

const { defineField, errors, values, meta, validate, setErrors, resetForm } = useFormData({
  data: {
    isBreaking: false,
    categoryId: null,
    assignment: '',
    coverDeadline: '',
    needsOriginalArtwork: false,
    editorId: null,
    writerId: null,
    language: null,
  },
  validator: toTypedSchema(
    zod.object({
      isBreakings: zod.boolean().optional(),
      categoryId: zod.number().nullable(),
      assignment: zod.string().min(1),
      coverDeadline: zod.string().min(1),
      needsOriginalArtwork: zod.boolean().optional(),
      editorId: zod.number().nullable(),
      writerId: zod.number().nullable(),
      language: zod.number().default(currentLanguageStore.currentLanguage.id),
    }),
  ),
});

const [breakingNews, breakingNewsAttrs] = defineField('isBreaking');
const [category, categoryAttrs] = defineField('categoryId');
const [assignment, assignmentAttrs] = defineField('assignment');
const [needsCover, needsCoverAttrs] = defineField('needsOriginalArtwork');
const [editor, editorAttrs] = defineField('editorId');
const [writer, writerAttrs] = defineField('writerId');
const [coverDeadline, coverDeadLineAttrs] = defineField('coverDeadline');
const [language] = defineField('language');

const isAssignmentErrored = computed(() => meta.value.touched && !!errors.value.assignment);
const isCoverDeadlineErrored = computed(() => meta.value.touched && !!errors.value.coverDeadline);

const fetchUserParams = computed<UsersFetchPayload>(() => ({
  page: 1,
  itemsPerPage: 1000,
  filters: {
    roles: [
      getCopyEditorRoleId.value,
      getWriterRoleId.value,
      getEditorRoleId.value,
      getSeniorWriterRoleId.value,
      getEditorInChiefRoleId.value,
    ],
    languages: [language.value],
  },
}));
const { data: users } = useFetchUsers(fetchUserParams);

const user = computed(() => userStore.state);

const isAssignToCurrentUser = computed(() => {
  if (!writer.value) return false;

  return writer.value === user.value.id;
});

const allowedAssignToMeAsEditor = computed(
  () => user.value.roles?.includes(ROLES.ROLE_EDITOR) || user.value.roles?.includes(ROLES.ROLE_EDITOR_IN_CHIEF),
);

const allowedAssignToMeAsWriter = computed(
  () =>
    user.value.roles?.includes(ROLES.ROLE_WRITER) ||
    user.value.roles?.includes(ROLES.ROLE_SENIOR_WRITER) ||
    user.value.roles?.includes(ROLES.ROLE_EDITOR_IN_CHIEF),
);
const {
  documentMetadataBreakingNewsView,
  documentMetadataBreakingNewsEdit,
  documentMetadataOriginalArtView,
  documentMetadataOriginalArtEdit,
  documentMetadataEditorSelectView,
  documentMetadataEditorSelectEdit,
  documentMetadataWriterSelectView,
  documentMetadataWriterSelectEdit,
  documentMetadataCategoryView,
  documentMetadataCategoryEdit,
  documentMetadataCoverDeadlineEdit,
} = useUserPermissions();

const {
  documentMetadataBreakingNewsCondition,
  documentMetadataEditorSelectCondition,
  documentMetadataWriterSelectCondition,
  documentMetadataCategoryCondition,
} = useUserConditions();

const writers = computed<SelectItem[]>(() => {
  if (!roles.value?.length || !users.value?.items?.length) {
    return [];
  }

  return [
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_WRITER),
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_SENIOR_WRITER),
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_EDITOR),
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_EDITOR_IN_CHIEF),
  ];
});

const editors = computed<SelectItem[]>(() => {
  if (!roles.value?.length || !users.value?.items?.length) {
    return [];
  }

  return [
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_EDITOR),
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_EDITOR_IN_CHIEF),
  ];
});

const { isLoading, action } = useSimpleAction(async (payload: CreateArticlePayload) => {
  try {
    const { valid, errors: validationErrors } = await validate();
    if (!valid) {
      throw validationErrors;
    }
  } catch (errors: any) {
    setErrors(errors as Record<string, string>);
    return;
  }
  const createPayload = {
    ...payload,
    languageId: language.value,
  };
  delete createPayload.language;

  try {
    // @ts-expect-error Wrong type TODO: Reconsider types
    const result: ArticleCreated = await CreateDocumentService.create(createPayload);

    emits('confirm', { ...result, languageId: language.value });

    setTimeout(() => {
      toast.success({
        id: 'SUCCESS_ARTICLE_CREATION',
        message: 'Article was successfully created',
      });
    }, 0);
  } catch {
    toast.errorTemporary({
      id: 'ERROR_ARTICLE_CREATION',
      message: 'Unable to create article. Try one more time.',
    });
  }
});

const assignToMe = (field: ASSIGN_TO_ME_ENTITY) => {
  if (!user.value) return;

  if (field === ASSIGN_TO_ME_ENTITY.EDITOR) {
    const me = editors?.value?.find((editor) => editor.id === user.value.id);
    editor.value = me?.id ?? null;
  } else if (field === ASSIGN_TO_ME_ENTITY.WRITER) {
    const me = writers?.value?.find((editor) => editor.id === user.value.id);
    writer.value = me?.id ?? null;
  }
};

const onSubmit = (e: SubmitEvent) => {
  action(values);
  e.preventDefault();
};

const onClose = () => {
  emits('close');
  resetForm();
};
const updateLanguage = (lang: Language) => {
  language.value = lang;
};

const categories = ref<SelectItem[] | []>([]);

const getCategories = async () => {
  const payload = {
    languageId: language.value,
    'filter[relevant]': true,
  };
  const data = await fetchCategories(payload);

  categories.value = data.items.map((categoryTranslate) => ({
    id: categoryTranslate.id,
    label: categoryTranslate.title,
  }));
};

onMounted(() => {
  if (currentLanguageStore.currentLanguage) {
    language.value = currentLanguageStore.currentLanguage.id;
  }
  if (language.value) {
    getCategories();
  }
});

watchEffect(() => {
  if (breakingNews.value) {
    coverDeadline.value = CoverDeadline['10_MINUTES'];
  }
  if (currentLanguageStore.currentLanguage.id) {
    language.value = currentLanguageStore.currentLanguage.id;
  }
});

watch(
  () => language.value,
  (newVal) => {
    getCategories();
  },
);
</script>
