<template>
  <div class="flex flex-col">
    <div class="flex mb-4 mx-2 justify-between full-width">
      <FormInput
        id="labels-search"
        :model-value="searchQuery"
        placeholder="Search for labels"
        ref="labelsSearchInput"
        @update:modelValue="(value) => updateSearchQuery(value)"
      >
        <template #prefix>
          <Search class="text-imperium-fg-muted" />
        </template>
        <template #suffix> <span class="text-imperium-fg-muted">⌘+K</span> </template>
      </FormInput>
      <div>
        <Button
          :size="SIZES.MEDIUM"
          data-testid="create-new-role-modal-show"
          @click="goToLabelCreate"
        >
          <template #leftIcon>
            <PlusIcon class="w-5 h-5" />
          </template>

          New label
        </Button>
      </div>
    </div>

    <LabelsTable @redirect="goToLabelEdit" />
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import Button from '@/components/Button.vue';
import { SIZES } from '@/types';

import PlusIcon from '@/assets/icons/plus.svg?component';
import Search from '@/assets/icons/search.svg?component';

import FormInput from '@/components/FormInput.vue';
import LabelsTable from '@/features/Labels/components/LabelsTable.vue';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { debounce } from 'lodash';
import { useLabelsFilters } from '@/features/Labels/stores/filters.store.ts';

const router = useRouter();

const goToLabelEdit = (roleId: number) => {
  router.push({
    name: 'labels-edit',
    params: {
      id: roleId,
    },
  });
};

const goToLabelCreate = () => {
  router.push({
    name: 'labels-create',
  });
};

const filtersStore = useLabelsFilters();
const labelsSearchInput = ref<HTMLElement | null>(null);

const searchQuery = computed(() => filtersStore.state?.searchQuery || '');
const updateSearchQuery = debounce((searchQuery: string) => {
  filtersStore.setSearchQuery(searchQuery);
}, 300);

const handleKeyDown = (event) => {
  if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
    event.preventDefault();
    if (labelsSearchInput.value) {
      labelsSearchInput.value.focus();
    }
  }
};

onMounted(() => {
  window.addEventListener('keydown', handleKeyDown);
});
onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeyDown);
});
</script>
