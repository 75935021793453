import { DEFAULT_LANGUAGE_ID } from '@/constants/queries.ts';
import { useRouter } from 'vue-router';
import { useLanguagesStore } from '@/stores/languages.store.ts';
import { type Language } from '@/types';
import { useArticleCurrentLanguageStore } from '@/stores/article-current-language.ts';

export interface ArticleEditRouteParams {
  id: number;
  languageId?: number;
  language?: number;
}

const setArticleLanguageStore = (articleLanguage: number) => {
  const languagesStore = useLanguagesStore();
  const currentLanguage = useArticleCurrentLanguageStore();
  const languageFull = languagesStore.state.find((lang: Language) => lang.id === articleLanguage);
  currentLanguage.updateLanguage(languageFull);
};

const getArticleEditNamedRoute = (article: ArticleEditRouteParams) => {
  setArticleLanguageStore(article.language ?? article.languageId);
  return {
    name: 'article',
    params: {
      id: article.id,
    },
    query: {
      language: article.language || article.languageId || DEFAULT_LANGUAGE_ID,
    },
  };
};

export const useArticleRouting = () => {
  const router = useRouter();

  const getArticleEditHref = (article: ArticleEditRouteParams) => {
    const namedRoute = router.resolve(getArticleEditNamedRoute(article));
    return namedRoute.href;
  };

  const goToArticleEdit = async (article: ArticleEditRouteParams) => {
    await router.push(getArticleEditNamedRoute(article));
  };

  return { goToArticleEdit, getArticleEditHref };
};
