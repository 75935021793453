<script setup lang="ts">
import { useCopyToClipboard } from '@/helpers/copy-to-clipboard/useCopyToClipboard';
import { BUTTON_TYPE, SIZES } from '@/types';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store';
import Tooltip from '@/components/Tooltip.vue';
import SwipeIcon from '@/assets/icons/swipe.svg?component';
import CopyIcon from '@/assets/icons/copy.svg?component';
import { useDocumentStatus } from '@/features/ArticleLayout/composables';
import ArticleStatus from '@/features/ArticleLayout/components/ArticleStatus.vue';
import Button from '@/components/Button.vue';
import { inject } from 'vue';

const props = defineProps<{
  isOpened: boolean;
}>();

const emits = defineEmits<{
  (event: 'open'): void;
  (event: 'hide'): void;
}>();
const clipboardService = useCopyToClipboard();
const articleStore = useArticleStore();
const { documentStatus } = useDocumentStatus();

const isMobile = inject<boolean>('isMobile');

const copyToClipboard = async () => {
  const title = document.createElement('h1');
  title.textContent = articleStore.state.title;
  const dek = document.createElement('p');
  dek.textContent = articleStore.state.dek;
  const text = `${title.outerHTML} ${dek.outerHTML} ${articleStore.state.fulltext}`;
  await clipboardService(text);
};

const onToggleSidebar = () => {
  if (props.isOpened) {
    emits('hide');
    return;
  }

  emits('open');
};
</script>

<template>
  <header class="flex items-center mb-4 justify-between">
    <div class="flex gap-2 items-center">
      <Button
        v-if="isMobile && props.isOpened"
        :visual-type="BUTTON_TYPE.GHOST"
        :size="SIZES.SMALL"
        class="flex lg:hidden mr-2"
        @click="onToggleSidebar"
      >
        <template #leftIcon>
          <SwipeIcon class="w-6 h-6 text-imperium-fg-muted" />
        </template>
      </Button>

      <h2 class="font-semibold text-lg text-imperium-fg-strong">Metadata</h2>
    </div>
    <div class="flex gap-2 items-center">
      <Tooltip
        :key="`tooltip-info`"
        width="unset"
        name="copy-info"
        placement="bottom"
        class="ml-auto"
      >
        <template #content>
          <Button
            :visual-type="BUTTON_TYPE.GHOST"
            :size="SIZES.SMALL"
            @click="copyToClipboard"
          >
            <CopyIcon />
          </Button>
        </template>
        <template #tooltip>
          <div class="text-sm whitespace-nowrap">Copy text</div>
        </template>
      </Tooltip>

      <ArticleStatus
        class="ml-1 h-fit"
        :status="articleStore.state.status"
        no-wrap
        :published-at="articleStore.state.publishedAt"
      />
    </div>
  </header>
</template>

<style scoped lang="scss"></style>
