<template>
  <ModalHolder
    :is-visible="props.isVisible"
    :size="SIZES.MEDIUM"
    data-testid="related-article-widget-modal"
    @close="onClose"
  >
    <template #title>Article widget</template>
    <div class="flex flex-col">
      <div class="flex flex-col mt-2">
        <FormSelect
          :values="articles"
          name="article"
          placeholder="Start typing the article title"
          full-select-response
          :is-loading="isLoading"
          :model-value="selectedArticleId"
          @update:model-value="onUpdateSelectedArticle"
          @search="onSearchUpdate"
        />
        <Button
          :size="SIZES.MEDIUM"
          class="mt-4"
          is-full-width
          @click="onInsertWidget"
        >
          Insert article widget
        </Button>
      </div>
    </div>
  </ModalHolder>
</template>

<script setup lang="ts">
import { SIZES } from '@/types';
import ModalHolder from '@/components/ModalHolder.vue';
import FormSelect from '@/components/FormSelect.vue';
import { computed, ref } from 'vue';
import { debounce } from 'lodash';
import Button from '@/components/Button.vue';
import { fetchPosts } from '@/features/Posts/api';
import type { Post } from '@/features/Posts/types';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';

const props = withDefaults(
  defineProps<{
    isVisible?: boolean;
  }>(),
  {
    isVisible: false,
  },
);

const emits = defineEmits<{
  (event: 'onClose'): void;
  (event: 'insert', value: Post): void;
}>();

const currentLanguageStore = useCurrentLanguageStore();
const languageId = computed(() => currentLanguageStore.currentLanguage.id);

const selectedArticleId = ref<number | null>(0);
const search = ref<string>('');
const articles = ref<Post[]>([]);
const isLoading = ref<boolean>(false);

const postsParams = computed(() => {
  return {
    languageId: languageId.value,
    itemsPerPage: 20,
    pagination: false,
    page: 1,
  };
});
const getArticles = async () => {
  if (!search.value) return [];
  isLoading.value = true;

  articles.value = (
    await fetchPosts({
      ...postsParams.value,
      'filter[search]': search.value,
    })
  ).items.map((item: Post) => {
    return {
      ...item,
      label: item.title,
      id: item.id,
    };
  });
  isLoading.value = false;
};

const getArticlesWithDebounced = debounce(() => {
  getArticles();
}, 800);

const onInsertWidget = () => {
  if (selectedArticleId.value) {
    const article = articles.value?.find((item: Post) => item.id === selectedArticleId.value);
    emits('insert', article);
    clearModalData();
  }
};

const onSearchUpdate = (val: string): void => {
  search.value = val;
  getArticlesWithDebounced();
};

const onUpdateSelectedArticle = (id: number): void => {
  selectedArticleId.value = id;
};

const clearModalData = (): void => {
  selectedArticleId.value = null;
  search.value = '';
  articles.value = [];
};

const onClose = (): void => {
  clearModalData();
  emits('onClose');
};
</script>
