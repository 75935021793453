import { useQuery } from '@tanstack/vue-query';
import type { MaybeRef } from 'vue';
import { LABEL_RESOURCE } from '@/features/Labels/constants';
import { fetchLabel } from '@/features/Labels/api';
import type { LabelFetchParam } from '@/features/Labels/types';

export const useFetchLabel = (params: LabelFetchParam) => {
  const { data, isLoading } = useQuery({
    queryKey: [LABEL_RESOURCE, params],

    queryFn: async ({ queryKey }) => {
      const payload: LabelFetchParam = queryKey[1] as LabelFetchParam;

      const result = await fetchLabel(payload);
      return result;
    },

    gcTime: 0,
    staleTime: 0,
    refetchInterval: false,
    refetchOnMount: 'always',
    enabled: !!params.labelId,
  });

  return {
    data,
    isLoading,
  };
};
