<template>
  <FormSearchMultiDropdown
    :model-value="selectedLabels"
    :name="props.name"
    is-handle-search-input
    class="mb-2"
    :attrs="props.attrs"
    :is-loading="!inited || isLoading"
    :is-disabled="!inited || isDisabled"
    placeholder="Type to search"
    :return-full-value="true"
    :values="filteredLabels"
    :is-errored="props.isErrored"
    @update:model-value="handleUpdate"
    @search="
      (searchString: string) => {
        isLoading = true;
        debouncedOnSearch(searchString);
      }
    "
  >
    <template #label>
      <slot name="label" />
    </template>

    <template #error>
      <slot name="error" />
    </template>
    <template #help>
      <slot name="help" />
    </template>
  </FormSearchMultiDropdown>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { fetchLabels } from '@/features/Labels/api';
import FormSearchMultiDropdown, { type SearchMultidropdownItem } from '@/components/FormSearchMultiDropdown.vue';
import { debounce } from 'lodash';
import type { LabelsFetchRequest, LabelTranslate } from '@/features/Labels/types';
import { LABEL_FIELD_ID, LABEL_FIELD_NAME } from '@/features/Labels/constants';
import { DEFAULT_LANGUAGE_ID } from '@/constants/queries';

interface Props {
  modelValue: number[];
  attrs: Record<string, unknown>;
  isErrored: boolean;
  isDisabled: boolean;
  name: string;
}

const props = withDefaults(defineProps<Props>(), {
  name: 'labels-multi-dropdown',
});

const emits = defineEmits<{
  (event: 'update:model-value', value: number[] | SearchMultidropdownItem[]): void;
}>();

const labels = ref<SearchMultidropdownItem[]>([]);
const isLoading = ref<boolean>(false);
const inited = ref<boolean>(false);

const filteredLabels = computed(() => {
  return labels.value.filter((label) => !props.modelValue?.includes(label.id));
});

const selectedLabels = computed(() => {
  if (!props.modelValue) {
    return [];
  }
  return labels.value.filter((label) => props.modelValue.includes(label.id));
});

const onSearch = async (searchString: string) => {
  const payload: LabelsFetchRequest = {
    pagination: false,
    languageId: DEFAULT_LANGUAGE_ID,
  };

  if (searchString) {
    payload['filter[search]'] = searchString;
  }

  try {
    const data = await fetchLabels(payload);
    const fetchedLabels =
      data?.items.map((labelTranslate: LabelTranslate) => ({
        id: labelTranslate[LABEL_FIELD_ID],
        label: labelTranslate[LABEL_FIELD_NAME],
      })) || [];

    const uniqueLabels = new Map<number, SearchMultidropdownItem>();
    for (const label of [...fetchedLabels, ...selectedLabels.value]) {
      uniqueLabels.set(label.id, label);
    }
    labels.value = Array.from(uniqueLabels.values());
  } catch (e) {
    console.error(e);
  } finally {
    isLoading.value = false;
  }
};

const handleUpdate = (values: SearchMultidropdownItem[]) => {
  const modelValue = Array.from(new Set(values.map(({ id }) => id)));
  emits('update:model-value', modelValue);
};

const debouncedOnSearch = debounce(onSearch, 500);

const initLabels = async () => {
  await onSearch('');
  inited.value = true;
};
onMounted(initLabels);
</script>
