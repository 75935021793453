<script setup lang="ts">
import TranslateDocument from '@/features/ArticleLayout/components/TranslateDocument.vue';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store.ts';
import { useUserPermissions } from '@/stores/user.store.ts';
import ChangeArticleLanguageDropdown from '@/features/ArticleLayout/components/ChangeArticleLanguageDropdown.vue';

const props = defineProps<{
  languageId: number;
}>();
const articleStore = useArticleStore();

const { documentTranslateView, documentTranslateEdit } = useUserPermissions();
</script>

<template>
  <div class="py-2 flex gap-2 justify-between">
    <ChangeArticleLanguageDropdown :document-language-id="props.languageId" />
    <TranslateDocument
      v-if="documentTranslateView"
      :document-id="articleStore.state.id"
      :document-language-id="props.languageId"
      :is-disabled="!documentTranslateEdit"
    />
  </div>
</template>

<style scoped lang="scss"></style>
