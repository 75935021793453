<template>
  <div>
    <div class="flex gap-2 my-4 items-center justify-end">
      <Button
        :color="BUTTON_COLOR.DEFAULT"
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.TERTIARY"
        @click="isInfoModalVisible = true"
      >
        Cancel
      </Button>
      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.PRIMARY"
        @click="onSubmit"
      >
        <template v-if="isEditing"> Save changes </template>
        <template v-else> Create a new label </template>
      </Button>
    </div>

    <section class="bg-imperium-bg-sub-base p-4 sm:rounded-2xl">
      <TitleHelper
        title="General settings"
        badge-number="1"
        description="Customize label."
      />
      <div class="flex flex-col mt-4 w-full gap-4">
        <div class="flex w-full gap-4">
          <FormInput
            v-model="title"
            :attrs="titleAttrs"
            :is-errored="!!errors[LABEL_FIELD_NAME]"
            class="w-full"
            placeholder="Enter label name"
          >
            <template #label> Label name * </template>
            <template #error>{{ errors[LABEL_FIELD_NAME] }}</template>
          </FormInput>
          <FormSelect
            v-model="labelType"
            name="label-type"
            :values="LABEL_TYPE_LIST"
            placeholder="Select label type"
            class="w-full"
          >
            <template #label> Label type</template>
          </FormSelect>
        </div>
        <div class="">
          <FormCheckbox
            v-model="enabled"
            class="mt-4"
            name="category-select"
            :size="SIZES.SMALL"
          >
            <p class="text-xs mt-1 text-imperium-fg-muted">The label will only be visible in the assigned category.</p>
          </FormCheckbox>
        </div>
      </div>
    </section>

    <Teleport to="#modals">
      <InfoModal
        v-if="isInfoModalVisible"
        :is-visible="isInfoModalVisible"
        @close="closeModal"
        @closeByCross="isInfoModalVisible = false"
        @save="onSave"
      />
    </Teleport>
  </div>
</template>
<script setup lang="ts">
import TitleHelper from '@/components/TitleHelper.vue';
import FormInput from '@/components/FormInput.vue';
import { labelFormTypedSchema } from '@/features/Labels/validators';
import { useFormData, useToast } from '@/composables';
import { computed, onMounted, ref } from 'vue';
import { Label } from '@/features/Labels/types';
import {
  LABEL_FIELD_ENABLED,
  LABEL_FIELD_ID,
  LABEL_FIELD_NAME,
  LABEL_FIELD_TYPE,
  LABEL_TYPE_LIST,
} from '@/features/Labels/constants';
import { useRouter } from 'vue-router';
import { useUpdateLabel } from '@/features/Labels/queries/useMutateLabel';
import { BUTTON_COLOR, BUTTON_TYPE, SIZES } from '@/types';
import Button from '@/components/Button.vue';
import FormCheckbox from '@/components/FormCheckbox.vue';
import FormSelect from '@/components/FormSelect.vue';
import InfoModal from '@/features/Labels/InfoModal.vue';

interface Props {
  data: Label | null;
}

const props = withDefaults(defineProps<Props>(), {
  data: null,
});

const toast = useToast();
const router = useRouter();

const isInfoModalVisible = ref<boolean>(false);

const { defineField, resetForm, handleSubmit, errors } = useFormData({
  data: props.data,
  validator: labelFormTypedSchema,
});

const isEditing = computed(() => !!props.data);

const { mutate } = useUpdateLabel({
  onSuccess: (label) => {
    toast.success({
      id: 'LABEL_DATA_SAVE_SUCCESS',
      message: isEditing.value ? 'Label edited successfully.' : 'Label was created.',
    });

    router.push({
      path: '/labels',
    });
  },
  onError: ({ data }) => {
    const { errorMessage, errorCode } = data;

    toast.errorTemporary({
      id: errorCode,
      message: errorMessage,
    });
  },
});

const [title, titleAttrs] = defineField(LABEL_FIELD_NAME);
const [labelType] = defineField(LABEL_FIELD_TYPE);
const [enabled] = defineField(LABEL_FIELD_ENABLED);

const onSubmit = handleSubmit(
  (values) => {
    mutate({ label: values, languageId: 1, labelId: props?.data?.[LABEL_FIELD_ID] || null });
  },
  () => {
    toast.errorTemporary({
      id: 'ERROR_VALIDATION',
      message: 'All mandatory fields must be filled in to move the article to another status.',
    });
  },
);

const onSave = () => {
  isInfoModalVisible.value = false;
  onSubmit();
};

const closeModal = () => {
  isInfoModalVisible.value = false;
  router.push({
    path: '/labels',
  });
};

onMounted(async () => {
  if (props.data) {
    resetForm({ values: props.data ?? {} });
  }
});
</script>
