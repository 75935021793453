<template>
  <section>
    <h3 class="font-semibold text-base text-imperium-fg-base mb-2">SEO</h3>

    <Tooltip
      :key="`tooltip-seo-desc`"
      width="unset"
      name="seo-desc-info"
      placement="auto"
      :tooltip-visible="!documentMetadataSeoDescEdit && !documentMetadataSeoDescConditions"
      button-class="w-full text-left"
    >
      <template #content>
        <FormTextarea
          v-if="documentMetadataSeoDescView"
          :model-value="description"
          name="description"
          class="mb-2"
          placeholder="Write a lead text that will be displayed on search"
          :attrs="descriptionAttrs"
          :max="MAX_SEO_DESCRIPTION_LENGTH"
          :is-disabled="
            (!documentMetadataSeoDescEdit && !documentMetadataSeoDescConditions) ||
            !userStore.hasAccessToCurrentLanguage()
          "
          :is-errored="isMetaDescriptionErrored"
          is-required
          :size="SIZES.SMALL"
          @update:model-value="(value) => emits('update:description', value)"
        >
          <template #label>
            <span class="text-xs">Meta Description</span>
          </template>
          <template #error>{{ errors.seoMetaDescription }}</template>
        </FormTextarea>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>
  </section>
</template>

<script lang="ts" setup>
import FormTextarea from '@/components/FormTextarea.vue';

import { SIZES } from '@/types';
import { MAX_SEO_DESCRIPTION_LENGTH } from '../constants';
import { useUserPermissions, useUserStore } from '@/stores/user.store';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store';
import { computed } from 'vue';
import { useUserConditions } from '@/composables/useUserConditions.ts';
import Tooltip from '@/components/Tooltip.vue';

defineProps<{
  descriptionAttrs: Record<string, unknown>;
  isMetaDescriptionErrored: boolean;
  errors: Record<string, unknown>;
}>();

const emits = defineEmits<{
  (event: 'update:description', value: string): void;
}>();

const { documentMetadataSeoDescView, documentMetadataSeoDescEdit } = useUserPermissions();

const userStore = useUserStore();
const articleStore = useArticleStore();

const description = computed(() => articleStore.state.seoMetaDescription);

const errors = computed(() => articleStore.state.errors);
const isMetaDescriptionErrored = computed(() => !!errors.value.seoMetaDescription);

const { documentMetadataSeoDescConditions } = useUserConditions();
</script>
