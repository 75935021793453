<template>
  <div
    class="space-y-3"
    :class="{ 'flex justify-between items-center space-y-0': !isMobile }"
  >
    <PaginationInfo
      v-if="props.isInfoShown"
      :offset="(currentPage - 1) * limit"
      :limit="limit"
      :items-count="itemsCount"
    />
    <nav v-if="pagesCount > 1 && props.itemsCount > 0">
      <ul class="inline-flex -space-x-px text-sm sm:text-base h-10">
        <li>
          <button
            :disabled="isPreviousButtonDisabled"
            data-testid="pagination-left-button"
            class="flex disabled:bg-imperium-bg-sub-base disabled:text-imperium-fg-subtle items-center justify-center px-2 h-10 ms-0 leading-tight text-gray-500 bg-imperium-bg-sub-base border border-e-0 border-imperium-border-base rounded-s-lg hover:bg-imperium-bg-2 hover:text-imperium-fg-strong"
            @click="!isPreviousButtonDisabled && changePage(props.currentPage - 1)"
          >
            <ArrowLeft class="w-6 h-6" />
          </button>
        </li>
        <li v-if="props.currentPage > minPagesLeft - 1">
          <button
            data-testid="pagination-first-page"
            class="flex text-imperium-fg-base font-semibold bg-imperium-bg-sub-base hover:bg-imperium-bg-2 hover:text-imperium-fg-strong items-center justify-center px-3 min-w-10 h-10 leading-tight border border-imperium-border-base"
            @click="!isPreviousButtonDisabled && changePage(1)"
          >
            1
          </button>
        </li>
        <li v-if="showLeftMultiplicity">
          <div
            data-testid="pagination-left-multiplicity"
            class="flex items-center justify-center px-3 min-w-10 h-10 cursor-default leading-tight text-imperium-fg-subtle bg-imperium-bg-sub-base border border-imperium-border-base"
          >
            ...
          </div>
        </li>
        <li
          v-for="page in showingPages"
          :key="page"
        >
          <button
            :data-testid="`pagination-page-${page}${currentPage === page ? '-active' : ''}`"
            :class="
              currentPage === page
                ? 'cursor-default bg-imperium-ds-primary-base hover:bg-imperium-ds-primary-base text-imperium-ds-base-black'
                : 'text-imperium-fg-base bg-imperium-bg-sub-base hover:bg-imperium-bg-2 hover:text-imperium-fg-strong'
            "
            class="flex items-center min-w-10 font-semibold justify-center px-4 h-10 leading-tight border border-imperium-border-base"
            @click="changePage(page)"
          >
            {{ page }}
          </button>
        </li>
        <li v-if="showRightMultiplicity">
          <div
            data-testid="pagination-right-multiplicity"
            class="flex items-center justify-center px-3 min-w-10 h-10 cursor-default leading-tight text-imperium-fg-subtle bg-imperium-bg-sub-base border border-imperium-border-base"
          >
            ...
          </div>
        </li>
        <li v-if="props.currentPage < pagesCount - visiblePages">
          <button
            data-testid="pagination-last-page"
            class="flex text-imperium-fg-base font-semibold bg-imperium-bg-sub-base hover:bg-imperium-bg-2 hover:text-imperium-fg-strong items-center justify-center px-3 min-w-10 h-10 leading-tight border border-imperium-border-base"
            @click="!isNextButtonDisabled && changePage(pagesCount)"
          >
            {{ pagesCount }}
          </button>
        </li>
        <li>
          <button
            :disabled="isNextButtonDisabled"
            data-testid="pagination-right-button"
            class="flex disabled:bg-imperium-bg-sub-base disabled:text-imperium-fg-subtle items-center justify-center px-2 h-10 leading-tight text-gray-500 bg-imperium-bg-sub-base border border-imperium-border-base rounded-e-lg hover:bg-imperium-bg-2 hover:text-imperium-fg-strong"
            @click="!isNextButtonDisabled && changePage(props.currentPage + 1)"
          >
            <ArrowRight class="w-6 h-6" />
          </button>
        </li>
      </ul>
    </nav>
    <PaginationLimit
      v-if="isLimitVisible && props.itemsCount > 10"
      id="users-pagination"
      :limit="limit"
      :limits="props.limits"
      @change-limit="emits('change-limit', $event)"
    />
  </div>
</template>
<script setup lang="ts">
import ArrowLeft from '@/assets/icons/arrow-left.svg?component';
import ArrowRight from '@/assets/icons/arrow-right.svg?component';
import PaginationLimit from '@/components/Table/Pagination/PaginationLimit.vue';
import PaginationInfo from '@/components/Table/Pagination/PaginationInfo.vue';
import { computed, ref, watchEffect } from 'vue';

const FIRST_PAGE = 1;
const VISIBLE_PAGES_AROUND_CURRENT = 2;
const VISIBLE_PAGES_AROUND_CURRENT_MOBILE = 1;
const MIN_PAGES_FOR_SHORTING = 4;
const MIN_PAGES_FOR_SHORTING_MOBILE = 3;
const RIGHT_MIN_PAGES_FOR_SHORING = 3;
const RIGHT_MIN_PAGES_FOR_SHORING_MOBILE = 2;

const props = withDefaults(
  defineProps<{
    currentPage: number;
    itemsCount: number;
    limit: number;
    isMobile?: boolean;
    isLimitVisible?: boolean;
    limits?: number[];
    isInfoShown?: boolean;
  }>(),
  {
    currentPage: 1,
    itemsCount: 0,
    limit: 10,
    isMobile: false,
    isLimitVisible: true,
    isInfoShown: true,
  },
);

const emits = defineEmits<{
  (event: 'change-limit', value: number): void;
  (event: 'change-page', value: number): void;
}>();

const pagesCount = ref<number>(1);
const showingPages = ref<number[]>([]);
const showLeftMultiplicity = ref<boolean>(false);
const showRightMultiplicity = ref<boolean>(false);

const visiblePages = computed(() =>
  props.isMobile ? VISIBLE_PAGES_AROUND_CURRENT_MOBILE : VISIBLE_PAGES_AROUND_CURRENT,
);
const minPagesLeft = computed(() => (props.isMobile ? MIN_PAGES_FOR_SHORTING_MOBILE : MIN_PAGES_FOR_SHORTING));
const minPagesRight = computed(() =>
  props.isMobile ? RIGHT_MIN_PAGES_FOR_SHORING_MOBILE : RIGHT_MIN_PAGES_FOR_SHORING,
);

watchEffect(() => {
  pagesCount.value = Math.ceil(props.itemsCount / props.limit);

  showLeftMultiplicity.value = props.currentPage > minPagesLeft.value && pagesCount.value > minPagesLeft.value;
  showRightMultiplicity.value =
    props.currentPage < pagesCount.value - minPagesRight.value && pagesCount.value > visiblePages.value;
  const startPage = Math.max(props.currentPage - visiblePages.value, FIRST_PAGE);
  const endPage = Math.min(props.currentPage + visiblePages.value, pagesCount.value);
  showingPages.value = [...Array.from({ length: endPage - startPage + 1 }, (_, i) => i + startPage)];
});

const changePage = (page) => {
  if (page !== props.currentPage) {
    emits('change-page', page);
  }
};

const isPreviousButtonDisabled = computed(() => props.currentPage === FIRST_PAGE);
const isNextButtonDisabled = computed(() => props.currentPage === pagesCount.value);
</script>
