import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "22",
  fill: "currentColor",
  viewBox: "0 0 20 22"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#303D43",
      "fill-rule": "evenodd",
      d: "M.409 12a.35.35 0 0 1-.33-.464L3.955.336A.5.5 0 0 1 4.43 0h2.104a.5.5 0 0 1 .472.336l3 8.664H3.18l-.9 2.596a.6.6 0 0 1-.567.404zM5.48 1.8l.154.997L7.091 7H3.87l1.456-4.203z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#303D43",
      d: "M12.51 8.296a.5.5 0 0 1 .65.278l.63 1.572a.5.5 0 0 1-.279.65l-.51.204h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-.852c-.674 1.443-1.992 2.94-3.492 4.256a40 40 0 0 0 3.442 2.38c.236.144.317.45.178.689a3.3 3.3 0 0 1-.48.622.6.6 0 0 1-.718.098c-.887-.459-3.046-1.606-4.079-2.417-1.03.809-3.067 1.951-3.91 2.413a.65.65 0 0 1-.722-.056c-.234-.19-.522-.45-.644-.66a.51.51 0 0 1 .178-.688c1.088-.663 2.43-1.5 3.577-2.381-1.16-1.017-2.186-2.039-3.055-3.158l2.162-.356c.414.47 1.186 1.126 1.918 1.748q.256.215.496.423c1.103-.947 2.09-1.928 3.035-2.913H7a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 7 11h4.877l-.037-.074-.629-1.572a.5.5 0 0 1 .279-.65z"
    }, null, -1)
  ])))
}
export default { render: render }