<template>
  <div
    ref="tooltipButton"
    :data-testid="`tooltip-button-${props.name}`"
    :class="props.buttonClass"
    type="button"
  >
    <slot name="content" />
  </div>

  <template ref="tooltipContent">
    <div
      v-if="props.tooltipVisible"
      :data-testid="`tooltip-content-${props.name}`"
      role="tooltip"
      :style="{ 'max-width': props.width }"
      class="shadow-lg z-[1000] inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg tooltip"
    >
      <slot
        v-if="props.tooltipVisible"
        name="tooltip"
      />
    </div>
  </template>
</template>

<script setup lang="ts">
import { onMounted, ref, inject, type Ref, watch } from 'vue';
import { useTippy } from 'vue-tippy';
import { type Placement } from 'tippy.js';

const props = withDefaults(
  defineProps<{
    name: string;
    placement?: Placement;
    width?: string;
    triggerType?: string;
    buttonClass?: string;
    tooltipVisible?: boolean;
  }>(),
  {
    placement: 'top',
    width: '220px',
    triggerType: 'mouseenter',
    buttonClass: '',
    tooltipVisible: true,
  },
);

const isTablet = inject<Ref<boolean>>('isTablet') as Ref<boolean>;

const tooltipButton = ref<Element>();
const tooltipContent = ref<Element>();

const { setContent, disable, enable } = useTippy(tooltipButton, {
  allowHTML: true,
  arrow: false,
  delay: 0,
  duration: 0,
  placement: props.placement,
  trigger: props.triggerType,
  moveTransition: 'transform 50ms ease-out',
});

watch(
  isTablet,
  (value) => {
    if (value) {
      disable();
      return;
    }

    enable();
  },
  { immediate: true },
);

watch(
  () => props.tooltipVisible,
  (value) => {
    if (!value) {
      disable();
    } else {
      enable();
    }
  },
  { immediate: true },
);

onMounted(() => {
  setContent(tooltipContent.value?.children[0]?.outerHTML ?? '');

  if (isTablet?.value) {
    disable();
  }
});
</script>
