import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';

import { useFetchDocument } from '../queries';
import { DEFAULT_LANGUAGE_ID } from '@/constants/queries.ts';

export const useCurrentDocument = () => {
  const route = useRoute();

  const languageId = computed(() => (route.query?.language ? +route.query.language : DEFAULT_LANGUAGE_ID));
  const documentId = computed(() => route.params.id);

  const {
    data,
    isError,
    isPending,
    isSuccess,
    isLoading: isDocumentLoading,
  } = useFetchDocument({
    documentId: computed(() => route.params.id),
    languageId,
    enabled: computed(() => !!languageId.value && !!route.params.id),
  });

  const isLoading = computed(() => isDocumentLoading.value);

  let documentResolve: ((value: unknown) => void) | null = null;

  const documentPromise = new Promise((resolve) => {
    documentResolve = resolve;
  });

  watch(
    () => [isSuccess, isDocumentLoading],
    ([isDocumentSuccess, isDocumentLoading]) => {
      if (!isDocumentSuccess.value || isDocumentLoading.value || isPending.value || documentResolve === null) {
        return;
      }

      documentResolve(isDocumentSuccess);
    },
    { immediate: true, deep: true },
  );

  return {
    data,
    isError,
    isPending,
    isLoading,

    languageId,
    documentId,

    documentPromise,
  };
};
