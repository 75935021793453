<template>
  <article
    data-testid="dashboard-table-published"
    class="bg-imperium-bg-sub-base shadow-imperium-base rounded-2xl overflow-x-hidden sm:overflow-x-none w-full sm:w-auto"
  >
    <header class="flex items-center gap-2 px-2 sm:px-4 py-4">
      <StatsIcon :theme="THEMES.SUCCESS">
        <FileSuccessIcon class="w-5 h-5" />
      </StatsIcon>

      <h3 class="text-gray-900 font-semibold text-md sm:text-xl leading-none">{{ t('dashboard.tables.published') }}</h3>
      <Button
        v-if="props.values?.total"
        data-testid="dashboard-table-published-button"
        :visual-type="BUTTON_TYPE.GHOST"
        :size="SIZES.SMALL"
        class="ml-auto whitespace-nowrap"
        @click="onViewAllClick"
      >
        {{ t('common.view-all', { count: props.values?.total || 0 }) }}
      </Button>
    </header>

    <div
      v-if="items?.length"
      class="relative overflow-x-auto"
    >
      <Table
        class="min-w-[580px] sm:min-w-0"
        is-rounded
      >
        <TableHead>
          <TableHeadRow
            class="min-h-[48px] h-[48px] uppercase"
            :is-on-surface="TABLE_ON_SURFACE.PRIMARY"
          >
            <TableHeadCell
              is-uppercase
              :size="SIZES.LARGE"
            >
              {{ t('dashboard.tables.columns.published') }}
            </TableHeadCell>
            <TableHeadCell
              is-uppercase
              is-full-width
            >
              {{ t('dashboard.tables.columns.title') }}
            </TableHeadCell>
            <TableHeadCell
              is-uppercase
              :size="SIZES.LARGE"
            >
              {{ t('dashboard.tables.columns.category') }}
            </TableHeadCell>
            <TableHeadCell
              v-if="isDashboardFull"
              is-uppercase
            >
              {{ t('dashboard.tables.columns.views') }}
            </TableHeadCell>
            <TableHeadCell
              is-uppercase
              :align="TABLE_ALIGN.RIGHT"
            >
              {{ t('dashboard.tables.columns.words') }}
            </TableHeadCell>
          </TableHeadRow>
        </TableHead>

        <TableBody>
          <TableRow
            v-for="(row, index) in items"
            :key="`table-05-${index}`"
            class="cursor-pointer"
            :striped="TABLE_STRIPED.STRIPED"
            :bordered="TABLE_BORDERED.BOTTOM"
            @click="emits('redirect', row.id, row.language, $event)"
          >
            <TableCell :visual-type="TABLE_VISUAL_TYPE.SUBTLE">
              <template v-if="row.publishedAt">
                <span class="text-imperium-fg-strong">
                  {{ getFormattedDate(row.publishedAt) }}
                </span>
                <br />
                <span class="text-imperium-fg-muted">
                  {{ getFormattedTime(row.publishedAt) }}
                </span>
              </template>
            </TableCell>
            <TableCell>
              <div
                v-if="row.title"
                class="overflow-hidden text-ellipsis whitespace-pre-wrap break-words font-semibold text-imperium-fg-strong"
              >
                {{ row.title }}
              </div>
              <div
                v-else
                class="text-imperium-fg-muted"
              >
                No title
              </div>
            </TableCell>
            <TableCell :visual-type="TABLE_VISUAL_TYPE.SUBTLE">
              {{ row.category.title }}
            </TableCell>
            <TableCell
              v-if="isDashboardFull"
              :visual-type="TABLE_VISUAL_TYPE.SUBTLE"
              :align="TABLE_ALIGN.RIGHT"
              class="text-imperium-fg-base"
            >
              {{ row?.views }}
            </TableCell>
            <TableCell
              :visual-type="TABLE_VISUAL_TYPE.SUBTLE"
              :align="TABLE_ALIGN.RIGHT"
            >
              {{ row.words || 0 }}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
    <div
      v-else
      class="h-22 flex items-center justify-center mb-4 text-imperium-fg-muted"
    >
      <div v-if="props.isLoading">
        <div class="h-10 w-10">
          <LoaderIcon class="w-10 h-10" />
        </div>
      </div>
      <div v-else>No articles right now</div>
    </div>
  </article>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

import LoaderIcon from '@/assets/icons/spinner.svg?component';
import FileSuccessIcon from '@/assets/icons/file-success.svg?component';
import Button from '@/components/Button.vue';
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableHeadRow, TableRow } from '@/components/Table';
import StatsIcon from './StatsIcon.vue';

import {
  BUTTON_TYPE,
  DocumentStatus,
  SIZES,
  TABLE_ALIGN,
  TABLE_BORDERED,
  TABLE_ON_SURFACE,
  TABLE_STRIPED,
  TABLE_VISUAL_TYPE,
  THEMES,
} from '@/types';
import { type Article, type ArticlesList } from '@/features/Articles/types';
import { getDateByUTC, getFormattedDate, getFormattedTime } from '@/utils';
import { useArticlesFilters } from '@/features/Articles/stores/filters.store.ts';
import { useRouter } from 'vue-router';
import { ARTICLE_FLAGS } from '@/features/Articles/constants.ts';

const props = defineProps<{
  values: ArticlesList;
  isLoading?: boolean;
}>();

const emits = defineEmits<{
  (event: 'redirect', id: number, languageId: number, pointerEvent: Event): void;
}>();

const router = useRouter();

const filtersStore = useArticlesFilters();

const oneDayAgo = new Date().getTime() - 24 * 60 * 60 * 1000;
const now = new Date().getTime();
const isDashboardFull = inject('isDashboardFull');
const { t } = useI18n();

const items = computed<Article[]>(() => {
  return props.values?.items?.filter((item) => {
    if (!item.publishedAt) {
      return false;
    }
    const publishedAt = new Date(item.publishedAt).getTime();
    return publishedAt >= oneDayAgo && publishedAt <= now;
  });
});

const onViewAllClick = () => {
  const tsYesterday = new Date(Date.now() - 86400 * 1000).toISOString();
  filtersStore.resetFilters();
  filtersStore.setFilters({
    publishedFrom: tsYesterday ?? '',
    publishedTo: new Date().toISOString() ?? '',
    status: [DocumentStatus.PUBLISHED],
  });
  router.push({
    path: '/articles',
  });
};
</script>
