import axios, { AxiosError, type CancelTokenSource } from 'axios';
import axiosRetry from 'axios-retry';
import { apiGatewayUrl } from '@/config';
import { HttpException } from '@/exceptions/HttpException';
import { AuthService } from '@/features/Auth/service';
import { useUserPermissions, useUserStore } from '@/stores/user.store.ts';
import { fetchUser } from '@/features/Auth/api/auth.ts';
import * as Sentry from '@sentry/browser';

interface Violation {
  propertyPath: string;
  title: string;
}

export interface RequestErrors {
  detail: string;
  title: string;
  violations: Violation[];
}

export const enum HEADERS {
  CONTENT_TYPE = 'content-type',
  USER_LOCALE = 'X-User-Locale',
}

export const http = axios.create({
  baseURL: apiGatewayUrl,
  timeout: 15000,
});

const CancelToken = axios.CancelToken;
const cancelMap = new Map<string, CancelTokenSource>();

http.interceptors.request.use((config) => {
  if (config.cancelToken) {
    return config;
  }

  const uniqueId = `${config.url}-${JSON.stringify(config.params || {})}-${JSON.stringify(config.data || {})}`;
  const source = cancelMap.get(uniqueId);

  if (source) {
    source.cancel(`Cancelled by middleware due to consecutive call on "${config.url}"`);
  }

  if (uniqueId) {
    const tokenSource = CancelToken.source();
    cancelMap.set(uniqueId, tokenSource);
    config.cancelToken = tokenSource.token;
  }

  if (!config.disableWithCredentials) {
    config.withCredentials = true;
  }

  return config;
});

http.interceptors.response.use(
  (response) => response,
  (res: AxiosError<RequestErrors>) => {
    const { response } = res;
    const requestUrl = response?.config?.url ?? '';

    if (axios.isCancel(res) || !response) {
      return Promise.reject(res);
    }

    const { data, status = 500 } = response;

    // Check for 401 status code
    if (status === 401 && !requestUrl.includes('login') && !requestUrl.includes('invite-confirm')) {
      AuthService.logout();
    }

    if (data?.violations && response.headers[HEADERS.CONTENT_TYPE] === 'application/json') {
      throw new HttpException(data.title, status);
    }

    throw response;
  },
);

axiosRetry(http, {
  retries: 5,
  retryDelay: (retryCount) => retryCount * 1000,
  retryCondition: async (error) => {
    const retryCount = error?.config?.['axios-retry']?.retryCount || 0;

    if (error.status && error.status === 403 && retryCount < 1) {
      try {
        const userPermissions = useUserPermissions();
        const userState = useUserStore();
        const user = await fetchUser();

        userPermissions.setUserPermissions(user.permissions);
        userPermissions.setUserId(user.id);
        userState.setUserState(user);

        return !!user.id;
      } catch (innerError) {
        Sentry.captureException(innerError);
        return false;
      }
    }
    // Prevent default lib retry on 4xx errors
    if (error.status === 403) {
      return false;
    }
    // By default, it retries if it is a network error or a 5xx error
    // on an idempotent request (GET, HEAD, OPTIONS, PUT or DELETE).
    // https://www.npmjs.com/package/axios-retry
    return axiosRetry.isNetworkOrIdempotentRequestError(error);
  },
});
