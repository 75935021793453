<template>
  <div>
    <LabelsForm
      v-if="!isLoading && labelId"
      :key="`edit-label-${route.params.id}`"
      :data="label"
    />
    <LabelsForm
      v-else
      key="create-label"
    />
  </div>
</template>
<script setup lang="ts">
import LabelsForm from '@/features/Labels/components/LabelsForm/LabelsForm.vue';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { useFetchLabel } from '@/features/Labels/queries/useFetchLabel.ts';
import { DEFAULT_LANGUAGE_ID } from '@/constants/queries.ts';

const route = useRoute();

const labelId = computed(() => Number(route.params.id ?? 0));

const { data: label, isLoading } = useFetchLabel({ labelId: labelId.value, languageId: DEFAULT_LANGUAGE_ID });
</script>
