import { computed, nextTick, ref } from 'vue';
import { defineStore } from 'pinia';
import type { Converter } from '@/features/Converter/types';
import { debounce } from 'lodash';
import { ConverterService } from '@/features/Converter/services';
import { useToast } from '@/composables';
import { types } from 'sass';
import Error = types.Error;
import { log } from 'util';
import { ConverterStatus } from '@/features/Converter/types';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';

interface ConverterState {
  converter: Converter;
  wasSaved: boolean;
}

export const useConverterPage = defineStore('converterPage', () => {
  const state = ref<ConverterState>({
    converter: {},
    wasSaved: true,
  });

  const toast = useToast();

  const currentLanguageStore = useCurrentLanguageStore();
  const languageId = computed(() => currentLanguageStore.currentLanguage.id);

  const updateConverterData = (values: Partial<Converter>, isUpdatedByUser: boolean = true) => {
    state.value.converter = {
      ...state.value.converter,
      ...values,
    };

    if (isUpdatedByUser) {
      state.value.wasSaved = false;
    }
  };

  const updateSavingByUser = (value: boolean = true) => {
    state.value.wasSaved = value;
  };

  const clearStore = () => {
    state.value = {
      converter: {},
      wasSaved: true,
    };
  };

  // Real time converter fields update
  const debouncedPatchField = debounce(
    async (value: string | boolean | number[], field: string, converterId: number) => {
      try {
        const data = await ConverterService.patchConverter({
          id: converterId,
          languageId: languageId.value,
          data: {
            [field]: value,
          },
        });
        if (data && data.status && field === 'status') {
          const stauts = data.status === ConverterStatus.PUBLISHED ? 'published' : 'unpublished';
          state.value.converter.status = data.status;

          toast.success({
            id: 'CONVERTER_STATUS_UPDATE',
            message: `Converter was ${stauts}`,
          });
        }
      } catch (e) {
        toast.errorTemporary({
          id: 'INVALID_PATCH_DATA',
          message: e.data.errorMessage,
        });
      }
    },
    500,
  );

  const onFieldUpdate = (val: string | boolean | number[], field: string, converterId: number) => {
    nextTick(() => {
      debouncedPatchField(val, field, converterId);
    });
  };

  return {
    updateConverterData,
    updateSavingByUser,
    state,
    clearStore,
    onFieldUpdate,
  };
});
