<script setup lang="ts">
import { SIZES, SortStatus } from '@/types';
import FormSelect, { type SelectItem } from '@/components/FormSelect.vue';
import AuthorScanIcon from '@/assets/icons/author-scan.svg?component';
import { useUserFilters } from '@/features/Users/stores/filters.store.ts';
import { useFetchAuthorById, useFetchAuthors } from '@/features/ArticleLayout/queries';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store.ts';
import { ArticleAuthorsFetchRequest } from '@/features/ArticleLayout/types.ts';
import { onMounted, computed, ref } from 'vue';
import { useUserStore } from '@/stores/user.store.ts';

const props = defineProps<{
  languageId: number;
  authorAttrs: Record<string, unknown>;
  errors: Record<string, unknown>;
}>();

const emits = defineEmits<{
  (event: 'update:authorId', value: number): void;
}>();

const filtersStore = useUserFilters();
const articleStore = useArticleStore();
const userStore = useUserStore();

const searchQuery = computed(() => filtersStore.state?.searchQuery || '');

const articleAuthorError = computed(() => articleStore.state?.errors?.authorId ?? false);

const authorId = ref<number>(articleStore.state.authorId);
const updateSearchQuery = (searchQuery: string) => {
  filtersStore.setSearchQuery(searchQuery);
};

const limitMessage = computed<string>(() => {
  return !searchQuery.value ? 'Only 20 authors are displayed. Start typing a name or surname to see more.' : '';
});

const fetchAuthorByIdParams = computed(() => {
  if (!articleStore.state.authorId) return;

  return {
    authorId: articleStore.state.authorId,
    languageId: props.languageId,
  };
});
const fetchAuthorsParams = computed<ArticleAuthorsFetchRequest>(() => ({
  languageId: props.languageId,
  page: 1,
  pagination: !searchQuery.value,
  itemsPerPage: 20,
  // Temporary removed from query coz with popular order requests timing nearby 2,5 sec
  // 'order[popular]': !searchQuery.value ? SortStatus.DESC : null,
  'order[name]': SortStatus.ASC,
  'filter[search]': searchQuery.value,
}));

const { data: selectedAuthorById } = useFetchAuthorById(fetchAuthorByIdParams);
const { data: authors, isLoading: isAuthorsLoading } = useFetchAuthors(fetchAuthorsParams);

const mappedAuthors = computed<SelectItem[]>(() => {
  if (!authors.value || !authors.value.items?.length) return [];

  return authors.value.items.map((author) => {
    return {
      id: author.author.id,
      label: author.name,
    };
  });
});

const mappedAuthor = computed(() => {
  if (!selectedAuthorById.value || !selectedAuthorById.value.author) return;

  return {
    id: selectedAuthorById.value.author.id,
    label: selectedAuthorById.value.name ?? selectedAuthorById.value.author.title,
  };
});

onMounted(() => {
  filtersStore.setSearchQuery('');
});

const updateAuthorId = (id: number) => {
  articleStore.updateField('authorId', id);
  emits('update:authorId', id);
};
</script>

<template>
  <FormSelect
    :attrs="authorAttrs"
    :is-errored="!!props.errors.authorId"
    :model-value="authorId"
    class="max-w-[380px] w-full mb-4 sm:mb-1"
    name="author"
    placeholder="Select public author"
    :values="mappedAuthors"
    :menu-additional-info="limitMessage"
    :size="SIZES.SMALL"
    :is-loading="isAuthorsLoading"
    :selected-value="mappedAuthor"
    :is-disabled="!userStore.hasAccessToCurrentLanguage()"
    data-anchor="field-author"
    @search="updateSearchQuery"
    @update:model-value="updateAuthorId"
  >
    <template #icon>
      <AuthorScanIcon class="w-4 h-4 text-imperium-fg-muted" />
    </template>

    <template #error> {{ props.errors.authorId }}</template>
  </FormSelect>
</template>

<style scoped lang="scss"></style>
