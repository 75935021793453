<template>
  <div
    :class="{
      'cursor-not-allowed': isEditorOffline,
      'h-full': true,
    }"
  >
    <div
      v-show="!isFirstConnection"
      id="editor-holder"
      ref="holder"
      :class="[
        $style['colladitor-holder'],

        {
          [$style['colladitor-holder--disabled']]: props.isDisabled,
          'opacity-20 pointer-events-none cursor-not-allowed': isEditorOffline,
        },
      ]"
    />

    <div
      v-if="isFirstConnection"
      class="flex justify-center items-center font-normal text-sm text-imperium-fg-muted"
    >
      <div>
        <svg
          class="animate-spin h-5 w-5 text-gray-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          />
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </div>
      <div class="ml-1">Connection in progress...</div>
    </div>

    <CollaborativeEditorLinkPreview v-if="isDesktop" />

    <CloudNoConnection
      v-if="isEditorOffline"
      class="animate-bounce absolute top-16 left-0 right-0 ms-auto me-auto opacity-60 w-12 h-12"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect, nextTick, onMounted, onUnmounted, inject, watch } from 'vue';
import * as Y from 'yjs';
import { ySyncPlugin, yCursorPlugin, yUndoPlugin, undo, redo, initProseMirrorDoc } from 'y-prosemirror';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { keymap } from 'prosemirror-keymap';

import { schema } from '../schema';
import { setupProsemirror } from '../plugins/prosemirror';
import { htmlToProseMirrorDoc } from '@/features/CollaborativeEditor/helpers/htmlToProseMirrorDoc';
import { useToast } from '@/composables';
import { backspaceDeletePlugin } from '@/features/CollaborativeEditor/plugins/prosemirror/deleteFigurePlugin';
import { enterKeymap } from '@/features/CollaborativeEditor/helpers/enterBindings';
import { Plugin } from 'prosemirror-state';
import { noImagesPlugin } from '@/features/CollaborativeEditor/plugins/prosemirror/noImagesPlugin';
import { addMenuHotKeysListener, removeMenuHotKeysListeners } from '@/features/CollaborativeEditor/helpers/menuHotKeys';
import CollaborativeEditorLinkPreview from '@/features/CollaborativeEditor/components/CollaborativeEditorLinkPreview.vue';
import {
  addListenerForLinksBlur,
  removeListenerForLinksBlur,
} from '@/features/CollaborativeEditor/helpers/linkPreview';
import { deleteLinkHelper } from '@/features/CollaborativeEditor/helpers/deleteLinkHelper';
import CloudNoConnection from '@/assets/icons/cloud-no-connection.svg?component';
import { useOfflineEditor } from '@/features/CollaborativeEditor/composables/useOfflineEditor';
import { Node, Fragment, Schema, Slice } from 'prosemirror-model';
import { useCRDTConnection } from '@/features/CollaborativeEditor/composables/crdt-websocket';
import { transformPasted } from '@/features/CollaborativeEditor/utils/transform-pasted.ts';

const props = defineProps<{
  documentId: string;
  userId: string;
  userName: string;
  languageId: string;
  text: string;
  isDisabled?: boolean;
}>();

const isDesktop = inject('isDesktop');

const { isEditorOffline } = useOfflineEditor();
const isEditorInitialized = ref<boolean>(false);

const handleLinkClicks = (e) => {
  if (e.target?.tagName === 'A' && holder.value.contains(e.target)) {
    const href = e.target.href;

    window.open(href, '_blank').focus();
  }
};

const ydoc = new Y.Doc();
const { ws, provider, isFirstConnection } = useCRDTConnection({
  documentId: props.documentId,
  ydoc,
  userId: props.userId,
  userName: props.userName,
  languageId: props.languageId,
});

const toast = useToast();

const yXmlFragment = ydoc.getXmlFragment(`${props.documentId}`);

const holder = ref<HTMLDivElement>();

const unwatch = watchEffect(() => {
  if (!holder.value) {
    return;
  }

  initEditor();

  nextTick(() => {
    document.addEventListener('click', handleLinkClicks);
    unwatch();
  });
});

const initEditor = () => {
  const editor = document.createElement('div');

  // Create prosemirrors nodes from our article full text
  const proseMirrorDoc = htmlToProseMirrorDoc(props.text || '');
  const { doc, mapping } = initProseMirrorDoc(yXmlFragment, schema);

  isEditorInitialized.value = true;
  const state = EditorState.create({
    doc,
    schema,
    plugins: [
      ySyncPlugin(yXmlFragment, { mapping }),
      yCursorPlugin(provider.value.awareness),
      yUndoPlugin(),
      noImagesPlugin,
      keymap({
        'Mod-z': undo,
        'Mod-y': redo,
        'Mod-Shift-z': redo,
        ...enterKeymap,
      }),
    ]
      .concat(setupProsemirror({ schema }))
      .concat(backspaceDeletePlugin)
      .concat([
        new Plugin({
          props: {
            handleDOMEvents: {
              beforeinput(view, event) {
                deleteLinkHelper(view, event);
              },
            },
            transformPasted: transformPasted(schema),
          },
        }),
      ]),
  });

  const view = new EditorView(editor, {
    state,
    attributes: {
      dir: 'ltr',
    },
    editable() {
      return !props.isDisabled;
    },
  });

  holder.value.appendChild(editor);
};
watch(
  () => provider.value,
  () => {
    provider.value.on('status', (event: { status: string }) => {
      if (event.status === 'connected') {
        if (!isEditorInitialized.value) {
          initEditor();
        }
      }
    });
  },
);

// Remove cursor before reloading page or closing page
window.addEventListener('unload', () => {
  const awareness = provider.value.awareness;
  awareness.destroy();
});

onMounted(() => {
  addListenerForLinksBlur();

  document.addEventListener(
    'click',
    (e) => {
      const target = e.target;

      if (target.tagName === 'TEMPLATE') {
        e.stopPropagation();
        e.preventDefault();
        return false;
      }
    },
    {
      capture: true,
    },
  );

  addMenuHotKeysListener();
});

onUnmounted(() => {
  document.removeEventListener('click', handleLinkClicks);
  removeMenuHotKeysListeners();
  removeListenerForLinksBlur();
});
</script>

<style lang="scss" module>
.colladitor-holder {
  @apply relative bg-imperium-bg-sub-base text-base text-imperium-fg-extreme h-full;

  > div {
    @apply h-full flex;
  }

  // Customization
  :global(.ProseMirror-menubar-wrapper) {
    @apply w-full;
  }

  :global(.ProseMirror) {
    @apply h-auto py-4 px-4 leading-6;
  }

  :global(.ProseMirror-menubar) {
    @apply flex flex-wrap justify-start items-center bg-imperium-bg-1 shadow-imperium-border rounded-3xl px-4 p-2 xl:px-2 min-h-10 top-[80px] gap-0 sm:gap-0.5;

    // Need to override "style" which added by ProseMirror with this modern way to make menu sticky
    position: sticky !important;
    width: 100% !important;

    -webkit-box-shadow: 0 -10px 0 10px rgba(255, 255, 255, 1);
    -moz-box-shadow: 0 -10px 0 10px rgba(255, 255, 255, 1);
    box-shadow: 0 -10px 0 10px rgba(255, 255, 255, 1);

    // Prevents editor above the menubar to be clickable when scroll
    &:before {
      @apply absolute content-[''];

      inset: -25px -20px 0 -20px;
      min-height: inherit;
      width: calc(100% + 40px);
      z-index: -1;
    }
  }

  :global(.ProseMirror [type='instagram']) {
    @apply xs:h-[660px] sm:h-[760px];
  }

  :global(.ProseMirror-menuitem) {
    @apply inline-flex items-center;
    &:first-child {
      @apply rounded-full;
    }

    &:last-child {
      @apply mr-2;

      @media (max-width: 1274px) and (min-width: 1024px) {
        @apply mr-auto;
      }

      @media (max-width: 675px) {
        @apply mr-auto;
      }
    }
  }

  :global(.ProseMirror-menuitem > *:not(.ProseMirror-menu-disabled)) {
    @apply mr-0 hover:bg-imperium-bg-3;
  }

  :global(.ProseMirror-icon) {
    @apply p-2 rounded text-[#303D43];
  }

  :global(.ProseMirror-menuseparator) {
    @apply bg-imperium-border-base border-0 mr-0 w-[1px] h-8;
    display: block !important;
  }
  :global(.ProseMirror-separator) {
    display: none !important;
  }

  // Blocks
  :global(.ProseMirror-menu-dropdown-wrap) {
    @apply p-0  rounded-3xl;

    display: block !important;
  }

  :global(.ProseMirror-Imperium-blocks-menu.ProseMirror-menu-dropdown) {
    @apply min-w-8 p-2 text-imperium-fg-muted bg-imperium-bg-3 hover:bg-imperium-bg-4 flex gap-2 rounded-3xl;
  }

  :global(.ProseMirror-Imperium-blocks-menu.ProseMirror-menu-dropdown::before) {
    @apply static block top-0 left-0 w-4 h-4;

    content: '';
    background-image: url('~/src/assets/icons/editor/plus.svg');
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
  }

  :global(.ProseMirror-Imperium-blocks-menu.ProseMirror-menu-dropdown::after) {
    @apply static border-0 w-4 h-4 top-0 left-0 block;

    background-image: url('~/src/assets/icons/caret-down.svg');
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
  }

  :global(.ProseMirror-Imperium-blocks-menu.ProseMirror-menu-dropdown-menu),
  :global(.ProseMirror-menu-submenu) {
    @apply top-full left-0 shadow-imperium-base rounded-lg bg-imperium-bg-sub-base p-2 border border-imperium-border-weak;
  }

  :global(.ProseMirror-Imperium-blocks-menu .ProseMirror-menu-dropdown-item) {
    @apply hover:bg-imperium-bg-3 p-2 font-semibold text-base text-imperium-fg-base flex items-center justify-start gap-2 rounded-lg;

    > div {
      @apply flex items-center justify-start gap-2 w-full;
    }
  }

  // Sub Dropdown
  :global(.ProseMirror-menu-submenu-label) {
    @apply pr-8 relative flex items-center gap-2;
  }

  :global(.ProseMirror-menu-submenu-label > svg) {
    @apply w-5 h-5;
  }

  :global(.ProseMirror-menu-submenu-label::after) {
    @apply content-[''] block border-0 absolute right-1 top-1/2 w-3 h-4;

    margin-top: -8px;
    opacity: 0.6;

    background-image: url('~/src/assets/icons/caret.svg');
    background-size: 8px;
    background-repeat: no-repeat;
    background-position: center;
  }

  :global(.ProseMirror-menu-submenu) {
    @apply -translate-y-[14%] max-h-[320px] overflow-y-auto px-2 py-3;

    scrollbar-width: thin;
    left: 100%;
    width: 320px;
  }
  :global(.ProseMirror-menu-submenu::before) {
    @apply content-[''] block absolute w-10 h-full -left-3 top-0;
  }

  :global(.ProseMirror-menu-dropdown-item .ProseMirror-icon) {
    @apply p-0 w-5 h-5;

    > svg {
      @apply w-5 h-5;
    }
  }

  :global(.ProseMirror-menu-submenu .ProseMirror-menu-dropdown-item) {
    @apply rounded-lg;
  }

  :global(.ProseMirror-menu-submenu .ProseMirror-menu-dropdown-item > div) {
    @apply pt-1 pb-1;
  }

  :global(.ProseMirror-menu-submenu .subscription-form-item) {
    @apply flex items-center w-full;
  }
  :global(.ProseMirror-menu-submenu .subscription-form-item__icon) {
    @apply w-6 min-w-6 h-6 min-h-6;
  }
  :global(.ProseMirror-menu-submenu .subscription-form-item__icon > svg) {
    @apply w-full h-auto;
  }
  :global(.ProseMirror-menu-submenu .subscription-form-item > div:not(:first-child)) {
    @apply overflow-hidden ml-3;
  }

  :global(.ProseMirror-menu-submenu .disclaimer-form-item) {
    @apply flex items-center w-full;
  }
  :global(.ProseMirror-menu-submenu .disclaimer-form-item__icon) {
    @apply w-6 min-w-6 h-6 min-h-6;
  }
  :global(.ProseMirror-menu-submenu .disclaimer-form-item__icon > svg) {
    @apply w-full h-auto;
  }
  :global(.ProseMirror-menu-submenu .disclaimer-form-item > div:not(:first-child)) {
    @apply overflow-hidden ml-3;
  }
  :global(.ProseMirror-menu-submenu .disclaimer-description) {
    @apply text-imperium-fg-muted font-normal text-sm overflow-hidden text-ellipsis;
  }

  :global(.ProseMirror-menu-submenu .description) {
    @apply text-imperium-fg-muted font-normal text-sm overflow-hidden text-ellipsis;
  }

  // Styling content in editor
  :global(.ProseMirror-Imperium) {
    p {
      @apply text-base leading-6 mb-4;
    }

    h2 {
      @apply font-bold text-imperium-fg-strong mb-4 text-[26px] leading-tight;
    }

    h3 {
      @apply font-semibold text-imperium-fg-strong text-2xl mb-4;
    }

    em,
    i {
      font-style: italic;
    }

    u {
      text-decoration: underline;
    }

    ul {
      list-style: disc;
    }

    ol {
      list-style: decimal;
    }

    blockquote {
      @apply relative min-h-10 text-lg;

      padding: 30px 60px;
      border-left: 0px none;

      p:first-child {
        @apply mt-0;
      }
    }

    blockquote::before {
      @apply block absolute;

      content: '';
      top: 2px;
      left: 2px;
      width: 36px;
      height: 36px;
      background-image: url('~/src/assets/icons/editor/representation/blockquote.svg');
      background-size: 36px;
      background-repeat: no-repeat;
      background-position: top left;
    }

    a[href],
    span[href] {
      color: currentColor;

      @apply border-b-2 border-imperium-ds-primary-base no-underline cursor-pointer;
    }

    img {
      min-width: 100px;
      min-height: 80px;
      max-width: 100%;
      max-height: 100%;
      flex-grow: 1;
      position: relative;
      @apply bg-imperium-bg-3;
      user-select: none;
    }

    template {
      @apply relative flex items-center justify-center text-center w-full bg-imperium-bg-sub-base border border-imperium-border-base shadow-imperium-form h-96 mb-5 rounded-xl overflow-hidden cursor-pointer;

      &:not(:first-child) {
        @apply mt-5;
      }

      &::before {
        @apply relative inline-block font-semibold text-xl text-imperium-fg-input;

        z-index: 8;
        content: attr(data-label);
      }

      &::after {
        @apply absolute block bg-transparent top-0 left-0 right-0 bottom-0;

        z-index: 9;
        content: '';
      }
    }

    template * {
      user-select: none;
      pointer-events: none;
      display: none;
    }

    :global(span.highlighted) {
      @apply bg-imperium-ds-green-muted;
    }
  }

  :global(template.ProseMirror-selectednode) {
    @apply outline-0 border-0 shadow-imperium-form-selected;
  }
  :global(article.ProseMirror-selectednode) {
    @apply outline-0 border-0;
    box-shadow: theme('boxShadow.imperium-form-selected') !important;
  }

  :global(iframe.ProseMirror-selectednode) {
    @apply outline-0 border-0 shadow-imperium-form-selected rounded-2xl;
  }

  :global(.ProseMirror iframe) {
    @apply rounded-2xl mb-4;
  }

  :global(.ProseMirror .twitter-tweet iframe) {
    @apply rounded-none mb-4;
  }

  :global(.ProseMirror-selectednode .twitter-tweet) {
    @apply outline-0 border-0 shadow-imperium-form-selected rounded-2xl;
  }

  :global(.ProseMirror-selectednode) {
    @apply outline-0;
  }

  :global(.ProseMirror-Imperium .container) {
    margin: auto;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(25 32 36 / var(--tw-text-opacity));
    padding: 20px;
    background: #f0eff0;
    background-position: 50px 0;
    max-width: none;
    position: relative;
  }

  :global(.ProseMirror-Imperium .figcaption) {
    @apply text-imperium-fg-strong text-sm text-center m-auto rounded mt-6;

    padding: 0 8px 8px 8px;
    max-width: none;
    min-width: 100%;
  }

  :global(.ProseMirror-Imperium figure) {
    @apply cursor-grab;
  }

  :global(.ProseMirror-Imperium figure .image-container) {
    @apply relative flex justify-center pointer-events-none;
  }

  :global(.ProseMirror-Imperium figure:hover .image-container .article-image-edit-button),
  :global(.ProseMirror-Imperium figure:hover .image-container .article-image-caption-button) {
    @apply block cursor-pointer pointer-events-auto;
  }

  :global(.ProseMirror-Imperium disclaimer) {
    @apply block text-imperium-fg-muted font-normal text-left leading-tight mb-4;
    margin-bottom: 16px !important;
    font-size: 11px;

    &:not(:first-child) {
      @apply mt-4;
    }
  }

  // Related article widget
  :global(.ProseMirror-Imperium .article-widget-container) {
    @apply grid sm:grid-cols-[1fr_,_2fr] rounded-xl mb-4 shadow-imperium-form;
  }
  :global(.ProseMirror-Imperium .article-widget-image) {
    @apply rounded-t-xl sm:rounded-t-none sm:rounded-l-xl w-full max-h-[228px] sm:min-w-[225px] h-full sm:min-h-[150px] object-cover;
  }
  :global(.ProseMirror-Imperium .article-widget-content) {
    @apply flex flex-col pt-3 pb-4 px-5;
  }

  :global(.ProseMirror-Imperium .article-widget-content) {
    @apply flex flex-col pt-3 pb-4 px-5 justify-between;
  }

  :global(.ProseMirror-Imperium .article-widget-title) {
    @apply font-semibold text-base text-black;
  }
  :global(.ProseMirror-Imperium .article-widget-read-also) {
    @apply font-semibold text-xs text-imperium-ds-primary-strongest m-0 mb-1;
  }
  :global(.ProseMirror-Imperium .article-widget-meta-section) {
    @apply flex justify-between items-center;
  }
  :global(.ProseMirror-Imperium .article-widget-meta-author) {
    @apply m-0 text-xs text-black font-semibold;
  }
  :global(.ProseMirror-Imperium .article-widget-meta-published-at) {
    @apply m-0 text-xs text-black font-semibold;
  }

  // Styling elements in content blocks
  :global(.ProseMirror .article-image-edit-button) {
    @apply border-t border-b border-r rounded-tr-lg rounded-br-lg cursor-pointer h-11 w-11 absolute p-2 block md:hidden;

    background-size: 24px;
    width: 44px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('~/src/assets/icons/image-alt.svg');
    background-color: white;
    bottom: -20px;
    left: 50%;
  }

  :global(.ProseMirror .article-image-edit-button:hover) {
    @apply bg-imperium-bg-1;
  }

  :global(.ProseMirror .article-image-caption-button) {
    @apply border rounded-tl-lg rounded-bl-lg cursor-pointer h-11 absolute p-2 block md:hidden;

    width: 45px;
    background-size: 24px;
    background-repeat: no-repeat;
    background-image: url('~/src/assets/icons/image-caption.svg');
    background-position: center;
    background-color: white;
    bottom: -20px;
    left: calc(50% - 44px);
  }

  :global(.ProseMirror .article-image-caption-button:hover) {
    @apply bg-imperium-bg-1;
  }

  // Styling prompts
  :global(.ProseMirror-prompt) {
    @apply absolute right-0 top-[calc(100%+20px)] z-50;
  }

  // Styling twitter
  :global(.ProseMirror .twitter-tweet) {
    margin: 10px auto 10px 0 !important;
  }

  p[data-ct-non-breakable='true'] {
    @apply border-l-imperium-border-strong border-l;
    margin-left: -50px;
    padding-left: 49px;
  }

  blockquote[data-ct-non-breakable='true'] {
    margin-left: -50px;
    padding-left: 99px;
    border-left: 1px solid #cdd6da !important;

    &::before {
      @apply block absolute;

      content: '';
      top: 2px;
      left: 49px;
      width: 36px;
      height: 36px;
      background-image: url('~/src/assets/icons/editor/representation/blockquote.svg');
      background-size: 36px;
      background-repeat: no-repeat;
      background-position: top left;
    }
  }

  h2[data-ct-non-breakable='true'] {
    @apply border-l-imperium-border-strong border-l;
    margin-left: -50px;
    padding-left: 49px;
  }

  h3[data-ct-non-breakable='true'] {
    @apply border-l-imperium-border-strong border-l;
    margin-left: -50px;
    padding-left: 49px;
  }

  ul[data-ct-non-breakable='true'] {
    @apply border-l-imperium-border-strong border-l;
    margin-left: -50px;
    padding-left: 79px;
  }

  ol[data-ct-non-breakable='true'] {
    @apply border-l-imperium-border-strong border-l;
    margin-left: -50px;
    padding-left: 79px;
  }

  figure[data-ct-non-breakable='true'] {
    @apply border-l-imperium-border-strong border-l;
    margin-left: -50px;
    padding-left: 49px;
  }

  disclaimer[data-ct-non-breakable='true'] {
    @apply border-l-imperium-border-strong border-l;
    margin-left: -50px;
    padding-left: 49px;
  }

  article[data-ct-non-breakable='true'] {
    &::before {
      content: '';
      position: absolute;
      left: -50px;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: #cdd6da;
    }
  }
}
//ProseMirror ProseMirror-Imperium ProseMirror-focused
.colladitor-holder {
  &--disabled {
    :global(.ProseMirror-Imperium) {
      pointer-events: none;
    }
    :global(.ProseMirror-menubar) {
      pointer-events: none;
    }
  }
}
</style>
