<script setup lang="ts">
import { computed, defineAsyncComponent, ref, shallowRef, watch, watchEffect } from 'vue';
import { SIZES, BUTTON_TYPE, type Language } from '@/types';

import Dropdown from '@/components/Dropdown.vue';
import Button from '@/components/Button.vue';
import ArrowDownIcon from '@/assets/icons/arrow-down.svg?component';
import TranslateIcon from '@/assets/icons/translate.svg?component';
import CountryIcon from '@/shared/ui/CountryIcon/CountryIcon.vue';
import { useUserStore } from '@/stores/user.store.ts';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store.ts';
import { useLanguagesStore } from '@/stores/languages.store.ts';
import { useCurrentLanguageStore } from '@/stores/current-language.store.ts';
import { useRoute, useRouter } from 'vue-router';
import { useArticleCurrentLanguageStore } from '@/stores/article-current-language.ts';

const router = useRouter();
const route = useRoute();
const { state: user } = useUserStore();
const articleStore = useArticleStore();
const languagesStore = useLanguagesStore();
const currentLanguageStore = useArticleCurrentLanguageStore();

const props = defineProps<{
  documentLanguageId: number;
  isDisabled?: boolean;
}>();

const emits = defineEmits<{
  (event: 'toggle'): void;
  (event: 'select', value: Language): void;
}>();

const dropdownElement = ref<typeof Dropdown | null>(null);

const articleTranslates = computed(() => articleStore.state?.languages ?? []);
const currentLanguageShort = computed(() => currentLanguageStore.state.short);

const articleId = computed(() => route.params.id);

const goToArticleTranslate = async (language: number) => {
  await router.push({
    name: 'article',
    params: {
      id: articleId.value,
    },
    query: {
      language: language,
    },
  });

  dropdownElement.value?.close();
};
const languagesOptions = computed(() => {
  return languagesStore.state.filter((language) => articleTranslates.value.includes(language.id));
});

const accessToLanguageGranted = (languageId: number) => {
  return !!user.languages.find((lang) => lang.id === languageId);
};

const hasLanguagesOptions = computed<boolean>(() => {
  return articleTranslates.value?.length > 0;
});

const currentComponent = shallowRef(null);
const updateComponent = () => {
  currentComponent.value = defineAsyncComponent(
    () => import(`@/assets/country-flags/square/${currentLanguageShort.value}.svg?component`),
  );
};

updateComponent();
watch(
  () => currentLanguageStore.state,
  (newVal) => {
    updateComponent();
  },
);
</script>

<template>
  <Dropdown
    v-if="hasLanguagesOptions"
    id="article-translate"
    ref="dropdownElement"
    placement="bottom-start"
    :classes="{
      dropdown: 'w-60',
    }"
  >
    <template #button>
      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.GHOST"
        leading-label="Language"
        @click="emits('toggle')"
      >
        <template #leftIcon>
          <TranslateIcon class="w-4 h-4 text-[#101010]" />
        </template>

        <template #rightIcon>
          <ArrowDownIcon class="w-4 h-4 text-[#101010]" />
        </template>
        <component
          :is="currentComponent"
          :key="currentLanguageShort"
          class="w-5 h-4 border-none"
        />
      </Button>
    </template>

    <template #menu>
      <ul class="max-h-64 py-2 px-2 overflow-y-auto overflow-x-hidden text-base">
        <li
          v-for="language in languagesOptions"
          :key="language.short"
          class="w-full py-2 px-1 text-md font-semibold text-gray-900 hover:bg-imperium-bg-2 rounded dark:text-gray-300 flex items-center gap-2 cursor-pointer"
          :class="{
            'pointer-events-none': !accessToLanguageGranted(language.id),
          }"
          @click="goToArticleTranslate(language.id)"
        >
          <CountryIcon
            :key="language.short"
            class="flex-none"
            :is-disabled="!accessToLanguageGranted(language.id)"
            :code="language.short"
            :name="language.title"
          />
          {{ language.title }}
          <span
            v-if="!accessToLanguageGranted(language.id)"
            class="text-[#98A4AA] text-xs ml-auto"
          >
            No access
          </span>
        </li>
      </ul>
    </template>
  </Dropdown>
</template>

<style scoped lang="scss"></style>
