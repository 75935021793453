<template>
  <form
    class="flex flex-col h-full justify-between"
    @submit="onSubmit"
  >
    <div class="flex flex-col">
      <FormInput
        v-model="name"
        :attrs="nameAttrs"
        :is-errored="isNameErrored"
        :label-position="INPUT_LABEL_POSITION.LEFT"
        class="w-full mb-2"
        is-required
        label-width="125px"
        name="name"
        data-testid="user-name"
        placeholder="Name Surname"
        type="text"
      >
        <template #label> User's name</template>
        <template #error>
          {{ errors.name }}
        </template>
      </FormInput>
      <FormInput
        v-model="email"
        :attrs="emailAttrs"
        :is-errored="isEmailErrored"
        :label-position="INPUT_LABEL_POSITION.LEFT"
        class="w-full mb-2"
        is-required
        label-width="125px"
        name="email"
        placeholder="Email"
        data-testid="user-email"
        type="text"
      >
        <template #label> Email address</template>
        <template #error>
          {{ errors.email }}
        </template>
      </FormInput>
      <FormMultiDropdown
        v-model="roles"
        :attrs="rolesAttrs"
        :is-errored="isRolesErrored"
        :label-position="INPUT_LABEL_POSITION.LEFT"
        :values="rolesList"
        class="mb-2"
        is-required
        label-width="125px"
        name="role"
        data-testid="user-role"
        placeholder="Search user's role in the list"
      >
        <template #label> Role</template>
        <template #error>
          {{ errors.roles }}
        </template>
      </FormMultiDropdown>

      <FormMultiDropdown
        v-if="userLanguageView"
        v-model="languages"
        :attrs="languagesAttrs"
        :label-position="INPUT_LABEL_POSITION.LEFT"
        :values="languagesList"
        class="mb-2"
        :is-disabled="!userLanguageEdit"
        data-testid="user-language"
        :hide-after-select="false"
        label-width="125px"
        name="languages"
        @update:model-value="updateUserLanguages"
      >
        <template #label> Language access</template>
      </FormMultiDropdown>
      <FormInput
        v-model="facebook"
        :attrs="facebookAttrs"
        :is-errored="isFacebookErrored"
        :label-position="INPUT_LABEL_POSITION.LEFT"
        class="w-full mb-2"
        label-width="125px"
        name="facebook"
        data-testid="user-facebook"
        placeholder="@1101011001"
        type="text"
      >
        <template #label>Facebook</template>
        <template #error>
          {{ errors.facebook }}
        </template>
      </FormInput>
      <FormInput
        v-model="twitter"
        :attrs="twitterAttrs"
        :is-errored="isTwitterErrored"
        :label-position="INPUT_LABEL_POSITION.LEFT"
        class="w-full mb-2"
        label-width="125px"
        name="twitter"
        data-testid="user-twitter"
        placeholder="@"
        type="text"
      >
        <template #label>X (Twitter)</template>
        <template #error>
          {{ errors.twitter }}
        </template>
      </FormInput>
      <FormInput
        v-model="telegram"
        :attrs="telegramAttrs"
        :is-errored="isTelegramErrored"
        :label-position="INPUT_LABEL_POSITION.LEFT"
        class="w-full mb-2"
        label-width="125px"
        data-testid="user-telegram"
        name="telegram"
        placeholder="@"
        type="text"
      >
        <template #label>Telegram</template>
        <template #error>
          {{ errors.telegram }}
        </template>
      </FormInput>
    </div>
    <div class="flex gap-2">
      <Button
        :color="BUTTON_COLOR.DEFAULT"
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.TERTIARY"
        is-full-width
        data-testid="cancel-user-button"
        @click="emits('cancel-edit')"
      >
        Cancel
      </Button>
      <Button
        :is-loading="props.isLoading"
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.PRIMARY"
        is-full-width
        type="submit"
        data-testid="save-user-button"
      >
        Save
      </Button>
    </div>
  </form>
</template>
<script lang="ts" setup>
import { computed, ref, type Ref, watch, watchEffect } from 'vue';
import { toTypedSchema } from '@vee-validate/zod';

import { useFormData } from '@/composables/useFormData';

import FormInput from '@/components/FormInput.vue';
import Button from '@/components/Button.vue';
import FormMultiDropdown from '@/components/FormMultiDropdown.vue';

import type { User } from '@/features/Users/types';
import { BUTTON_COLOR, BUTTON_TYPE, INPUT_LABEL_POSITION, type Language, SIZES } from '@/types';
import { type Role } from '@/features/Roles/types';
import { type FormErrors } from 'vee-validate';
import { userEditFormValidate } from '@/features/Users/components/UserInfo/validation/userEditForm';
import { useLanguagesStore } from '@/stores/languages.store';
import { useUserPermissions } from '@/stores/user.store';
import { useToast } from '@/composables';
import { UsersService } from '@/features/Users/service';
import { useRolesStore } from '@/features/Roles/stores/roles.store';

const { userLanguageEdit, userLanguageView } = useUserPermissions();

const props = defineProps<{
  userData: User;
  isLoading: boolean;
}>();

const emits = defineEmits<{
  (event: 'close'): void;
  (event: 'cancel-edit'): void;
  (event: 'update-data', value: User, errors: Ref<FormErrors<typeof value>>): void;
  (event: 'save'): void;
  (event: 'refetch-user'): void;
}>();

const { defineField, errors, handleSubmit, values, setValues } = useFormData({
  data: {},
  validator: toTypedSchema(userEditFormValidate),
});

watch(
  values,
  () => {
    emits('update-data', values, errors);
  },
  { deep: true },
);

watchEffect(() => {
  const data = { ...props.userData };

  data.roles = data.roles.map((item: Role) => (item?.id ? item.id : item));
  data.languages = data.languages.map((item: Language) => (item?.id ? item.id : item));
  setValues(data);
});

const [name, nameAttrs] = defineField('name');
const [email, emailAttrs] = defineField('email');
const [roles, rolesAttrs] = defineField('roles');
const [languages, languagesAttrs] = defineField('languages');
const [facebook, facebookAttrs] = defineField('facebook');
const [twitter, twitterAttrs] = defineField('twitter');
const [telegram, telegramAttrs] = defineField('telegram');

const isNameErrored = computed(() => !!errors.value.name);
const isEmailErrored = computed(() => !!errors.value.email);
const isRolesErrored = computed(() => !!errors.value.roles);
const isFacebookErrored = computed(() => !!errors.value.facebook);
const isTwitterErrored = computed(() => !!errors.value.twitter);
const isTelegramErrored = computed(() => !!errors.value.telegram);

const onSubmit = (event: Event) => {
  event.preventDefault();
  handleSubmit(() => {
    emits('save');
  })();
};

const rolesStore = useRolesStore();

const rols = computed(() => rolesStore.state);

const languagesStore = useLanguagesStore();
const toast = useToast();

const previousValues = ref<Array<number> | null>(languages.value);

const langs = computed(() => languagesStore.state);

const rolesList = computed(() => {
  const rolesItems = rols.value || [];
  if (rolesItems) {
    return (
      rolesItems?.map((item: Role) => ({
        id: item.id,
        label: item.name,
      })) || []
    );
  }

  return [];
});

const languagesList = computed(() => {
  const languagesItems = langs.value || [];
  if (languagesItems?.length) {
    return (
      languagesItems.map((item: Language) => ({
        id: item.id,
        label: item.title,
      })) || []
    );
  }

  return [];
});

const updateUserLanguages = async (newValues) => {
  try {
    languages.value = [...newValues];

    const addedItems = newValues.filter((item) => !previousValues.value.includes(item));
    const removedItems = previousValues.value.filter((item) => !newValues.includes(item));

    for (const item of addedItems) {
      try {
        await UsersService.postUserLanguages(props.userData.id, { id: item });
        toast.success({
          id: 'LANGUAGE_ADDED_TO_USER',
          message: 'Language added to user',
        });
      } catch (e) {
        toast.errorTemporary({
          id: 'ERROR_ADD_LANGUAGE_TO_USER',
          message: e.data.errorMessage,
        });
      }
    }

    for (const item of removedItems) {
      try {
        await UsersService.deleteUserLanguages(props.userData.id, item);
        toast.success({
          id: 'LANGUAGE_REMOVED_FROM_USER',
          message: 'Language removed from user',
        });
      } catch (e) {
        toast.errorTemporary({
          id: 'ERROR_DELETE_LANGUAGE_TO_USER',
          message: e.data.errorMessage,
        });
      }
    }
    previousValues.value = [...newValues];
    emits('refetch-user');
  } catch (e) {
    toast.errorTemporary({
      id: 'ERROR_LANGUAGE_TO_USER',
      message: e.data.errorMessage,
    });
  }
};
</script>
