<template>
  <section class="flex flex-col items-start">
    <h3 class="font-semibold text-base text-imperium-fg-base mb-2">Article Settings</h3>

    <Tooltip
      :key="`tooltip-market`"
      width="unset"
      name="market-info"
      placement="auto"
      :tooltip-visible="!documentMetadataShowInMarketsEdit && !showInMarketCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <FormCheckbox
          v-if="documentMetadataShowInMarketsView"
          :is-disabled="
            (!documentMetadataShowInMarketsEdit && !showInMarketCondition) || !userStore.hasAccessToCurrentLanguage()
          "
          :model-value="articleStore.state.isShowingInMarkets"
          class="mb-2.5"
          name="show-markets"
          :attrs="isShowInMarketsAttrs"
          :size="SIZES.SMALL"
          @update:model-value="(value) => emits('update:isShowInMarkets', value)"
        >
          <span class="text-sm">Show in Markets</span>
        </FormCheckbox>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-rss`"
      width="unset"
      name="rss-info"
      placement="auto"
      :tooltip-visible="!documentMetadataHideFromRssEdit && !editNoIndexCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <FormCheckbox
          v-if="documentMetadataHideFromRssView"
          :is-disabled="
            (!documentMetadataHideFromRssEdit && !editNoIndexCondition) || !userStore.hasAccessToCurrentLanguage()
          "
          :model-value="articleStore.state.excludeFromAllRss"
          class="mb-2.5"
          name="exclude-from-rss"
          :attrs="isExcludeFromRssAttrs"
          :size="SIZES.SMALL"
          @update:model-value="(value) => emits('update:isExcludeFromRss', value)"
        >
          <span class="text-sm">Exclude from all RSS</span>
        </FormCheckbox>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>

    <FormCheckbox
      :model-value="articleStore.state.hideFromHotStories"
      class="mb-2.5"
      name="hide-from-hot"
      :attrs="isHideFromHotAttrs"
      :is-disabled="!articleStore.isOriginalArticle() || !userStore.hasAccessToCurrentLanguage()"
      :size="SIZES.SMALL"
      @update:model-value="(value) => emits('update:isHideFromHot', value)"
    >
      <span class="text-sm">Hide from Hot Stories</span>
    </FormCheckbox>

    <Tooltip
      :key="`tooltip-hide-main`"
      width="unset"
      name="hide-main-info"
      placement="auto"
      :tooltip-visible="!documentMetadataHideFromMainPageEdit && !hideFromMainCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <FormCheckbox
          v-if="documentMetadataHideFromMainPageView"
          :is-disabled="
            (!documentMetadataHideFromMainPageEdit && !hideFromMainCondition) || !userStore.hasAccessToCurrentLanguage()
          "
          :model-value="articleStore.state.hideFromMainPage"
          class="mb-2.5"
          name="hide-from-main-page"
          :attrs="isHideFromMainPageAttrs"
          :size="SIZES.SMALL"
          @update:model-value="(value) => emits('update:isHideFromMainPage', value)"
        >
          <span class="text-sm">Hide from Main Page</span>
        </FormCheckbox>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-original-content`"
      width="unset"
      name="original-content-info"
      placement="auto"
      :tooltip-visible="!documentMetadataOriginContentEdit && !documentMetadataOriginContentCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <FormCheckbox
          v-if="documentMetadataOriginContentView"
          :is-disabled="
            (!documentMetadataOriginContentEdit && !documentMetadataOriginContentCondition) ||
            !userStore.hasAccessToCurrentLanguage()
          "
          :model-value="articleStore.state.isOriginalContent"
          class="mb-2.5"
          name="original-content"
          :size="SIZES.SMALL"
          @update:model-value="(value) => emits('update:isOriginalContent', value)"
        >
          <span class="text-sm">Original Content</span>
        </FormCheckbox>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-promo`"
      width="unset"
      name="promo-info"
      placement="auto"
      :tooltip-visible="!documentMetadataPromoPostEdit && !promoPostCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <FormCheckbox
          v-if="documentMetadataPromoPostView"
          :is-disabled="
            (!documentMetadataPromoPostEdit && !promoPostCondition) || !userStore.hasAccessToCurrentLanguage()
          "
          :model-value="articleStore.state.isPromoPost"
          class="mb-2.5"
          name="promo"
          :attrs="isPromoAttrs"
          :size="SIZES.SMALL"
          @update:model-value="(value) => emits('update:isPromo', value)"
        >
          <span class="text-sm">Promo Post</span>
        </FormCheckbox>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-republished`"
      width="unset"
      name="republished-info"
      placement="auto"
      :tooltip-visible="!documentMetadataRepublishArticleEdit && !documentMetadataRepublishArticleCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <FormCheckbox
          v-if="documentMetadataRepublishArticleView"
          :is-disabled="
            (!documentMetadataRepublishArticleEdit && !documentMetadataRepublishArticleCondition) ||
            !articleStore.isOriginalArticle() ||
            !userStore.hasAccessToCurrentLanguage()
          "
          :model-value="articleStore.state.canBeRepublished"
          class="mb-2.5"
          name="republish"
          :size="SIZES.SMALL"
          @update:model-value="(value) => emits('update:canBeRepublished', value)"
        >
          <span class="text-sm"> Republished Article</span>
        </FormCheckbox>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>
  </section>
</template>

<script lang="ts" setup>
import FormCheckbox from '@/components/FormCheckbox.vue';

import { SIZES } from '@/types';
import { useUserPermissions, useUserStore } from '@/stores/user.store';
import { useUserConditions } from '@/composables/useUserConditions';
import Tooltip from '@/components/Tooltip.vue';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store';

defineProps<{
  isShowInMarketsAttrs: Record<string, unknown>;
  isExcludeFromRssAttrs: Record<string, unknown>;
  isHideFromHotAttrs: Record<string, unknown>;
  isHideFromMainPageAttrs: Record<string, unknown>;
  isPromoAttrs: Record<string, unknown>;
}>();

const emits = defineEmits<{
  (event: 'update:isShowInMarkets', value: boolean): void;
  (event: 'update:isExcludeFromRss', value: boolean): void;
  (event: 'update:isHideFromHot', value: boolean): void;
  (event: 'update:isHideFromMainPage', value: boolean): void;
  (event: 'update:isPromo', value: boolean): void;
  (event: 'update:isOriginalContent', value: boolean): void;
  (event: 'update:canBeRepublished', value: boolean): void;
}>();

const {
  documentMetadataHideFromRssView,
  documentMetadataHideFromRssEdit,
  documentMetadataHideFromMainPageView,
  documentMetadataHideFromMainPageEdit,
  documentMetadataShowInMarketsView,
  documentMetadataShowInMarketsEdit,
  documentMetadataPromoPostView,
  documentMetadataPromoPostEdit,
  documentMetadataRepublishArticleView,
  documentMetadataRepublishArticleEdit,
  documentMetadataOriginContentView,
  documentMetadataOriginContentEdit,
} = useUserPermissions();

const {
  hideFromMainCondition,
  showInMarketCondition,
  promoPostCondition,
  editNoIndexCondition,
  documentMetadataRepublishArticleCondition,
  documentMetadataOriginContentCondition,
} = useUserConditions();

const articleStore = useArticleStore();
const userStore = useUserStore();
</script>
