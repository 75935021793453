import type { User } from '@/features/Users/types';

export interface Tokens {
  token: string;
  refreshToken: string;
  tokenExpiresAt?: number;
  refreshTokenExpiresAt?: number;
}

export interface AuthLoginPayload {
  email?: string;
  password: string;
  code?: string;
  token?: string;
}

export interface AuthResetPasswordPayload {
  email: string;
}

export interface AuthCreatePasswordPayload {
  resetToken: string;
  password: string;
}

export type AuthLoginRequest = AuthLoginPayload;

export interface AuthLoginResponse {
  token: string;
  refresh_token: string;
}

export interface TwoFACheckResponse {
  qrCode?: string;
  backupCodes?: string[];
  message?: string;
}

export interface TwoFASetRequest {
  code: string;
}

export interface TwoFASetResponse extends AuthLoginResponse {}

export type AuthResetPasswordRequest = AuthResetPasswordPayload;

export interface FetchUserResponse extends User {
  permissions: any[];
  phone: string;
  updatesAt: string;
  expiredAt: number;
}

export interface UserInvitationConfirmPayload {
  token: string;
  password: string;
}

export interface UserInvitationConfirmResponse {
  token: string;
  refresh_token: string;
}

export interface ValidateTokenPayload {
  token: string;
}

export interface ValidateTokenResponse {
  user: User;
}

export enum AUTH_ERROR_CODES {
  INVALID_LOGIN_CREDENTIALS = 'INVALID_LOGIN_CREDENTIALS',
  ACCESS_DENIED = 'ACCESS_DENIED',
  INVALID_2FA_CODE = 'INVALID_2FA_CODE',
  '2FA_NOT_SET' = '2FA_NOT_SET',
  '2FA_OFF' = '2FA_OFF',
  '2FA_REQUIRED' = '2FA_REQUIRED',
  NOT_FOUND = 'NOT_FOUND',
  JWT_REFRESH_FAILED = 'JWT_REFRESH_FAILED',
}
