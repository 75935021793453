<template>
  <div class="flex flex-row flex-wrap overflow-hidden gap-1">
    <CategoriesTableLabelBadgesBadge
      v-for="(label, index) in shownBadges"
      :key="label[LABEL_FIELD_ID] ?? index"
      :label-id="label[LABEL_FIELD_ID] ?? index"
      :label-title="label[LABEL_FIELD_NAME] ?? label"
    />
    <Badge
      v-if="moreBadges"
      id="more-badges"
      :label="`+${moreBadges}`"
      :theme="BADGE_THEMES.DEFAULT"
      :size="SIZES.XSMALL"
      :rounded="BADGE_ROUNDED.LARGE"
    />
  </div>
</template>
<script setup lang="ts">
import Badge from '@/components/Badge.vue';
import { LABEL_FIELD_ID, LABEL_FIELD_NAME } from '@/features/Labels/constants';
import CategoriesTableLabelBadgesBadge from '@/features/Categories/components/CategoriesTableLabelBadgesBadge.vue';
import { computed } from 'vue';
import { BADGE_ROUNDED, BADGE_THEMES, SIZES } from '@/types';

type BadgeType = {
  id?: string;
  title: string;
};

interface Props {
  badges: BadgeType[] | Array<string> | null;
  maxItems: number;
}

const props = withDefaults(defineProps<Props>(), {
  badges: null,
  maxItems: 2,
});

const shownBadges = computed(() => {
  if (!props.badges) return null;
  if (props.badges.length > props.maxItems) {
    return props.badges.slice(0, props.maxItems);
  }
  return props.badges;
});

const moreBadges = computed(() => {
  if (!shownBadges.value) return 0;
  const countBadges = props.badges.length - shownBadges.value?.length;
  if (countBadges > 0) {
    return countBadges;
  }

  return 0;
});
</script>
