import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "18",
  height: "16",
  fill: "none",
  viewBox: "0 0 18 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M8.391 4.876a.417.417 0 0 0-.416.432l.176 4.584c.009.223.193.4.417.4h.864a.417.417 0 0 0 .417-.4l.176-4.584a.417.417 0 0 0-.416-.432zm1.651 7.291a1.042 1.042 0 1 1-2.084 0 1.042 1.042 0 0 1 2.084 0"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M7.777.785a1.417 1.417 0 0 1 2.446 0l7.336 12.613c.543.935-.136 2.103-1.223 2.103H1.664C.577 15.5-.102 14.333.44 13.398zM9.18 2.307a.208.208 0 0 0-.36 0L2.298 13.521c-.08.139.02.313.18.313h13.044c.16 0 .26-.174.18-.313z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }