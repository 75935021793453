<template>
  <div class="flex flex-col gap-1">
    <div class="flex justify-end">
      <WordCount :text="modelValue" />
    </div>
    <textarea
      ref="dekRef"
      :data-testid="`input-${name}`"
      v-bind="props.attrs"
      :name="props.name"
      :value="localText"
      :placeholder="props.placeholder"
      class="article-dek-input leading-normal resize-none overflow-hidden break-words"
      :class="
        inputClass({
          isErrored: props.isErrored,
          isDisabled: props.isDisabled,
        })
      "
      :maxlength="MAX_DEK_LENGTH"
      wrap="hard"
      @input="onInputChange($event.target.value)"
    />

    <div
      v-if="props.isErrored && isError"
      :data-testid="`input-${props.name}-error`"
      :class="
        labelClass({
          isErrored: true,
        })
      "
      class="mt-1"
    >
      <slot name="error" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted, onUnmounted, watchEffect, useSlots } from 'vue';
import { tv } from 'tailwind-variants';
import { preventLinebreaks, clearHtmlEntities } from '@/utils/string/keyboard';
import { useFocus } from '@vueuse/core';
import WordCount from '@/features/WordCount/components/WordCount.vue';
import { MAX_DEK_LENGTH } from '@/features/ArticleLayout/constants';
import { useTextareaAutosize } from '@vueuse/core';
import sanitizeAndTruncate from '@/shared/helpers/sanitizeAndTruncate';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store.ts';

const props = defineProps<{
  modelValue: string;
  attrs: Record<string, unknown>;
  name: string;
  placeholder?: string;
  max?: number;
  isErrored?: boolean;
  isDisabled?: boolean;
}>();

const emits = defineEmits<{
  (event: 'update:modelValue', value: string): void;
}>();

const slots = useSlots();
const articleStore = useArticleStore();

const dekRef = ref<HTMLTextAreaElement | undefined>();
const articleDescription = computed(() => articleStore.state.dek);
const localText = ref<string>(articleDescription.value || '');

useTextareaAutosize({
  element: dekRef,
  input: localText,
});

const isError = computed(() => !!slots.error);

const { focused } = useFocus(dekRef);

watchEffect(() => {
  if (articleDescription.value !== localText.value && !focused.value) {
    localText.value = articleDescription.value;
  }
});

const labelClass = tv({
  base: 'inline-block block input-meta-text-default input-meta-text-sm',
  variants: {
    isErrored: {
      true: 'input-meta-text-errored',
    },
    isDisabled: {
      true: 'pointer-events-none',
    },
  },
});

const inputClass = tv({
  base: 'p-0 border-0 focus:ring-0 outline-none shadown-none rounded block w-full text-base text-imperium-fg-secondary focus:border-0',
  variants: {
    isErrored: {
      true: 'input-primary-errored',
    },
    isDisabled: {
      true: 'pointer-events-none',
    },
  },
});

onMounted(() => {
  if (!dekRef.value) {
    return;
  }

  dekRef.value.addEventListener('keypress', preventLinebreaks);
  dekRef.value.addEventListener('paste', clearHtmlEntities);
});

onUnmounted(() => {
  if (dekRef.value) {
    dekRef.value.removeEventListener('keypress', preventLinebreaks);
    dekRef.value.removeEventListener('paste', clearHtmlEntities);
  }
});

const onInputChange = (value: string) => {
  const data = sanitizeAndTruncate({ value, maxLength: MAX_DEK_LENGTH });
  localText.value = data;
  emits('update:modelValue', data);
};
</script>

<style lang="scss" scoped>
.article-dek-input[placeholder]:empty:before {
  content: attr(placeholder);
  opacity: 0.5;
}
</style>
