<template>
  <div class="flex flex-col gap-1">
    <div class="flex justify-end">
      <WordCount :text="modelValue" />
    </div>
    <textarea
      ref="titleRef"
      :data-testid="`input-${name}`"
      :value="localText"
      v-bind="props.attrs"
      :name="props.name"
      :placeholder="props.placeholder"
      :type="props.type"
      class="article-title-input leading-normal resize-none overflow-hidden break-words"
      :class="[
        inputClass({
          isErrored: props.isErrored,
          isDisabled: props.isDisabled,
        }),
      ]"
      :required="props.isRequired"
      :readonly="props.isReadonly"
      :maxlength="MAX_TITLE_LENGTH"
      wrap="hard"
      @input="onInputChange($event.target.value)"
    />

    <div
      v-if="props.isErrored && isError"
      :data-testid="`input-${props.name}-error`"
      :class="
        labelClass({
          isErrored: true,
        })
      "
      class="mt-1"
    >
      <slot name="error" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useSlots, computed, ref, onMounted, onUnmounted, watchEffect } from 'vue';
import { tv } from 'tailwind-variants';
import { preventLinebreaks, clearHtmlEntities } from '@/utils/string/keyboard';
import WordCount from '@/features/WordCount/components/WordCount.vue';
import { useFocus } from '@vueuse/core/index';
import { MAX_TITLE_LENGTH } from '@/features/ArticleLayout/constants';
import { useTextareaAutosize } from '@vueuse/core';
import sanitizeAndTruncate from '@/shared/helpers/sanitizeAndTruncate';
import { debounce } from 'lodash';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store.ts';

const props = withDefaults(
  defineProps<{
    modelValue: string;
    isErrored: boolean;
    attrs?: Record<string, unknown>;
    name?: string;
    type?: string;
    isRequired: boolean;
    placeholder?: string;
    isReadonly?: boolean;
    isDisabled?: boolean;
  }>(),
  {
    type: 'text',
    isErrored: false,
    isRequired: false,
    isReadonly: false,
  },
);

const emits = defineEmits<{
  (event: 'update:modelValue', value: string): void;
}>();

const slots = useSlots();
const articleStore = useArticleStore();

const titleRef = ref<HTMLTextAreaElement | undefined>();
const articleTitle = computed(() => articleStore.state.title);
const localText = ref<string>(articleTitle.value || '');

useTextareaAutosize({
  element: titleRef,
  input: localText,
});

const isError = computed(() => !!slots.error);

const { focused } = useFocus(titleRef);

watchEffect(() => {
  if (articleTitle.value !== localText.value && !focused.value) {
    localText.value = articleTitle.value;
  }
});

const labelClass = tv({
  base: 'inline-block block input-meta-text-default input-meta-text-sm',
  variants: {
    isErrored: {
      true: 'input-meta-text-errored',
    },
    isDisabled: {
      true: 'pointer-events-none',
    },
  },
});

const inputClass = tv({
  base: 'rounded-lg border-0 ring-0 focus:ring-0 block w-full text-ellipsis text-[33px] text-imperium-fg-strong font-semibold p-0',
  variants: {
    isErrored: {
      true: 'input-primary-errored',
    },
    isDisabled: {
      true: 'pointer-events-none',
    },
  },
});

onMounted(() => {
  if (!titleRef.value) {
    return;
  }

  titleRef.value.addEventListener('keypress', preventLinebreaks);
  titleRef.value.addEventListener('paste', clearHtmlEntities);
});

onUnmounted(() => {
  if (titleRef.value) {
    titleRef.value.removeEventListener('keypress', preventLinebreaks);
    titleRef.value.removeEventListener('paste', clearHtmlEntities);
  }
});

const valueUpdater = computed(() => {
  if (!props.modelValue || props.modelValue.length < 4) {
    return debounce((data: string) => {
      // To avoid early slug changing
      emits('update:modelValue', data);
    }, 1000);
  }

  return (data: string) => emits('update:modelValue', data);
});

const onInputChange = (value: string) => {
  const data = sanitizeAndTruncate({ value, maxLength: MAX_TITLE_LENGTH });
  localText.value = data;
  valueUpdater.value(data);
};
</script>
<style lang="scss" scoped>
.article-title-input[placeholder]:empty:before {
  content: attr(placeholder);
  opacity: 0.5;
}
</style>
