export const MAX_TITLE_LENGTH = 255;
export const MAX_DEK_LENGTH = 255;
export const MAX_ASSIGNMENT_LENGTH = 255;
export const MAX_TWEET_LENGTH = 240;
export const MAX_SEO_DESCRIPTION_LENGTH = 300;
export const MAX_AI_COVER_DESCRIPTION_LENGTH = 1000;

export enum METADATA_SECTIONS {
  INFO = 'info',
  TEAM = 'team',
  SCHEDULE = 'schedule',
  TAXONOMY = 'taxonomy',
  SOCIALS = 'socials',
  SEO = 'seo',
  SETTINGS = 'settings',
  COVER = 'cover',
}

export const FIELD_TO_SECTION_MAP: Record<string, METADATA_SECTIONS> = {
  articleUrl: METADATA_SECTIONS.INFO,
  assignment: METADATA_SECTIONS.INFO,
  needsOriginalArtwork: METADATA_SECTIONS.INFO,

  copyEditorId: METADATA_SECTIONS.TEAM,
  writerId: METADATA_SECTIONS.TEAM,
  editorId: METADATA_SECTIONS.TEAM,

  coverImageDeadLineAt: METADATA_SECTIONS.SCHEDULE,
  embargoUntil: METADATA_SECTIONS.SCHEDULE,
  publishedAt: METADATA_SECTIONS.SCHEDULE,
  updatedAt: METADATA_SECTIONS.SCHEDULE,

  categoryId: METADATA_SECTIONS.TAXONOMY,
  badgeId: METADATA_SECTIONS.TAXONOMY,
  superTags: METADATA_SECTIONS.TAXONOMY,
  tags: METADATA_SECTIONS.TAXONOMY,

  isExcludedFromTelegram: METADATA_SECTIONS.SOCIALS,
  twitterExcludeRss: METADATA_SECTIONS.SOCIALS,
  twitterPost: METADATA_SECTIONS.SOCIALS,

  seoMetaDescription: METADATA_SECTIONS.SEO,

  isBreakingNews: METADATA_SECTIONS.SETTINGS,
  excludeFromAllRss: METADATA_SECTIONS.SETTINGS,
  hideFromHotStories: METADATA_SECTIONS.SETTINGS,
  hideFromMainPage: METADATA_SECTIONS.SETTINGS,
  isPromoPost: METADATA_SECTIONS.SETTINGS,
  isShowingInMarkets: METADATA_SECTIONS.SETTINGS,
};

export const ARTICLE_CATEGORIES_RESOURCE = 'article-categories-resource';
export const ARTICLE_DOCUMENT_RESOURCE = 'article-document-resource';
export const ARTICLE_TAGS_RESOURCE = 'article-tags-resource';
export const ARTICLE_BADGES_RESOURCE = 'article-badges-resource';

export const DOCUMENT_COVERART_FIELD = 'coverArt';
